import React from 'react';

import { frFR } from '@mui/x-data-grid';
import apolloQuery from '../../utils/apolloQuery';
import { UserContext } from '../../providers/UserProvider';
import { CartContext } from '../../providers/CartProvider';
import { OrderContext } from './OrderProvider';
import { ORDER_SEARCH } from '../../queries/ORDER_SEARCH_QUERY';
import { orderColumns, pinnedOrderColumns } from './orderColumns';
import { formatOrderRows } from '../../services/orders.service';
import { subtotalRange } from '../../services/gridFilters.service';

import { DataGridPro, GridRowsProp } from '@mui/x-data-grid-pro';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useGridOptions } from '../../providers/GridOptionsProvider';

export const OrderGrid: React.FC = () => {
    const { user } = React.useContext(UserContext);
    const {
        orderColumnFilters,
        orderDropdownColumns,
        updateOrderDropdownColumns,
    } = React.useContext(OrderContext);
    const { carts } = React.useContext(CartContext);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { location } = useHistory();
    const {
        gridOption,
        setGridPage,
        setGridRowsPerPage,
        totalRows,
        setTotalRows,
        reset,
    } = useGridOptions();
    React.useEffect(() => {
        if (!location.state || !(location.state as any).gridKeepPagination) {
            return reset();
        }
    }, [location, reset]);
    React.useEffect(() => {
        // Magento pages start at 1, but MUI DataGrid starts at 0
        const currentPageMagento = gridOption.page + 1;
        const subtotalFilter = subtotalRange(orderColumnFilters);

        const queryOptions = {
            query: ORDER_SEARCH,
            variables: {
                store_id: user.storeId,
                filter: {
                    number: {},
                    lastname: {},
                    firstname: {},
                    email: {},
                    telephone: {},
                    subtotal: subtotalFilter,
                    store_id: {},
                    order_date: { from: undefined, to: undefined },
                    status: {},
                },
                pageSize: gridOption.rowsPerPage,
                currentPage: currentPageMagento,
            },
        };
        if (orderColumnFilters.get('number'))
            queryOptions.variables.filter.number = {
                contains: orderColumnFilters.get('number'),
            };
        if (orderColumnFilters.get('lastname'))
            queryOptions.variables.filter.lastname = {
                contains: orderColumnFilters.get('lastname'),
            };
        if (orderColumnFilters.get('firstname'))
            queryOptions.variables.filter.firstname = {
                contains: orderColumnFilters.get('firstname'),
            };
        if (orderColumnFilters.get('email'))
            queryOptions.variables.filter.email = {
                contains: orderColumnFilters.get('email'),
            };
        if (orderColumnFilters.get('telephone'))
            queryOptions.variables.filter.telephone = {
                contains: orderColumnFilters.get('telephone'),
            };
        if (orderColumnFilters.get('Acheté sur'))
            queryOptions.variables.filter.store_id = {
                eq: orderColumnFilters.get('Acheté sur'),
            };
        if (orderColumnFilters.get('dateFrom'))
            queryOptions.variables.filter.order_date.from =
                orderColumnFilters.get('dateFrom');
        if (orderColumnFilters.get('dateTo'))
            queryOptions.variables.filter.order_date.to =
                orderColumnFilters.get('dateTo');
        if (orderColumnFilters.get('Statut'))
            queryOptions.variables.filter.status = {
                eq: orderColumnFilters.get('Statut'),
            };

        const delay = setTimeout(async () => {
            setIsLoading(true);

            const response = await apolloQuery(queryOptions);

            if (!response?.data?.posOrderList?.items) return;

            setRows(formatOrderRows(response));
            setTotalRows(
                response.data.posOrderList.page_info.total_pages *
                    gridOption.rowsPerPage
            );
            setIsLoading(false);
        }, 500);

        return () => {
            clearTimeout(delay);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridOption, orderColumnFilters, user.storeId, carts]);

    React.useEffect(() => {
        updateOrderDropdownColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <>
            {!orderDropdownColumns.size ? (
                <CircularProgress />
            ) : (
                <DataGridPro
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    disableColumnReorder
                    headerHeight={100}
                    rows={rows}
                    columns={orderColumns}
                    initialState={{ pinnedColumns: pinnedOrderColumns }}
                    pagination
                    paginationMode="server"
                    page={gridOption.page}
                    rowCount={totalRows}
                    rowsPerPageOptions={[7, 15, 25]}
                    pageSize={gridOption.rowsPerPage}
                    onPageSizeChange={(newPageSize: number) =>
                        setGridRowsPerPage(newPageSize)
                    }
                    onPageChange={(newPage: number) => {
                        setGridPage(newPage);
                    }}
                    loading={isLoading}
                    localeText={
                        frFR.components.MuiDataGrid.defaultProps.localeText
                    }
                />
            )}
        </>
    );
};
