import React, { PropsWithChildren } from 'react';

type Row = string | number;

export const CartGridContext = React.createContext({
    rowsDiscount: new Map(),
    updateRowsDiscount: (row: Row, value: string) => {
        return;
    },
});

export const CartGridProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [rowsDiscount, setRowsDiscount] = React.useState(new Map());

    const updateRowsDiscount = (row: Row, value: string) => {
        setRowsDiscount(new Map(rowsDiscount.set(row, value)));
    };

    return (
        <CartGridContext.Provider
            value={{
                rowsDiscount,
                updateRowsDiscount,
            }}
        >
            {children}
        </CartGridContext.Provider>
    );
};
