import { gql } from '@apollo/client';

export const ADDRESS_UPDATE = gql`
    mutation posUpdateCustomerAddress(
        $address_id: Int!
        $lastname: String
        $firstname: String
        $telephone: String
        $city: String
        $company: String
        $street: [String]
        $postcode: String
        $default_billing: Boolean
    ) {
        posUpdateCustomerAddress(
            address_id: $address_id
            input: {
                lastname: $lastname
                firstname: $firstname
                telephone: $telephone
                city: $city
                company: $company
                street: $street
                postcode: $postcode
                default_billing: $default_billing
            }
        ) {
            default_billing
            default_shipping
        }
    }
`;
