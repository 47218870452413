import React from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import RemoveItem from './RemoveItem';
import ItemDiscounts from './ItemDiscounts';
import StockStatus from './StockStatus';
import DiscountedPrice from './DiscountedPrice';
import FullPrice from './FullPrice';

const cartColumns: GridColDef[] = [
    { field: 'id', hide: true },
    {
        field: 'remove',
        headerName: '',
        width: 20,
        hideSortIcons: true,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params: GridRenderCellParams): React.ReactElement => {
            return <RemoveItem uid={params.row.id} />;
        },
    },
    {
        field: 'barcode',
        hide: true,
        width: 420,
        hideSortIcons: true,
        disableColumnMenu: true,
    },
    {
        field: 'skuHidden',
        hide: true,
        width: 200,
        hideSortIcons: true,
        disableColumnMenu: true,
    },
    {
        field: 'sku',
        headerName: 'Référence',
        width: 615,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderCell: ({ value }) => <span title={value}>{value}</span>,
    },
    {
        field: 'quantity',
        headerName: 'Qté',
        width: 50,
        hideSortIcons: true,
        align: 'center',
    },
    {
        field: 'price',
        headerName: 'Prix U',
        width: 80,
        hideSortIcons: true,
        align: 'center',
        resizable: true,
        renderCell: (params: GridRenderCellParams): React.ReactElement => {
            return <FullPrice price={params.value} />;
        },
    },
    {
        field: 'discountRate',
        hide: true,
        width: 200,
        hideSortIcons: true,
        disableColumnMenu: true,
    },
    {
        field: 'discount',
        headerName: 'Remise',
        width: 150,
        hideSortIcons: true,
        resizable: true,
        renderCell: (params: GridRenderCellParams): React.ReactElement => {
            return (
                <ItemDiscounts
                    rowID={params.id}
                    discountRate={params.row.discountRate}
                />
            );
        },
    },
    {
        field: 'discountedPrice',
        headerName: 'Prix Total',
        width: 100,
        hideSortIcons: true,
        align: 'center',
        resizable: true,
        renderCell: (params: GridRenderCellParams): React.ReactElement => {
            return (
                <DiscountedPrice
                    rowID={params.id}
                    itemID={params.row.id}
                    price={params.row.discountedPrice}
                    barcode={params.row.barcode}
                    type={params.row.type}
                />
            );
        },
    },
    {
        field: 'stock',
        headerName: 'Stock',
        width: 175,
        hideSortIcons: true,
        resizable: true,
        renderCell: (params: GridRenderCellParams): React.ReactElement => {
            return <StockStatus stockInfo={params.row.stock} />;
        },
    },
    {
        field: 'delay',
        headerName: "Délai d'expédition",
        width: 400,
        hideSortIcons: true,
        disableColumnMenu: true,
    },
    { field: 'type', hide: true },
];

export default cartColumns;
