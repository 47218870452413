import apolloQuery from '../utils/apolloQuery';
import apolloMutate from '../utils/apolloMutate';
import { PRODUCT_SKU } from '../queries/PRODUCT_SKU_QUERY';
import { PRODUCT_QUICKSEARCH } from '../queries/PRODUCT_SEARCH_QUERY';
import { CART_FORCEADDPRODUCT } from '../queries/CART_FORCEADDPRODUCT_MUTATION';
import { APICart } from './cart.service';
import { APIMoney } from './payment.service';

export type ProductItem = {
    id: number;
    uid: string;
    name: string;
    sku: string;
};

export type StockModal = {
    state: 'closed' | 'preorder' | 'outofstock';
    barcode?: string;
    uid?: string;
};

export enum StockStatusEnum {
    IN_STOCK_AT_LOCATION = 'IN_STOCK_AT_LOCATION',
    IN_STOCK_ELSEWHERE = 'IN_STOCK_ELSEWHERE',
    IN_STOCK_AT_DEFAULT_WAREHOUSE = 'IN_STOCK_AT_DEFAULT_WAREHOUSE',
    HAS_INCOMING_QUANTITIES = 'HAS_INCOMING_QUANTITIES',
    PREORDERABLE_INFINITE = 'PREORDERABLE_INFINITE',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export type APIStockStatus =
    | StockStatusEnum.HAS_INCOMING_QUANTITIES
    | StockStatusEnum.IN_STOCK_AT_LOCATION
    | StockStatusEnum.IN_STOCK_ELSEWHERE
    | StockStatusEnum.PREORDERABLE_INFINITE
    | StockStatusEnum.OUT_OF_STOCK;

export type APIStockSummary = {
    code: string;
    name: string;
    qty: number;
};
export type APIStock = {
    delay_information?: string;
    global_qty?: number;
    local_qty?: number;
    next_receiving_date?: string;
    preorderable_qty?: number;
    status: string;
    status_label?: string;
    summary?: Array<APIStockSummary>;
    availability?: string;
    availability_label?: string;
};
export type APIProductItem = {
    uid?: string;
    product: {
        [index: string]: string | undefined;
        bc_barcode?: string;
        sku?: string;
        name: string;
        uid?: string;
        type_id: string;
    };
    prices: {
        discounts?: [amount: APIMoney, label: string];
        custom_discount_percent?: number;
        original_price?: APIMoney;
        original_price_including_tax?: APIMoney;
        price?: APIMoney;
        row_total?: APIMoney;
        row_total_including_tax?: APIMoney;
        total_item_discount?: APIMoney;
    };
    pos_stock?: APIStock;
    selected_options: {
        value: string;
        label: string;
    }[];
    quantity: number;
    tax_percent: number;
};
export type APIOrderProductItem = {
    availability?: string;
    availability_label?: string;
    delivery_delay: string;
    discounts: [
        {
            amount: APIMoney;
            label: string;
        }
    ];
    id: string;
    product_name: string;
    product_sku: string;
    product_type: string;
    product_sale_price: APIMoney;
    product_original_price: APIMoney;
    product_original_price_including_tax: APIMoney;
    quantity_invoiced: number;
    quantity_ordered: number;
    quantity_shipped: number;
    selected_options: { label: string; value: string }[];
    status: string;
    tax_amount: number;
    tax_percent: number;
};
export type APIProductResponse = {
    data: {
        posProductFilter: {
            items: Array<APIProductItem>;
        };
    };
};
export type APIImage = {
    label: string;
    url: string;
};
export type APIProductListItem = {
    uid: string;
    name: string;
    sku: string;
    bc_barcode: string | null;
    annee__detail: ValueIdCouple;
    collection_de_mode__detail: ValueIdCouple;
    couleur__detail: ValueIdCouple;
    final_price: APIMoney;
    manufacturer__detail: ValueIdCouple;
    pos_stock: APIStock;
    saison__detail: ValueIdCouple;
    sexe__detail: {
        items: Array<ValueIdCouple>;
    };
    stock_status: string;
    image: APIImage;
    small_image: APIImage;
    thumbnail: APIImage;
    type_id: string;
};
export type APIProductListResponse = {
    data: {
        posProductFilter: {
            items: Array<APIProductListItem>;
            total_count: number;
        };
    };
    errors?: any;
};
export type APIProductAutocomplete = {
    bc_barcode: string;
    name: string;
    sku: string;
    stock_status: string;
};
export type ProductResult = {
    id?: string | number;
    name?: string;
    sku?: string;
};
export type ProductAutocomplete = {
    id: string;
    label: string;
};
export type ValueIdCouple = {
    id: string;
    label: string;
};
export type FormattedCartRow = {
    id: string | undefined;
    name: string | undefined;
    barcode: string | undefined;
    sku: string | undefined;
    skuHidden: string | undefined;
    quantity: number | undefined;
    price: number | undefined;
    discountRate: number | undefined;
    discountedPrice: number | undefined;
    stock: APIStock | undefined;
    type: string | undefined;
};
export type FormattedProductRow = {
    id: string | null;
    sku: string | null;
    barcode: string | null;
    name: string | null;
    annee: string | null;
    collection_de_mode: string | null;
    couleur: string | null;
    manufacturer: string | null;
    price: string | null;
    saison: string | null;
    sexe: string | null;
    stock: Array<APIStockSummary> | null;
    stock_label: string | null;
    local_qty: number | null;
    delay_info: string | null;
    image: string | null;
    image_modal: string | null;
};

export const searchProduct = async (
    sku: string
): Promise<ProductResult | null> => {
    if (sku === '') {
        return null;
    }

    const queryOptions = {
        query: PRODUCT_SKU,
        variables: {
            sku,
        },
    };

    const response: APIProductResponse = await apolloQuery(queryOptions);

    return {
        id: response.data.posProductFilter.items[0].product?.bc_barcode,
        name: response.data.posProductFilter.items[0].product?.name,
        sku: response.data.posProductFilter.items[0].product?.bc_barcode,
    };
};

export const formatCartRows = (cart: APICart): Array<FormattedCartRow> => {
    const formattedItems: Array<FormattedCartRow> = [];
    const cartItems = cart?.items;

    cartItems &&
        cartItems.forEach((item: APIProductItem) => {
            const formattedRow = {
                id: item?.uid,
                name: item.product.name,
                barcode: item.product.bc_barcode,
                skuHidden: item.product.sku,
                sku: item.product.name + ' (' + item.product.sku + ')',
                quantity: item.quantity,
                price: item.prices.original_price_including_tax?.value,
                discountRate: item.prices.custom_discount_percent,
                discountedPrice: item.prices.row_total_including_tax?.value,
                stock: item.pos_stock,
                delay: item.pos_stock?.delay_information,
                type: item.product.type_id,
            };
            formattedItems.push(formattedRow);
        });

    return formattedItems;
};

export const fetchProductsAutocomplete = async (
    input: string
): Promise<any> => {
    const queryOptions = {
        query: PRODUCT_QUICKSEARCH,
        variables: {
            currentPage: 1,
            pageSize: 20,
            search: input,
            sort: {
                name: 'ASC',
            },
        },
    };

    return await apolloQuery(queryOptions);
};

export const formatProductsAutocomplete = (
    items: Array<APIProductAutocomplete>
): Array<ProductAutocomplete> => {
    const newItems: Array<ProductAutocomplete> = [];
    if (!items) return newItems;

    items.forEach((item) => {
        newItems.push({
            label: item.name,
            id: item.bc_barcode,
        });
    });

    return newItems;
};

export const formatProductRows = (
    response: APIProductListResponse
): Array<FormattedProductRow> => {
    const items = response.data.posProductFilter.items;
    const formattedProductRows: Array<FormattedProductRow> = [];

    items.forEach((item) => {
        const formattedRow: FormattedProductRow = {
            id: item?.uid,
            annee: item?.annee__detail?.label,
            collection_de_mode: item?.collection_de_mode__detail?.label,
            couleur: item?.couleur__detail?.label,
            image: item?.thumbnail?.url,
            image_modal: item?.image?.url,
            manufacturer: item?.manufacturer__detail?.label,
            name: item?.name,
            price:
                item?.final_price?.value.toString() +
                ' ' +
                item?.final_price?.currency,
            saison: item?.saison__detail?.label,
            sexe: item?.sexe__detail?.items
                ? item.sexe__detail.items[0]?.label
                : null,
            stock: item?.pos_stock?.summary ?? null,
            stock_label: item?.pos_stock?.status_label ?? null,
            local_qty: item?.pos_stock?.local_qty ?? null,
            delay_info: item?.pos_stock?.delay_information ?? null,
            sku: item?.sku,
            barcode: item?.bc_barcode,
        };
        formattedProductRows.push(formattedRow);
    });

    return formattedProductRows;
};

export const findItemByProductKey = (
    productItems: Array<APIProductItem>,
    key: string,
    value: string
): APIProductItem | undefined => {
    if (!productItems?.length || !key || !value) return;

    return productItems.find((item) => {
        if (item.product[key]?.includes(value)) return item;
    });
};

export const forceAddProduct = async (
    storeID: number,
    cartID: string,
    barcode: string
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_FORCEADDPRODUCT,
        variables: {
            store_id: storeID,
            cart_id: cartID,
            bc_barcode: barcode,
            quantity: 1,
        },
    };

    return await apolloMutate(mutationOptions);
};
