import React from 'react';

import {
    APIShippingMethodsInput,
    Locations,
    ShippingMethods,
} from '../../services/delivery.service';
import { CartContext } from '../../providers/CartProvider';

import LocationSelectionGrid from '../LocationSelectionGrid/LocationSelectionGrid';

type Props = {
    deliveryCarrier: undefined | APIShippingMethodsInput;
    withdrawalMethods: ShippingMethods;
    hideNonDefaultDeliveryPoints: boolean;
    handleCarrierSelection: (methodCodes: APIShippingMethodsInput) => void;
};

const WithdrawalDelivery: React.FC<Props> = ({
    deliveryCarrier,
    withdrawalMethods,
    hideNonDefaultDeliveryPoints,
    handleCarrierSelection,
}) => {
    const [locations, setLocations] = React.useState<Locations>();
    const [selectedID, setSelectedID] = React.useState<number>();

    const handleLocationSelection = (
        locationID: number,
        storeID: number,
        storeCode: string
    ) => {
        if (!deliveryCarrier) return;

        setSelectedID(locationID);
        handleCarrierSelection({
            method_code: deliveryCarrier.method_code,
            carrier_code: deliveryCarrier.carrier_code,
            delivery_point_id: locationID,
        });
    };

    React.useEffect(() => {
        if (!withdrawalMethods?.length || !deliveryCarrier?.carrier_code)
            return;

        withdrawalMethods.forEach((withdrawalMethod) => {
            if (
                withdrawalMethod.carrier_code !==
                    deliveryCarrier.carrier_code ||
                !withdrawalMethod?.delivery_points
            )
                return;

            setLocations(withdrawalMethod.delivery_points);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withdrawalMethods]);

    React.useEffect(() => {
        if (!deliveryCarrier?.delivery_point_id) return;

        setSelectedID(deliveryCarrier.delivery_point_id);
    }, [deliveryCarrier]);

    return (
        <>
            {!!(deliveryCarrier && locations) && (
                <LocationSelectionGrid
                    locations={locations}
                    selectedID={selectedID}
                    hideNotSelected={hideNonDefaultDeliveryPoints}
                    handleLocationSelection={handleLocationSelection}
                />
            )}
        </>
    );
};

export default WithdrawalDelivery;
