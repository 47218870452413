import React from 'react';
import { format, isValid } from 'date-fns';

import { CartContext } from '../../providers/CartProvider';
import { CardOrCoupon } from '../../services/giftcard.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';

type Props = {
    code: CardOrCoupon;
};

const Line = styled(Box)(() => ({
    height: '41px',
    display: 'grid',
    padding: '0 22px 0 30px',
    marginBottom: '13px',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'end',
    gridAutoRows: '1fr',
    gridTemplateColumns: '1fr 8fr 3fr 3fr 4fr',
    background: '#f9f9f9',
    borderRadius: '60px',
    '& *': {
        marginRight: '20px',
    },
}));
const ClearIcon = styled(Clear)(() => ({
    height: '14px',
    width: '14px',
    cursor: 'pointer',
}));

const CodeItem: React.FC<Props> = ({ code }) => {
    const { activeCart, unapplyGiftCardOrCoupon } =
        React.useContext(CartContext);
    const expirationDate =
        code?.expiration_date && isValid(new Date(code.expiration_date))
            ? format(new Date(code.expiration_date), 'dd/MM/yyyy')
            : '';

    const handleUnapplyCode = (code: string) => {
        unapplyGiftCardOrCoupon(activeCart, code);
    };

    return (
        <Line key={code.code}>
            <ClearIcon
                sx={{
                    color:
                        code?.applied_amount?.value || code?.description
                            ? theme.palette.primary.main
                            : theme.palette.error.main,
                }}
                onClick={() => {
                    handleUnapplyCode(code.code);
                }}
            />

            <Typography
                sx={{
                    color:
                        code?.applied_amount?.value || code?.description
                            ? theme.palette.primary.main
                            : theme.palette.error.main,
                    textAlign: 'start',
                }}
            >
                {code.code}
            </Typography>

            <Typography
                sx={{
                    color: code?.applied_amount?.value
                        ? theme.palette.success.dark
                        : theme.palette.error.main,
                }}
            >
                {code?.applied_amount?.value && code?.applied_amount?.currency && (
                    <>
                        {code.applied_amount.value}
                        {' ' + code.applied_amount.currency}
                    </>
                )}
            </Typography>

            <Typography
                sx={{
                    color:
                        code?.current_amount?.value &&
                        code?.applied_amount?.value &&
                        (code.current_amount.value * 100 -
                            code.applied_amount.value * 100) /
                            100 >
                            0
                            ? theme.palette.success.dark
                            : theme.palette.error.main,
                }}
            >
                {code?.current_amount?.value &&
                    code?.applied_amount?.value &&
                    code?.applied_amount?.currency && (
                        <>
                            {(code.current_amount.value * 100 -
                                code.applied_amount.value * 100) /
                                100}
                            {' ' + code.applied_amount.currency}
                        </>
                    )}
            </Typography>

            <Typography
                sx={{
                    color: code?.applied_amount?.value
                        ? theme.palette.primary.main
                        : theme.palette.error.main,
                }}
            >
                {expirationDate}
            </Typography>
        </Line>
    );
};

export default CodeItem;
