type GridOptions = {
    page: number;
    rowsPerPage: number;
};
type GridPageChangeAction = {
    type: 'pageChange';
    page: number;
};
type GridPageIncrementAction = {
    type: 'pageIncrement';
    n: number;
};
type GridRowPerPageChangeAction = {
    type: 'rowPerPageChange';
    rowsPerPage: number;
};
type GridResetAction = {
    type: 'reset';
};
export const defaultGridOption = {
    page: 0,
    rowsPerPage: 7,
};
export const GridOptionsReducer = (
    prev: GridOptions,
    action:
        | GridPageChangeAction
        | GridRowPerPageChangeAction
        | GridPageIncrementAction
        | GridResetAction
): GridOptions => {
    switch (action.type) {
        case 'pageChange':
            return { ...prev, page: action.page };
        case 'pageIncrement':
            return { ...prev, page: prev.page + action.n };
        case 'rowPerPageChange':
            return { ...prev, rowsPerPage: action.rowsPerPage };
        case 'reset':
            return defaultGridOption;
        default:
            return prev;
    }
};
