import { gql } from '@apollo/client';

export const CUSTOMER = gql`
    query posCustomer($store_id: Int!, $email: String!) {
        posCustomer(store_id: $store_id, email: $email) {
            id
            store_id
            created_at
            default_billing
            default_shipping
            email
            firstname
            is_subscribed
            lastname
            middlename
            group {
                id
                label
            }
            company
            telephone
            addresses {
                extension_attributes {
                    attribute_code
                    value
                }
                city
                company
                default_billing
                default_shipping
                firstname
                id
                lastname
                middlename
                postcode
                country {
                    id
                    label
                }
                region {
                    region
                    region_code
                    region_id
                }
                region_id
                street
                telephone
            }
        }
    }
`;
