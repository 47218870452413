import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import {
    AddressTypes,
    APICustomerAddress,
} from '../../services/customer.service';
import {
    AddressRadio,
    AddressRadioEnum,
    DeliveryAddressFields,
    emptyDeliveryAddress,
    formatDeliveryAddress,
    getAddressFieldsRegisterOptions,
} from '../../services/delivery.service';

import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';

type Props = {
    address?: APICustomerAddress;
    addressSelected: AddressRadio;
    addressType?: AddressTypes;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}));
const StyledTextFieldContainer = styled(Box)(() => ({
    paddingLeft: '12px',
    paddingRight: '12px',
}));
const StyledTextField = styled(TextField)(() => ({
    height: '40px',
    width: '100%',
    margin: '20px 24px',
}));

const Address: React.FC<Props> = ({
    address,
    addressSelected,
    addressType,
}) => {
    const [deliveryAddress, setDeliveryAddress] = React.useState<
        Array<DeliveryAddressFields> | undefined
    >();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    React.useEffect(() => {
        if (!address) {
            return;
        }

        setDeliveryAddress(formatDeliveryAddress(address));
    }, [address]);

    return (
        <Container>
            {addressSelected == AddressRadioEnum.DEFAULT
                ? deliveryAddress
                    ? deliveryAddress.map((field) => {
                          return (
                              <StyledTextFieldContainer key={field.id}>
                                  <StyledTextField
                                      variant="outlined"
                                      disabled={true}
                                      label={field.label}
                                      value={field.value}
                                      InputLabelProps={{ shrink: true }}
                                  />
                              </StyledTextFieldContainer>
                          );
                      })
                    : ''
                : emptyDeliveryAddress.map((field) => {
                      const fieldID = addressType + field.id;
                      const registerOptions: RegisterOptions =
                          getAddressFieldsRegisterOptions(
                              field.id,
                              field.required
                          );

                      return (
                          <StyledTextFieldContainer
                              key={addressType + field.id}
                          >
                              <StyledTextField
                                  variant="outlined"
                                  label={field.label}
                                  InputLabelProps={{ shrink: true }}
                                  {...register(fieldID, registerOptions)}
                                  {...(errors[fieldID] && {
                                      error: true,
                                  })}
                                  helperText={
                                      errors[fieldID]?.message?.toString() ??
                                      null
                                  }
                              />
                          </StyledTextFieldContainer>
                      );
                  })}
        </Container>
    );
};

export default Address;
