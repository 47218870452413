import { gql } from '@apollo/client';

export const CART_REMOVEPRODUCT = gql`
    mutation posRemoveItemFromCart(
        $store_id: Int!
        $cart_id: String!
        $cart_item_uid: ID!
    ) {
        posRemoveItemFromCart(
            store_id: $store_id
            input: { cart_id: $cart_id, cart_item_uid: $cart_item_uid }
        ) {
            cart {
                id
            }
        }
    }
`;
