import React from 'react';
import { useForm } from 'react-hook-form';

import currency from '../../utils/currency';
import { DueFormValues } from '../../services/payment.service';

import { styled } from '@mui/material/styles';
import { theme } from '../../themeOptions';
import { Button, TextField } from '@mui/material';

type Props = {
    amount: number;
    currentOption: string;
    hasPaidTotal: boolean;
    handleDueFormValidation: (amount: number) => void;
};

const FormContainer = styled('form')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '60px',
}));
const Amount = styled(TextField)(() => ({
    '& input': {
        height: '10px',
    },
    width: '236px',
    marginRight: '14px',
}));
const Submit = styled(Button)(() => ({
    height: '40px',
    width: '50px',
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));

const DueForm: React.FC<Props> = ({
    amount,
    currentOption,
    hasPaidTotal,
    handleDueFormValidation,
}) => {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm<DueFormValues>();
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        setValue('amount', currency(amount));
        if (!currentOption) {
            setIsDisabled(true);
            return;
        }
        if (amount) setIsDisabled(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, amount, currentOption]);

    React.useEffect(() => {
        if (errors.amount || !amount || hasPaidTotal) setIsDisabled(true);
    }, [errors.amount, amount, hasPaidTotal]);

    return (
        <FormContainer
            onSubmit={handleSubmit((dueData) => {
                setIsDisabled(true);
                handleDueFormValidation(dueData.amount);
            })}
        >
            <Amount
                id="amount"
                type="number"
                inputProps={{
                    min: 0,
                    max: currency(amount),
                    step: '0.01',
                }}
                placeholder="Montant à régler en €"
                {...register('amount', { required: true, valueAsNumber: true })}
                {...(errors.amount && { error: true })}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    clearErrors();
                    setValue('amount', parseFloat(e.target.value));
                    if (currentOption) setIsDisabled(false);

                    const value = parseFloat(e.target.value);
                    if (!value) {
                        setError('amount', {
                            message: 'Veuillez renseigner un montant',
                        });
                    }
                    if (value <= 0) {
                        setError('amount', {
                            message:
                                'Le montant ne peut pas être inférieur ou égal à 0',
                        });
                    }
                    if (value > currency(amount)) {
                        setError('amount', {
                            message:
                                'Le montant ne peut pas être supérieur au restant à payer',
                        });
                    }
                }}
            />
            <Submit variant="contained" disabled={isDisabled} type="submit">
                OK
            </Submit>
        </FormContainer>
    );
};

export default DueForm;
