import React from 'react';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';

type Props = {
    folded: boolean;
};

const ActionButton = styled(Fab)(() => ({
    zIndex: 10,
    height: '40px',
    width: '40px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
const AddIcon = styled(Add)(() => ({
    height: '23px',
    width: '23px',
    transition: '.2s ease-in-out',
}));

const CommentButton: React.FC<Props> = ({ folded }) => {
    return (
        <ActionButton variant="circular">
            <AddIcon sx={{ transform: folded ? '' : 'rotate(45deg)' }} />
        </ActionButton>
    );
};

export default CommentButton;
