import React from 'react';

import {
    GridCellParams,
    GridColDef,
    GridPinnedColumns,
} from '@mui/x-data-grid-pro';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import ButtonShowOrder from './ButtonShowOrder';
import {
    dateRangeRenderHeader,
    dropdownRenderHeader,
    numberRangeRenderHeader,
    textRenderHeader,
} from '../Filters/RenderFilters';

export const orderColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: true },
    {
        field: 'number',
        headerName: 'N° de commande',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'order'),
    },
    {
        field: 'store_label',
        headerName: 'Acheté sur',
        width: 180,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'order'),
    },
    {
        field: 'order_date',
        headerName: "Date d'achat",
        width: 160,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dateRangeRenderHeader(params, 'order'),
    },
    {
        field: 'lastname',
        headerName: 'Nom',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'order'),
    },
    {
        field: 'firstname',
        headerName: 'Prénom',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'order'),
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 200,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'order'),
    },
    {
        field: 'telephone',
        headerName: 'Téléphone',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'order'),
    },
    {
        field: 'subtotal',
        headerName: 'Montant',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => numberRangeRenderHeader(params, 'order'),
    },
    {
        field: 'status',
        headerName: 'Statut',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'order'),
    },
    {
        field: 'customer_note',
        headerName: 'Commentaire',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
    },
    {
        field: 'show',
        headerName: '',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderCell: (params: GridCellParams): React.ReactElement => {
            const Container = styled(Box)(() => ({
                paddingLeft: '5px',
                paddingRight: '5px',
            }));

            return (
                <Container
                    sx={{
                        lineHeight: 'initial',
                    }}
                >
                    <ButtonShowOrder orderNumber={params.row.number} />
                </Container>
            );
        },
    },
];

export const pinnedOrderColumns: GridPinnedColumns = {
    left: ['number'],
    right: ['show'],
};
