import { createTheme } from '@mui/material';
// noinspection ES6UnusedImports
import {} from '@mui/x-data-grid/themeAugmentation';
import { frFR } from '@mui/x-data-grid';

export const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#2f2b99',
                light: '#6555cb',
                dark: '#00056a',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#6280fb',
                light: '#9aafff',
                dark: '#1b1f52',
                contrastText: '#ffffff',
            },
            text: {
                primary: '#21267d',
                secondary: '#6669a6',
                disabled: '#8c8ebc',
            },
            action: {
                active: 'rgba(0, 5, 106, 0.54)',
                hover: 'rgba(27, 31, 82, 0.04)',
                selected: 'rgba(98, 128, 251, 0.54)',
                disabled: 'rgba(27, 31, 82, 0.26)',
                disabledBackground: 'rgba(27, 31, 82, 0.15)',
                focus: 'rgba(27, 31, 82, 0.12)',
            },
            error: {
                main: '#f45341',
                dark: '#ba1718',
                light: '#ff866d',
                contrastText: '#ffffff',
            },
            info: {
                main: '#ac85ff',
                dark: '#7957cb',
                light: '#e0b5ff',
                contrastText: '#ffffff',
            },
            warning: {
                main: '#ff9359',
                dark: '#c7642d',
                light: '#ffc487',
                contrastText: '#ffffff',
            },
            success: {
                main: '#8ff4c1',
                dark: '#5cc191',
                light: '#c3fff4',
                contrastText: '#ffffff',
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: ['Montserrat'].join(','),
            h1: {
                fontSize: '34px',
                textAlign: 'left',
                marginLeft: '25px',
                marginBottom: '10px',
            },
            h2: { fontSize: '24px' },
            h3: { fontSize: '20px', fontWeight: 'lighter' },
            h4: { fontSize: '34px', fontWeight: 'lighter' },
            subtitle1: { fontWeight: 'normal', fontSize: '16px' },
            subtitle2: { fontWeight: 'bolder', fontSize: '14px' },
            body1: { fontWeight: 'lighter', fontSize: '16px' },
            body2: { fontWeight: 'lighter', fontSize: '14px' },
            button: { fontWeight: 'bolder', fontSize: '15px' },
            caption: { fontWeight: 'normal', fontSize: '12px' },
            overline: { fontWeight: 'normal', fontSize: '12px' },
        },
    },
    frFR
);

theme.components = {
    MuiAvatar: {
        styleOverrides: {
            root: { fontWeight: 'normal', fontSize: '12px' },
        },
    },
    MuiButton: {
        styleOverrides: {
            sizeLarge: { fontWeight: 'normal', fontSize: '15px' },
            sizeMedium: { fontWeight: 'normal', fontSize: '14px' },
            sizeSmall: { fontWeight: 'normal', fontSize: '13px' },
            root: { display: 'flex', padding: '6px 16px' },
        },
    },
    MuiInput: {
        styleOverrides: {
            root: { fontWeight: 'normal', fontSize: '16px' },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: { fontWeight: 'normal', fontSize: '12px' },
        },
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                background: 'white',
                '& .MuiDataGrid-row:nth-of-type(even)': {
                    backgroundColor: '#f9f9f9',
                },
                '& .MuiDataGrid-columnHeader': {
                    fontWeight: 'bolder',
                    height: '100px',
                },
                '& .MuiDataGrid-columnHeaderDraggableContainer': {
                    height: '100%',
                },
                '& .MuiDataGrid-cell': {
                    fontWeight: 'lighter',
                    color: 'rgba(0, 5, 106, 0.87)',
                    border: 'none',
                },
                '& .MuiDataGrid-pinnedColumns .MuiDataGrid-cell': {
                    background: 'white',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
                    {
                        outline: 'none',
                    },
                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                    width: '100%',
                },
                '& .MuiCircularProgress-root': {
                    zIndex: 1000,
                },
                '& .MuiDataGrid-overlay': {
                    height: '100px!important',
                },
                marginLeft: '22px',
                marginRight: '22px',
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '0.4px',
                paddingTop: '4px',
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: { borderRadius: '4px' },
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            text: { fontSize: '12px' },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: { fontWeight: 'bolder', fontSize: '14px' },
        },
    },
    MuiAlert: {
        styleOverrides: {
            icon: { color: theme.palette.error.main + '!important' },
            standardError: {
                background:
                    'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #BA1718',
            },
        },
    },
};
