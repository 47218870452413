import React from 'react';

import { Locations, LocationsData } from '../../services/delivery.service';

import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { StorefrontOutlined } from '@mui/icons-material';

type Props = {
    locations: Locations | undefined;
    selectedID?: number;
    hideNotSelected?: boolean;
    handleLocationSelection: (
        locationID: number,
        storeID: number,
        storeCode: string
    ) => void;
};

const StoreSelectionGrid = styled(Grid)(() => ({
    listStyleType: 'none',
    paddingLeft: 0,
}));
const StoreItem = styled(Grid)(() => ({
    height: 128,
    width: 128,
    maxWidth: '128px',

    borderRadius: '4px',
    background: 'white',
    boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2), \
                        0px 4px 5px rgba(0, 0, 0, 0.14), \
                        0px 1px 10px rgba(0, 0, 0, 0.12);',
    '&:hover': {
        background: '#f9f9f9',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    fontSize: 12,
    cursor: 'pointer',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '29px',
    marginBottom: '29px',
}));
const StoreIcon = styled(StorefrontOutlined)(() => ({
    display: '',
    height: 55,
    width: 55,
    marginBottom: '7px',
}));
const StoreName = styled(Typography)(() => ({
    fontSize: 12,
    fontWeight: 'normal',
    textAlign: 'center',
}));

const LocationSelectionGrid: React.FC<Props> = ({
    locations,
    selectedID,
    hideNotSelected,
    handleLocationSelection,
}) => {
    return (
        <StoreSelectionGrid container columns={3}>
            {!!locations &&
                locations.map((location: LocationsData, index) => {
                    const isSelected = location.id == selectedID;

                    return (
                        <Box key={index}>
                            {(isSelected || !hideNotSelected) && (
                                <StoreItem
                                    item
                                    title={location.name}
                                    onClick={() => {
                                        handleLocationSelection(
                                            location.id,
                                            location.store_id,
                                            location.store_code
                                        );
                                    }}
                                    sx={{
                                        background: isSelected
                                            ? 'rgba(47, 43, 153, 0.08)'
                                            : '',
                                        cursor: isSelected
                                            ? 'default'
                                            : 'pointer',
                                    }}
                                >
                                    <StoreIcon />

                                    <StoreName variant="body1">
                                        {location.name.split('-')[1] ??
                                            location.name}
                                    </StoreName>
                                </StoreItem>
                            )}
                        </Box>
                    );
                })}
        </StoreSelectionGrid>
    );
};

export default LocationSelectionGrid;
