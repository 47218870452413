import React from 'react';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { CartContext } from '../../providers/CartProvider';

const StyledButton = styled(Button)(() => ({
    order: 0,
}));
const CartAddIcon = styled(AddCircleOutline)(() => ({
    height: '20px',
    width: '20px',
    marginRight: '10px',
}));

export const CartAddButton: React.FC = () => {
    const { activeCart, createCart, carts } = React.useContext(CartContext);
    const [hasMaximumCarts, setHasMaximumCarts] =
        React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setHasMaximumCarts(carts.size >= 5);
        setLoading(carts.size === 0); // this locks the creation of a new cart before the CartProvider creates one
    }, [carts]);

    React.useEffect(() => {
        setLoading(false);
    }, [activeCart]);

    return (
        <StyledButton
            disabled={hasMaximumCarts || loading}
            onClick={() => {
                setLoading(true);
                createCart();
            }}
        >
            <CartAddIcon
                sx={{
                    color:
                        hasMaximumCarts || loading
                            ? 'grey'
                            : theme.palette.primary.main,
                }}
            />
            NOUVEAU PANIER
        </StyledButton>
    );
};

export default CartAddButton;
