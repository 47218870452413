import React from 'react';

import {
    APIShippingMethodsInput,
    ShippingMethods,
} from '../../services/delivery.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup } from '@mui/material';

type Props = {
    methods: ShippingMethods;
    handleCarrierSelection: (methodCodes: APIShippingMethodsInput) => void;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));
const StyledButton = styled(Button)(() => ({
    color: theme.palette.secondary.main,
}));

export const CarrierSwitch: React.FC<Props> = ({
    methods,
    handleCarrierSelection,
}) => {
    return (
        <Container>
            <ButtonGroup disableElevation variant="outlined">
                {methods.map((method, index) => {
                    return (
                        <StyledButton
                            key={index}
                            onClick={() => {
                                handleCarrierSelection({
                                    carrier_code: method.carrier_code,
                                    method_code: method.method_code,
                                });
                            }}
                        >
                            {method.carrier_title}
                        </StyledButton>
                    );
                })}
            </ButtonGroup>
        </Container>
    );
};

export default CarrierSwitch;
