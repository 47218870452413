import React from 'react';

import { CartContext } from '../../providers/CartProvider';

import { styled } from '@mui/material/styles';
import { Clear } from '@mui/icons-material';

import { ButtonIconContainer } from '../ButtonIconContainer/ButtonIconContainer';

type Props = {
    uid: string;
};

const ClearIcon = styled(Clear)(() => ({
    height: '14px',
    width: '14px',
    cursor: 'pointer',
}));

const RemoveItem: React.FC<Props> = ({ uid }) => {
    const { activeCart, removeProduct } = React.useContext(CartContext);

    return (
        <ButtonIconContainer onClick={() => removeProduct(activeCart, uid)}>
            <ClearIcon />
        </ButtonIconContainer>
    );
};

export default RemoveItem;
