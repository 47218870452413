import React from 'react';

import { frFR } from '@mui/x-data-grid';
import cartColumns from './cartColumns';
import { FormattedCartRow } from '../../services/products.service';

import { Box } from '@mui/material';
import { DataGridPro, GridRowsProp } from '@mui/x-data-grid-pro';

export type CartGridProps = {
    items: Array<FormattedCartRow>;
};

export const CartGrid: React.FunctionComponent<CartGridProps> = ({ items }) => {
    const [rows, setRows] = React.useState<GridRowsProp>([]);

    React.useEffect(() => {
        setRows(items);
    }, [items]);

    return (
        <Box
            sx={{
                height: '235px',
                width: '100%',
            }}
        >
            {!!rows.length && (
                <DataGridPro
                    hideFooter
                    disableColumnMenu
                    disableSelectionOnClick
                    disableColumnReorder
                    rows={rows}
                    columns={cartColumns}
                    rowCount={items.length}
                    localeText={
                        frFR.components.MuiDataGrid.defaultProps.localeText
                    }
                    className="cart-grid"
                    sx={{ margin: '0' }}
                />
            )}
        </Box>
    );
};

export default CartGrid;
