import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { CustomerGridLocation } from '../../services/customer.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress } from '@mui/material';
import { Save } from '@mui/icons-material';

type Props = {
    disabled: boolean;
};

const StickyButtons = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '30px',
}));
const BackButton = styled(Button)(() => ({
    background: theme.palette.action.hover,
    color: theme.palette.primary.main,
}));
const SaveButton = styled(Button)(() => ({
    marginLeft: '10px',
}));
const SaveIcon = styled(Save)(() => ({
    height: '16px',
    width: '16px',
    marginRight: '10px',
    color: theme.palette.primary.contrastText,
}));

const disabledSaveButton = {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    '&:hover': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
};
const enabledSaveButton = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
};

export const CustomerFormButtons: React.FC<Props> = ({ disabled }) => {
    const history = useHistory();
    const { state } = useLocation<CustomerGridLocation>();
    const {
        formState: { isSubmitting, errors },
    } = useFormContext();

    return (
        <StickyButtons>
            <BackButton
                onClick={() => {
                    if (!state?.filters?.customer) {
                        history.push('/clients', { gridKeepPagination: true });
                        return;
                    }
                    history.push('/clients', {
                        ...state,
                        gridKeepPagination: true,
                    });
                }}
            >
                RETOUR
            </BackButton>

            {isSubmitting ? (
                <CircularProgress />
            ) : (
                <SaveButton
                    type="submit"
                    disabled={!!(disabled || errors?.email)}
                    sx={
                        disabled || errors?.email
                            ? disabledSaveButton
                            : enabledSaveButton
                    }
                >
                    <SaveIcon />
                    ENREGISTRER
                </SaveButton>
            )}
        </StickyButtons>
    );
};
