import { gql } from '@apollo/client';

export const CART_QUERY = gql`
    query posCart($store_id: Int!, $cart_id: String!) {
        posCart(store_id: $store_id, cart_id: $cart_id) {
            id
            total_quantity
            customer_note
            email
            firstname
            lastname
            company
            telephone
            customer {
                id
                company
                default_billing
                default_shipping
                email
                firstname
                lastname
                is_subscribed
                telephone
                created_at
            }
            composite_payment {
                total
                payments {
                    code
                    amount
                    label
                }
            }
            applied_gift_cards {
                gift_cards {
                    code
                    initial_amount {
                        value
                        currency
                    }
                    current_amount {
                        value
                        currency
                    }
                    applied_amount {
                        value
                        currency
                    }
                    expiration_date
                }
                total_amount {
                    value
                }
            }
            shipping_addresses {
                firstname
                lastname
                company
                street
                city
                region {
                    code
                    label
                }
                postcode
                telephone
                country {
                    code
                    label
                }
                available_shipping_methods {
                    amount {
                        value
                    }
                    carrier_code
                    carrier_title
                    method_code
                    method_title
                    shipping_method_type
                    delivery_points {
                        id
                        city
                        name
                        store_id
                        store_code
                    }
                }
                selected_shipping_method {
                    amount {
                        value
                    }
                    carrier_code
                    carrier_title
                    method_code
                    method_title
                    shipping_method_type
                    delivery_point {
                        address
                        city
                        country
                        id
                        name
                        store_id
                        store_code
                    }
                }
            }

            billing_address {
                firstname
                lastname
                company
                street
                city
                region {
                    code
                    label
                }
                postcode
                telephone
                country {
                    code
                    label
                }
            }
            applied_coupons {
                code
                description
                name
            }
            prices {
                discounts {
                    amount {
                        value
                        currency
                    }
                    label
                }
                subtotal_including_tax {
                    value
                    currency
                }
                grand_total {
                    value
                    currency
                }
            }
            items {
                uid
                product {
                    bc_barcode
                    sku
                    name
                    uid
                    id
                    type_id # type du produit : simple, giftcard, configurable,...
                }
                pos_stock {
                    delay_information
                    global_qty
                    incoming_date
                    incoming_qty
                    local_qty
                    status
                    status_label
                    summary {
                        code
                        incoming_date
                        incoming_qty
                        name
                        preorder_date
                        qty
                    }
                }
                prices {
                    discounts {
                        amount {
                            value
                            currency
                        }
                        label
                    }
                    custom_discount_percent
                    original_price {
                        value
                        currency
                    }
                    original_price_including_tax {
                        value
                        currency
                    }
                    price {
                        value
                        currency
                    }
                    row_total {
                        value
                        currency
                    }
                    row_total_including_tax {
                        value
                        currency
                    }
                    total_item_discount {
                        value
                        currency
                    }
                }
                quantity
                tax_percent
            }
        }
    }
`;
