import React from 'react';
import { useForm } from 'react-hook-form';

import { CartContext } from '../../providers/CartProvider';
import { checkHasPaidTotal } from '../../services/payment.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography } from '@mui/material';

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
}));
const FormContainer = styled('form')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));
const StyledTextField = styled(TextField)(() => ({
    width: '200px',
    '& input': {
        height: '24px',
        padding: '8px 12px',
    },
}));
const StyledSubmit = styled(Button)(() => ({
    height: '36px',
    width: '24px',
    marginLeft: '24px',
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));
const ErrorMessage = styled(Typography)(() => ({
    fontSize: theme.typography.body2.fontSize,
}));

const CodesForm: React.FC = () => {
    const { activeCart, carts, applyGiftCardOrCoupon, updateCart } =
        React.useContext(CartContext);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm<{ code: string }>();
    const [codeValue, setCodeValue] = React.useState<string>('');
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!codeValue) {
            setIsDisabled(true);
            return;
        }

        const hasPaidTotal = checkHasPaidTotal(
            carts.get(activeCart).composite_payment.total,
            carts.get(activeCart).prices.grand_total.value
        );
        if (hasPaidTotal) {
            setIsDisabled(true);
            return;
        }

        setIsDisabled(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeValue, carts]);

    return (
        <Container>
            <FormContainer
                onSubmit={handleSubmit((data) => {
                    applyGiftCardOrCoupon(activeCart, data.code).then(
                        (codeData) => {
                            if (!codeData?.errors?.length) {
                                updateCart(activeCart);
                                setCodeValue('');
                                setValue('code', '');
                                return;
                            }
                            setError('code', {
                                message: codeData.errors[0].message,
                            });
                            setIsDisabled(true);
                        }
                    );
                })}
            >
                <StyledTextField
                    id="code"
                    label="Carte cadeau ou Code promo"
                    variant="outlined"
                    {...register('code', { required: true })}
                    {...(errors.code && { error: true })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        clearErrors();
                        setCodeValue(e.target.value);
                        setIsDisabled(false);
                    }}
                />

                <StyledSubmit
                    type="submit"
                    disabled={isDisabled}
                    sx={{
                        background: isDisabled
                            ? theme.palette.action.disabledBackground
                            : '',
                        color: isDisabled ? theme.palette.action.disabled : '',
                    }}
                >
                    OK
                </StyledSubmit>
            </FormContainer>
            <ErrorMessage sx={{ color: 'red' }}>
                {errors?.code?.message}
            </ErrorMessage>
        </Container>
    );
};

export default CodesForm;
