import React, { PropsWithChildren } from 'react';

import PropTypes from 'prop-types';
import apolloMutate from '../utils/apolloMutate';
import { REVOKE_TOKEN } from '../queries/TOKEN_REVOKE_MUTATION';
import { EventEmitter, Events } from '../utils/events';
import { SELLER_TOKEN } from '../queries/TOKEN_GENERATE_MUTATION';
import { handleCartWipeOnLogin } from '../services/cart.service';

type NullableString = string | null | undefined;

export const UserContext = React.createContext({
    user: {
        name: '',
        authenticated: false,
        storeId: 0,
        storeCode: '',
        locationId: 0,
    },
    generateToken: async (user: string, password: string) => {
        return;
    },
    login: (
        name: string,
        storeId: number,
        storeCode: string,
        locationId: number,
        shouldWipeCarts?: boolean
    ) => {
        return;
    },
    isLoggedIn: (
        token: NullableString,
        name: NullableString,
        storeId: NullableString
    ): any => {
        return;
    },
    logout: () => {
        return;
    },
});

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    // User is the name of the 'data' that gets stored in context
    const [user, setUser] = React.useState({
        name: '',
        authenticated: false,
        storeId: 0,
        storeCode: '',
        locationId: 0,
    });

    const isLoggedIn = (
        token: NullableString,
        userId: NullableString,
        storeId: NullableString
    ) => {
        return !!(token && userId && storeId);
    };

    const generateToken = async (user: string, password: string) => {
        const mutationOptions = {
            mutation: SELLER_TOKEN,
            variables: {
                user: user,
                password: password,
            },
        };

        return await apolloMutate(mutationOptions);
    };

    // Login updates the user data with a name parameter
    const login = (
        name: string,
        storeId: number,
        storeCode: string,
        locationId: number,
        shouldWipeCarts?: boolean
    ) => {
        const localCarts = JSON.parse(localStorage.getItem('carts') ?? '[]');
        sessionStorage.setItem('storeID', storeId.toString());
        sessionStorage.setItem('storeCode', storeCode);
        sessionStorage.setItem('locationID', locationId.toString());

        if (!shouldWipeCarts) {
            setUser({
                name: name,
                authenticated: true,
                storeId: storeId,
                storeCode: storeCode,
                locationId: locationId,
            });
            return;
        }
        handleCartWipeOnLogin(localCarts)
            .then(() => {
                localStorage.setItem('carts', '[]');
                localStorage.setItem('activeCart', '');
                setUser({
                    name: name,
                    authenticated: true,
                    storeId: storeId,
                    storeCode: storeCode,
                    locationId: locationId,
                });
            })
            .catch((error) => {
                console.error('error', error);
            });
    };

    // Logout updates the user data to default
    const logout = () => {
        const mutationOptions = {
            mutation: REVOKE_TOKEN,
            variables: {
                user: user.name,
            },
        };

        apolloMutate(mutationOptions);
        EventEmitter.unsubscribe(Events.AUTHENTICATION_EXPIRED);

        sessionStorage.setItem('user', '');
        sessionStorage.setItem('storeID', '');
        sessionStorage.setItem('storeCode', '');
        sessionStorage.setItem('token', '');

        setUser({
            name: '',
            authenticated: false,
            storeId: 0,
            storeCode: '',
            locationId: 0,
        });
    };

    EventEmitter.subscribe(Events.AUTHENTICATION_EXPIRED, (response: any) => {
        if (!user.authenticated) return;
        logout();
    });

    return (
        <UserContext.Provider
            value={{ user, generateToken, login, isLoggedIn, logout }}
        >
            {children}
        </UserContext.Provider>
    );
};

UserProvider.propTypes = { children: PropTypes.node.isRequired };
