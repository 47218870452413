import apolloMutate from '../utils/apolloMutate';
import apolloQuery from '../utils/apolloQuery';
import { MutationOptions } from '@apollo/client';
import { CUSTOMER_UPDATE } from '../queries/CUSTOMER_UPDATE_MUTATION';
import { ADDRESS_UPDATE } from '../queries/ADDRESS_UPDATE_MUTATION';
import { ADDRESS_CREATE } from '../queries/ADDRESS_CREATE_MUTATION';
import { CUSTOMER_SEARCH_SIMPLE } from '../queries/CUSTOMER_SEARCH_QUERY';
import {
    CART_SETCUSTOMER,
    CART_SETGUESTCUSTOMER,
} from '../queries/CART_SETCUSTOMER_MUTATION';
import {
    CART_SETSHIPPING,
    CART_SETSHIPPING_FULL,
} from '../queries/CART_SETSHIPPING_MUTATION';
import {
    CART_SETBILLING,
    CART_SETBILLING_FULL,
} from '../queries/CART_SETBILLING_MUTATION';
import {
    APIShippingMethod,
    findDefaultCarrier,
    findDefaultDeliveryType,
    findLocationIDByStoreID,
    findMethodsByDeliveryType,
    setDeliveryMethodOnCart,
} from './delivery.service';
import { APIProductItem } from './products.service';
import { APICart, PosError } from './cart.service';
import { GridFilters } from './gridFilters.service';

export type APIGroup = {
    id: number;
    label: string;
};

export type APIRegion = {
    region: string;
    region_code: string;
    region_id: number;
};

export type APICountry = {
    id: string;
    label: string;
};

export type APIBasicAddress = {
    id: number;
    company: string;
    postcode: string;
    telephone: string;
    country: APICountry;
};

export type APIRow = {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    telephone: string;
    company: string;
    addresses: Array<APIBasicAddress>;
    group: APIGroup;
    created_at: string;
    default_billing: number;
    is_subscribed: boolean;
};

export type FormattedRow = {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    company: string;
    postcode: string;
    telephone: string;
    country: string;
    group: string;
    created_at: string;
    is_subscribed: boolean;
};

export type CustomerListResponse = {
    data: {
        posCustomerList: {
            items: Array<APIRow>;
        };
    };
};

export type APICustomerAddress = {
    city: string;
    company: string;
    default_billing?: boolean;
    default_shipping?: boolean;
    firstname: string;
    id?: number;
    lastname: string;
    delivery_name: string;
    middlename?: string;
    postcode?: string;
    country: APICountry;
    region?: APIRegion;
    region_id?: number;
    street: Array<string>;
    telephone: string;
    available_shipping_methods?: Array<APIShippingMethod>;
    selected_shipping_method?: APIShippingMethod;
};

export type APICustomer = {
    id: number;
    store_id: number;
    created_at: string;
    default_billing: string;
    default_shipping: string;
    email: string;
    firstname: string;
    is_subscribed: boolean;
    lastname: string;
    middlename: string;
    telephone: string;
    company: string;
    group: APIGroup;
    addresses?: Array<APICustomerAddress>;
};

export type CustomerRecap = {
    id: string | number;
    company: string;
    created_at: string;
    email: string;
    firstname: string;
    lastname: string;
    telephone: string;
};

export type FormattedCustomerInfo = {
    id: string | number;
    disabled: boolean;
    label: string;
    value: string | number | boolean | undefined;
    width: string;
    hidden: boolean;
    type?: string;
    required?: boolean;
};

export enum CustomerFormTypesEnum {
    SHIPPING = 'shipping',
    BILLING = 'billing',
    INFO = 'info',
    NEWSHIPPING = 'newshipping',
    NEWBILLING = 'newbilling',
}

export type AddressTypes =
    | CustomerFormTypesEnum.SHIPPING
    | CustomerFormTypesEnum.BILLING;
export type CustomerFormTypesExtended =
    | CustomerFormTypesEnum.SHIPPING
    | CustomerFormTypesEnum.BILLING
    | CustomerFormTypesEnum.INFO
    | CustomerFormTypesEnum.NEWSHIPPING
    | CustomerFormTypesEnum.NEWBILLING;

type StrUnd = string | undefined;
export type CustomerFormData = {
    billingform: StrUnd;
    billingcity: StrUnd;
    billingcompany: StrUnd;
    billingcountry: StrUnd;
    billingfirstname: StrUnd;
    billinglastname: StrUnd;
    billingpostcode: StrUnd;
    billingstreet: StrUnd;
    billingtelephone: StrUnd;

    newbillingcity: StrUnd;
    newbillingcompany: StrUnd;
    newbillingcountry: StrUnd;
    newbillingfirstname: StrUnd;
    newbillinglastname: StrUnd;
    newbillingpostcode: StrUnd;
    newbillingstreet: StrUnd;
    newbillingtelephone: StrUnd;

    infocreated_at: StrUnd;
    infoemail: StrUnd;
    infofirstname: StrUnd;
    infogroup: StrUnd;
    infoid: number | undefined;
    infolastname: StrUnd;
    infocompany: StrUnd;
    infotelephone: StrUnd;

    shippingform: StrUnd;
    shippingcity: StrUnd;
    shippingcompany: StrUnd;
    shippingcountry: StrUnd;
    shippingfirstname: StrUnd;
    shippinglastname: StrUnd;
    shippingpostcode: StrUnd;
    shippingstreet: StrUnd;
    shippingtelephone: StrUnd;

    newshippingcity: StrUnd;
    newshippingcompany: StrUnd;
    newshippingcountry: StrUnd;
    newshippingfirstname: StrUnd;
    newshippinglastname: StrUnd;
    newshippingpostcode: StrUnd;
    newshippingstreet: StrUnd;
    newshippingtelephone: StrUnd;

    is_subscribed: boolean;
};

export type CartFormValues = {
    id?: number;
    email: string;
    lastname?: string;
    firstname?: string;
    telephone?: string;
    company?: string;
    is_subscribed?: boolean;
};

export type CustomerAddressInput = {
    city: string;
    country_code: string;
    firstname: string;
    lastname: string;
    postcode: string;
    street: Array<string>;
    telephone: string;
    company?: string;
};

export type CustomerAddresses = {
    shipping: number;
    billing: number;
};

export type CustomerAddressesNR = {
    shipping: CustomerAddressInput;
    billing: CustomerAddressInput;
};

export type APISearchAutoComplete = {
    email: string;
    id: number;
};

export type SearchAutoComplete = {
    label: string;
    id: string;
};

export type CustomerGridLocation = {
    email?: string;
    filters?: GridFilters;
};

const NRAddress = {
    city: 'n/r',
    country_code: 'FR',
    firstname: 'n/r',
    lastname: 'n/r',
    postcode: 'n/r',
    street: ['n/r'],
    telephone: 'n/r',
};

export const formatCustomerRows = (
    response: CustomerListResponse
): Array<FormattedRow> => {
    const items = response.data.posCustomerList.items;
    const formattedRows: Array<FormattedRow> = [];

    items.forEach((item: APIRow) => {
        const addressId = item.default_billing;
        let defaultBillingAddress: APIBasicAddress | null = {
            id: 0,
            company: '',
            postcode: '',
            telephone: '',
            country: {
                id: '',
                label: '',
            },
        };

        item.addresses.forEach((address: APIBasicAddress) => {
            if (address.id == addressId) {
                defaultBillingAddress = address;
                return;
            }
        });

        const formattedRow = {
            id: item.id,
            email: item.email,
            firstname: item.firstname,
            lastname: item.lastname,
            company: item.company,
            postcode: defaultBillingAddress.postcode,
            telephone: item.telephone,
            country: defaultBillingAddress.country.label,
            group: item.group.label,
            created_at: item.created_at,
            is_subscribed: item.is_subscribed,
        };
        formattedRows.push(formattedRow);
    });

    return formattedRows;
};

export const defaultAddressID = (
    addressType: string,
    customerData: APICustomer
): string => {
    if (addressType === CustomerFormTypesEnum.SHIPPING) {
        return customerData.default_shipping;
    } else {
        return customerData.default_billing;
    }
};

export const defaultCustomerAddress = (
    addressType: string,
    customerData: APICustomer
): APICustomerAddress | undefined => {
    let defaultAddress: APICustomerAddress | undefined;
    if (!customerData?.addresses) return;

    customerData.addresses.forEach((address) => {
        if (
            defaultAddressID(addressType, customerData) ==
            address.id?.toString()
        ) {
            defaultAddress = address;
        }
    });

    return defaultAddress;
};

export const formatCartCustomerInfo = (
    formData?: CartFormValues
): Array<FormattedCustomerInfo> => {
    if (!formData) {
        return [
            {
                id: 'lastname',
                disabled: false,
                label: 'Nom',
                value: '',
                width: '',
                hidden: false,
            },
            {
                id: 'firstname',
                disabled: false,
                label: 'Prénom',
                value: '',
                width: '',
                hidden: false,
            },
            {
                id: 'email',
                disabled: false,
                label: 'Email',
                value: '',
                width: '',
                hidden: false,
                type: 'email',
                required: true,
            },
            {
                id: 'telephone',
                disabled: false,
                label: 'Téléphone',
                value: '',
                width: '',
                hidden: false,
            },
            {
                id: 'company',
                disabled: false,
                label: 'Société',
                value: '',
                width: '',
                hidden: false,
            },
            // {
            //     id: 'is_subscribed',
            //     disabled: false,
            //     label: 'Abonnement',
            //     value: false,
            //     width: '100%',
            //     hidden: false,
            // },
        ];
    }

    return [
        {
            id: 'lastname',
            disabled: false,
            label: 'Nom',
            value: formData.lastname,
            width: '',
            hidden: false,
        },
        {
            id: 'firstname',
            disabled: false,
            label: 'Prénom',
            value: formData.firstname,
            width: '',
            hidden: false,
        },
        {
            id: 'email',
            disabled: true,
            label: 'Email',
            value: formData.email,
            width: '',
            hidden: false,
            type: 'email',
            required: true,
        },
        {
            id: 'telephone',
            disabled: false,
            label: 'Téléphone',
            value: formData.telephone,
            width: '',
            hidden: false,
        },
        {
            id: 'company',
            disabled: false,
            label: 'Société',
            value: formData.company,
            width: '',
            hidden: false,
        },
        // {
        //     id: 'is_subscribed',
        //     disabled: false,
        //     label: 'Abonnement',
        //     value: formData.is_subscribed,
        //     width: '100%',
        //     hidden: false,
        // },
    ];
};

export const formatCustomerInfo = (
    customerData: APICustomer
): Array<FormattedCustomerInfo> | undefined => {
    return [
        {
            id: 'id',
            disabled: true,
            label: 'ID Client',
            value: customerData.id,
            width: '',
            hidden: false,
        },
        {
            id: '99',
            disabled: true,
            label: 'hidden',
            value: '',
            width: '',
            hidden: true,
        },
        {
            id: 'lastname',
            disabled: false,
            label: 'Nom',
            value: customerData.lastname,
            width: '',
            hidden: false,
        },
        {
            id: 'firstname',
            disabled: false,
            label: 'Prénom',
            value: customerData.firstname,
            width: '',
            hidden: false,
        },
        {
            id: 'email',
            disabled: false,
            label: 'Email',
            value: customerData.email,
            width: '99%',
            hidden: false,
        },
        {
            id: 'telephone',
            disabled: false,
            label: 'Téléphone',
            value: customerData.telephone,
            width: '',
            hidden: false,
        },
        {
            id: 'company',
            disabled: false,
            label: 'Société',
            value: customerData.company,
            width: '',
            hidden: false,
        },
        {
            id: 'group',
            disabled: true,
            label: 'Groupe client',
            value: customerData.group.label,
            width: '',
            hidden: false,
        },
        {
            id: 'created_at',
            disabled: true,
            label: 'Client depuis le',
            value: customerData.created_at,
            width: '',
            hidden: false,
        },
    ];
};

export const formatCustomerAddress = (
    customerData: APICustomer,
    addressType: string
): Array<FormattedCustomerInfo> | undefined => {
    const defaultAddress = defaultCustomerAddress(addressType, customerData);

    if (defaultAddress) {
        return [
            {
                id: 'lastname',
                disabled: false,
                label: 'Nom',
                value: defaultAddress?.lastname,
                width: '',
                hidden: false,
                required: true,
            },
            {
                id: 'firstname',
                disabled: false,
                label: 'Prénom',
                value: defaultAddress?.firstname,
                width: '',
                hidden: false,
                required: true,
            },
            {
                id: 'street',
                disabled: false,
                label: 'Adresse',
                value: defaultAddress?.street[0],
                width: '66%',
                hidden: false,
                required: true,
            },
            {
                id: 'postcode',
                disabled: false,
                label: 'Code postal',
                value: defaultAddress?.postcode,
                width: '33%',
                hidden: false,
                required: true,
            },
            {
                id: 'city',
                disabled: false,
                label: 'Ville',
                value: defaultAddress?.city,
                width: '',
                hidden: false,
                required: true,
            },
            {
                id: 'country',
                disabled: false,
                label: 'Pays',
                value: defaultAddress?.country.label,
                width: '',
                hidden: false,
                required: false,
            },
            {
                id: 'telephone',
                disabled: false,
                label: 'Téléphone',
                value: defaultAddress?.telephone,
                width: '',
                hidden: false,
                required: false,
            },
            {
                id: 'company',
                disabled: false,
                label: 'Société',
                value: defaultAddress?.company,
                width: '',
                hidden: false,
                required: false,
            },
            //{id: 9, disabled: false, label: 'Informations supplémentaires', value: defaultAddress.?},
        ];
    }
};

export const emptyCustomerAddress = [
    {
        id: 'lastname',
        disabled: false,
        label: 'Nom',
        value: '',
        width: '',
        hidden: false,
        required: true,
    },
    {
        id: 'firstname',
        disabled: false,
        label: 'Prénom',
        value: '',
        width: '',
        hidden: false,
        required: true,
    },
    {
        id: 'street',
        disabled: false,
        label: 'Adresse',
        value: '',
        width: '66%',
        hidden: false,
        required: true,
    },
    {
        id: 'postcode',
        disabled: false,
        label: 'Code postal',
        value: '',
        width: '33%',
        hidden: false,
        required: true,
    },
    {
        id: 'city',
        disabled: false,
        label: 'Ville',
        value: '',
        width: '',
        hidden: false,
        required: true,
    },
    {
        id: 'country',
        disabled: false,
        label: 'Pays',
        value: '',
        width: '',
        hidden: false,
        required: false,
    },
    {
        id: 'telephone',
        disabled: false,
        label: 'Téléphone',
        value: '',
        width: '',
        hidden: false,
        required: false,
    },
    {
        id: 'company',
        disabled: false,
        label: 'Société',
        value: '',
        width: '',
        hidden: false,
        required: false,
    },
];

const getMutationOptions = (
    formType: CustomerFormTypesExtended,
    id: number,
    formData: CustomerFormData,
    BIS?: boolean
): MutationOptions => {
    switch (formType) {
        case CustomerFormTypesEnum.INFO:
            return {
                mutation: CUSTOMER_UPDATE,
                variables: {
                    customer_id: id,
                    lastname: formData.infolastname,
                    firstname: formData.infofirstname,
                    email: formData.infoemail,
                    company: formData.infocompany,
                    telephone: formData.infotelephone
                        ? formData.infotelephone
                        : 'n/r',
                    // is_subscribed: formData.is_subscribed,
                },
            };
        case CustomerFormTypesEnum.SHIPPING:
            return {
                mutation: ADDRESS_UPDATE,
                variables: {
                    address_id: id,
                    lastname: formData.shippinglastname,
                    firstname: formData.shippingfirstname,
                    telephone: formData.shippingtelephone
                        ? formData.shippingtelephone
                        : 'n/r',
                    city: formData.shippingcity,
                    company: formData.shippingcompany,
                    street: [formData.shippingstreet],
                    postcode: formData.shippingpostcode,
                    default_shipping: true,
                },
            };
        case CustomerFormTypesEnum.BILLING:
            return {
                mutation: ADDRESS_CREATE,
                variables: {
                    customer_id: id,
                    lastname: formData.billinglastname,
                    firstname: formData.billingfirstname,
                    telephone: formData.billingtelephone
                        ? formData.billingtelephone
                        : 'n/r',
                    city: formData.billingcity,
                    company: formData.billingcompany,
                    country_code: 'FR',
                    street: [formData.billingstreet],
                    postcode: formData.billingpostcode,
                    default_billing: true,
                },
            };
        case CustomerFormTypesEnum.NEWSHIPPING:
            if (BIS) {
                return {
                    mutation: ADDRESS_CREATE,
                    variables: {
                        customer_id: id,
                        lastname: formData.newshippinglastname,
                        firstname: formData.newshippingfirstname,
                        telephone: formData.newshippingtelephone
                            ? formData.newshippingtelephone
                            : 'n/r',
                        city: formData.newshippingcity,
                        company: formData.newshippingcompany,
                        country_code: 'FR',
                        street: [formData.newshippingstreet],
                        postcode: formData.newshippingpostcode,
                        default_shipping: true,
                        default_billing: true,
                    },
                };
            } else {
                return {
                    mutation: ADDRESS_CREATE,
                    variables: {
                        customer_id: id,
                        lastname: formData.newshippinglastname,
                        firstname: formData.newshippingfirstname,
                        telephone: formData.newshippingtelephone
                            ? formData.newshippingtelephone
                            : 'n/r',
                        city: formData.newshippingcity,
                        company: formData.newshippingcompany,
                        country_code: 'FR',
                        street: [formData.newshippingstreet],
                        postcode: formData.newshippingpostcode,
                        default_shipping: true,
                    },
                };
            }
        case CustomerFormTypesEnum.NEWBILLING:
            return {
                mutation: ADDRESS_CREATE,
                variables: {
                    customer_id: id,
                    lastname: formData.newbillinglastname,
                    firstname: formData.newbillingfirstname,
                    telephone: formData.newbillingtelephone
                        ? formData.newbillingtelephone
                        : 'n/r',
                    city: formData.newbillingcity,
                    company: formData.newbillingcompany,
                    country_code: 'FR',
                    street: [formData.newbillingstreet],
                    postcode: formData.newbillingpostcode,
                    default_billing: true,
                },
            };
    }
};

export const updateCartCustomerInfo = (
    formData: CartFormValues,
    customerID: number
): Promise<any> => {
    const mutationOptions = {
        mutation: CUSTOMER_UPDATE,
        variables: {
            customer_id: customerID,
            lastname: formData.lastname,
            firstname: formData.firstname,
            email: formData.email,
            company: formData.company,
            telephone: formData.telephone ? formData.telephone : 'n/r',
            // is_subscribed: formData.is_subscribed,
        },
    };

    return apolloMutate(mutationOptions);
};

export const updateCustomerInfo = async (
    formData: CustomerFormData,
    customerID: number
): Promise<PosError | void> => {
    const mutationOptions = getMutationOptions(
        CustomerFormTypesEnum.INFO,
        customerID,
        formData
    );

    return apolloMutate(mutationOptions)
        .then((infoRes) => {
            if (!infoRes?.errors?.length) return;
            console.error(infoRes.errors[0]);
            return infoRes.errors[0];
        })
        .catch((infoErr) => {
            console.error(infoErr);
        });
};

export const updateCustomerAddress = (
    formData: CustomerFormData,
    id: number,
    addressType: CustomerFormTypesExtended
): void => {
    const mutationOptions = (): MutationOptions => {
        switch (addressType) {
            case CustomerFormTypesEnum.SHIPPING:
                return getMutationOptions(addressType, id, formData);
            case CustomerFormTypesEnum.BILLING:
                return getMutationOptions(addressType, id, formData);
            default:
                return getMutationOptions(addressType, id, formData);
        }
    };
    apolloMutate(mutationOptions())
        .then((addressRes) => {
            if (!addressRes?.errors?.length) return;
            console.error(addressRes.errors[0]);
        })
        .catch((addressErr) => {
            console.error(addressErr);
        });
};

export const createCustomerAddress = (
    formData: CustomerFormData,
    customerID: number,
    addressType: CustomerFormTypesExtended,
    BIS?: boolean
): void => {
    const mutationOptions = (): MutationOptions => {
        switch (addressType) {
            case CustomerFormTypesEnum.NEWSHIPPING:
                return getMutationOptions(addressType, customerID, formData);
            case CustomerFormTypesEnum.BILLING:
                return getMutationOptions(addressType, customerID, formData);
            case CustomerFormTypesEnum.NEWBILLING:
                return getMutationOptions(
                    addressType,
                    customerID,
                    formData,
                    BIS
                );
            default:
                return getMutationOptions(
                    addressType,
                    customerID,
                    formData,
                    BIS
                );
        }
    };

    apolloMutate(mutationOptions())
        .then((addressRes) => {
            if (!addressRes?.errors?.length) return;
            console.error(addressRes.errors[0]);
        })
        .catch((addressErr) => {
            console.error(addressErr);
        });
};

export const updateDefaultBillingAddress = (
    formData: CustomerFormData,
    shippingID: number
): void => {
    const mutationOptions = {
        mutation: ADDRESS_UPDATE,
        variables: {
            address_id: shippingID,
            default_billing: true,
        },
    };

    apolloMutate(mutationOptions)
        .then((addressRes) => {
            if (!addressRes?.errors?.length) return;
            console.error(addressRes.errors[0]);
        })
        .catch((addressErr) => {
            console.error(addressErr);
        });
};

export const fetchCustomersAutocomplete = async (
    storeID: number,
    inputValue: string
): Promise<any> => {
    const queryOptions = {
        query: CUSTOMER_SEARCH_SIMPLE,
        variables: {
            store_id: storeID,
            filter: {
                email: { contains: inputValue },
            },
            pageSize: 20,
            currentPage: 1,
        },
    };

    return await apolloQuery(queryOptions);
};

export const formatCustomersAutocomplete = (
    items: Array<APISearchAutoComplete>
): Array<SearchAutoComplete> => {
    const newItems: Array<SearchAutoComplete> = [];

    items.forEach((item) => {
        newItems.push({
            label: item.email,
            id: item.id.toString(),
        });
    });

    return newItems;
};

// The 4-5 functions below can be improved (separation of concerns & refactoring).
// For the current needs and bugs, they should be good enough
export const assignCustomerToCart = async (
    storeID: number,
    cartID: string,
    customerID: number,
    cartItems: Array<APIProductItem>
): Promise<any> => {
    const customerMutation = {
        mutation: CART_SETCUSTOMER,
        variables: {
            store_id: storeID,
            cart_id: cartID,
            customer_id: customerID,
        },
    };

    return await apolloMutate(customerMutation)
        .then((customerData) => {
            const defaultBilling =
                customerData?.data?.posAssignCartToCustomer?.customer
                    ?.default_billing;
            if (
                customerData?.errors?.length &&
                customerData.errors[0]?.message
            ) {
                console.error('Customer address error, retrying');
                return apolloMutate(customerMutation).then((customerData) => {
                    if (!customerData?.data?.posAssignCartToCustomer?.customer)
                        return;
                    if (!defaultBilling) {
                        return assignNonExistingAddressesToCart(
                            storeID,
                            cartID,
                            {
                                billing: NRAddress,
                                shipping: NRAddress,
                            }
                        );
                    }

                    return assignExistingAddressesToCart(storeID, cartID, {
                        billing:
                            customerData.data.posAssignCartToCustomer.customer
                                .default_billing,
                        shipping:
                            customerData.data.posAssignCartToCustomer.customer
                                .default_shipping,
                    });
                });
            }
            if (!customerData?.data?.posAssignCartToCustomer?.customer) return;
            if (!defaultBilling) {
                return assignNonExistingAddressesToCart(storeID, cartID, {
                    billing: NRAddress,
                    shipping: NRAddress,
                });
            }

            return assignExistingAddressesToCart(storeID, cartID, {
                billing:
                    customerData.data.posAssignCartToCustomer.customer
                        .default_billing,
                shipping:
                    customerData.data.posAssignCartToCustomer.customer
                        .default_shipping,
            });
        })
        .then((assignAddressData) => {
            const availableMethods =
                assignAddressData?.data?.posSetBillingAddressOnCart?.cart
                    ?.shipping_addresses[0]?.available_shipping_methods;
            if (!availableMethods) return;

            const defaultDeliveryType = cartItems?.length
                ? findDefaultDeliveryType(cartItems)
                : 'TO_DELIVERY_POINT';
            const shippingMethods = findMethodsByDeliveryType(
                availableMethods,
                defaultDeliveryType
            );
            const defaultCarrier = findDefaultCarrier(
                shippingMethods,
                defaultDeliveryType
            );

            if (!defaultCarrier) return;
            if (defaultDeliveryType == 'TO_DELIVERY_POINT') {
                defaultCarrier.delivery_point_id = findLocationIDByStoreID(
                    availableMethods,
                    storeID
                );
            }

            return setDeliveryMethodOnCart(storeID, cartID, defaultCarrier);
        });
};

export const assignGuestCustomerToCart = async (
    storeID: number,
    cartID: string,
    customerInfo: CartFormValues,
    cartItems: Array<APIProductItem>
): Promise<any> => {
    const customerMutation = {
        mutation: CART_SETGUESTCUSTOMER,
        variables: {
            cart_id: cartID,
            firstname: customerInfo.firstname,
            lastname: customerInfo.lastname,
            email: customerInfo.email,
            telephone: customerInfo.telephone,
            company: customerInfo.company,
        },
    };

    return await apolloMutate(customerMutation)
        .then((assignData) => {
            if (assignData?.errors?.length) return assignData.errors[0];

            const guestAddress = {
                city: 'n/r',
                company: customerInfo?.company ?? 'n/r',
                country_code: 'FR',
                firstname: customerInfo?.firstname ?? 'n/r',
                lastname: customerInfo?.lastname ?? 'n/r',
                postcode: 'n/r',
                street: ['n/r'],
                telephone: customerInfo?.telephone ?? 'n/r',
            };

            return assignNonExistingAddressesToCart(storeID, cartID, {
                shipping: guestAddress,
                billing: guestAddress,
            });
        })
        .then((assignAddressData) => {
            const availableMethods =
                assignAddressData?.data?.posSetBillingAddressOnCart?.cart
                    ?.shipping_addresses[0]?.available_shipping_methods;
            if (!availableMethods) return assignAddressData;

            const defaultDeliveryType = cartItems?.length
                ? findDefaultDeliveryType(cartItems)
                : 'TO_DELIVERY_POINT';

            const shippingMethods = findMethodsByDeliveryType(
                availableMethods,
                defaultDeliveryType
            );
            const defaultCarrier = findDefaultCarrier(
                shippingMethods,
                defaultDeliveryType
            );

            if (!defaultCarrier) return assignAddressData;
            if (defaultDeliveryType == 'TO_DELIVERY_POINT') {
                defaultCarrier.delivery_point_id = findLocationIDByStoreID(
                    availableMethods,
                    storeID
                );
            }

            return setDeliveryMethodOnCart(storeID, cartID, defaultCarrier);
        });
};

export const assignExistingAddressesToCart = async (
    storeID: number,
    cartID: string,
    customerAddresses: CustomerAddresses
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_SETSHIPPING,
        variables: {
            store_id: storeID,
            cart_id: cartID,
            address_id: customerAddresses.shipping,
        },
    };
    return await apolloMutate(mutationOptions)
        .then(() => {
            return {
                mutation: CART_SETBILLING,
                variables: {
                    store_id: storeID,
                    cart_id: cartID,
                    address_id: customerAddresses.billing,
                },
            };
        })
        .then((mutationOptions) => {
            return apolloMutate(mutationOptions);
        });
};

export const assignNonExistingAddressToCart = async (
    storeID: number,
    cartID: string,
    addressType: AddressTypes,
    customerAddress: CustomerAddressInput
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_SETSHIPPING_FULL,
        variables: {
            store_id: storeID,
            cart_id: cartID,
            address: customerAddress,
        },
    };
    if (addressType == CustomerFormTypesEnum.BILLING) {
        mutationOptions.mutation = CART_SETBILLING_FULL;
    }

    return await apolloMutate(mutationOptions);
};

export const assignNonExistingAddressesToCart = async (
    storeID: number,
    cartID: string,
    customerAddresses: CustomerAddressesNR
): Promise<any> => {
    return await assignNonExistingAddressToCart(
        storeID,
        cartID,
        CustomerFormTypesEnum.SHIPPING,
        customerAddresses.shipping
    ).then(() => {
        return assignNonExistingAddressToCart(
            storeID,
            cartID,
            CustomerFormTypesEnum.BILLING,
            customerAddresses.billing
        );
    });
};

export const assignAddressToCart = async (
    storeID: number,
    cartID: string,
    customerAddresses: CustomerAddressesNR,
    hasID: boolean,
    addressType: AddressTypes
): Promise<any> => {
    let MUTATION: any;
    if (hasID) {
        addressType == CustomerFormTypesEnum.SHIPPING
            ? (MUTATION = CART_SETSHIPPING)
            : (MUTATION = CART_SETBILLING);
    } else {
        addressType == CustomerFormTypesEnum.SHIPPING
            ? (MUTATION = CART_SETSHIPPING_FULL)
            : (MUTATION = CART_SETBILLING_FULL);
    }

    const mutationOptions = {
        mutation: MUTATION,
        variables: {
            store_id: storeID,
            cart_id: cartID,
            address:
                addressType == CustomerFormTypesEnum.SHIPPING
                    ? customerAddresses.shipping
                    : customerAddresses.billing,
        },
    };
    return await apolloMutate(mutationOptions);
};

export const findLocalCustomerData = (cart: APICart): CustomerRecap => {
    if (cart?.customer) return cart.customer;
    return {
        company: cart?.company,
        created_at: '',
        email: cart?.email,
        firstname: cart?.firstname,
        id: '',
        lastname: cart?.lastname,
        telephone: cart?.telephone,
    };
};
