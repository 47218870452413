import React from 'react';

import {
    GridCellParams,
    GridColDef,
    GridPinnedColumns,
} from '@mui/x-data-grid-pro';

import ButtonAddCustomer from './ButtonAddCustomer';
import ButtonEditCustomer from './ButtonEditCustomer';
import {
    dateRangeRenderHeader,
    dropdownRenderHeader,
    textRenderHeader,
} from '../Filters/RenderFilters';

import { Box } from '@mui/material';

export const customerColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
        hideSortIcons: true,
        sortable: false,
        align: 'center',
        resizable: true,
        headerAlign: 'center',
    },
    {
        field: 'email',
        headerName: 'Mail',
        width: 300,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'customer'),
    },
    {
        field: 'firstname',
        headerName: 'Prénom',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'customer'),
    },
    {
        field: 'lastname',
        headerName: 'Nom',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'customer'),
    },
    {
        field: 'created_at',
        headerName: 'Client depuis le',
        width: 165,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dateRangeRenderHeader(params, 'customer'),
    },
    {
        field: 'company',
        headerName: 'Société',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'customer'),
    },
    {
        field: 'telephone',
        headerName: 'Téléphone',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'customer'),
    },
    {
        field: 'postcode',
        headerName: 'Code postal',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'customer'),
    },
    {
        field: 'group',
        headerName: 'Groupe client',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'customer'),
    },
    {
        field: 'country',
        headerName: 'Pays',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'customer'),
    },
    {
        field: 'is_subscribed',
        headerName: 'Abonnement',
        hide: true,
    },
    {
        field: 'edit',
        headerName: '',
        width: 20,
        resizable: false,
        hideSortIcons: true,
        sortable: false,
        align: 'center',
        renderCell: (params: GridCellParams): React.ReactElement => {
            return (
                <Box
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    sx={{ lineHeight: 'initial' }}
                >
                    <ButtonEditCustomer params={params} />
                </Box>
            );
        },
    },
    {
        field: 'add',
        headerName: '',
        width: 20,
        resizable: false,
        hideSortIcons: true,
        sortable: false,
        align: 'center',
        renderCell: (params: GridCellParams): React.ReactElement => {
            return (
                <Box
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    sx={{ lineHeight: 'initial' }}
                >
                    <ButtonAddCustomer params={params} />
                </Box>
            );
        },
    },
];

export const pinnedCustomerColumns: GridPinnedColumns = {
    left: ['id'],
    right: ['edit', 'add'],
};
