import React from 'react';

import { useQuery } from '@apollo/client';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { LOCATIONS } from '../../queries/LOCATIONS_QUERY';

import { styled } from '@mui/material/styles';
import { theme } from '../../themeOptions';
import { Box, Grid, Typography } from '@mui/material';
import { UserContext } from '../../providers/UserProvider';

import LocationSelectionGrid from '../LocationSelectionGrid/LocationSelectionGrid';

const StoreSelection = styled(Grid)(() => ({
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
}));
const ContentContainer = styled(Box)(() => ({
    marginBottom: '33px',
}));
const StoreSelectionError = styled(Typography)(() => ({
    color: theme.palette.error.main,
    textAlign: 'left',
}));

export const StoreSelectionStep: React.FunctionComponent = () => {
    const [userStorage] = useSessionStorage('user', null);
    const { login } = React.useContext(UserContext);

    const handleLocationSelection = (
        locationID: number,
        storeID: number,
        storeCode: string
    ) => {
        login(userStorage, storeID, storeCode, locationID, true);
    };

    const { loading, error, data } = useQuery(LOCATIONS, {
        variables: { user: userStorage },
    });

    if (loading) {
        return <div>Chargement des magasins...</div>;
    } else if (data) {
        return (
            <StoreSelection>
                <ContentContainer>
                    <Typography component="h1" variant="h4">
                        Magasin
                    </Typography>
                    <Typography
                        component="p"
                        variant="body1"
                        sx={{ marginTop: '8px' }}
                    >
                        Choisissez votre magasin :
                    </Typography>
                </ContentContainer>

                <LocationSelectionGrid
                    locations={data.posSeller.locations}
                    handleLocationSelection={handleLocationSelection}
                />
            </StoreSelection>
        );
    } else if (error) {
        console.log(error);
        return (
            <StoreSelectionError variant="body2">
                Une erreur est survenue, veuillez recharger la page.
            </StoreSelectionError>
        );
    } else {
        return null;
    }
};
