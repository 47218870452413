import React from 'react';

import { styled } from '@mui/material/styles';
import { InfoOutlined } from '@mui/icons-material';
import { theme } from '../../themeOptions';
import { Tooltip } from '@mui/material';

const InfoIcon = styled(InfoOutlined)(() => ({
    height: '15px',
    width: '15px',
    color: theme.palette.action.active,
}));

const CommentTooltip: React.FC = () => {
    return (
        <Tooltip title={'Ce message sera transmis à la logistique'}>
            <InfoIcon />
        </Tooltip>
    );
};

export default CommentTooltip;
