import { gql } from '@apollo/client';

export const CART_UNAPPLYGIFTCARDORCOUPON = gql`
    mutation posRemoveGiftCardOrCouponCodeFromCart(
        $cart_id: String!
        $code: String!
    ) {
        posRemoveGiftCardOrCouponCodeFromCart(
            input: { cart_id: $cart_id, code: $code }
        ) {
            cart {
                id
            }
        }
    }
`;
