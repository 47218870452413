import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Step, StepLabel, Stepper } from '@mui/material';
import { StoreSelectionStep } from './StoreSelectionStep';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { LoginFormContext } from './LoginWizard';

export const StoreSelectionWrapper: React.FunctionComponent = () => {
    const LoginStepper = styled(Stepper)(() => ({
        width: '400px',
        marginBottom: '90px',
    }));
    const ButtonPrevious = styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '150px',
    }));
    const IconPrevious = styled(ArrowBackOutlinedIcon)(() => ({
        height: '18px',
        marginRight: '8px',
        marginBottom: '3px',
    }));

    const context = React.useContext(LoginFormContext);
    const steps = ['Connexion', 'Magasin'];

    return (
        <>
            <LoginStepper activeStep={context.activeStep}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </LoginStepper>
            <StoreSelectionStep></StoreSelectionStep>
            <ButtonPrevious>
                <Button color="inherit" onClick={context.handleBack}>
                    <IconPrevious />
                    Précédent
                </Button>
            </ButtonPrevious>
        </>
    );
};
