import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { OrderGridLocation } from '../../services/orders.service';
import { RecapState } from '../../pages/Recap/Recap';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Edit, Print, SaveAlt, UTurnLeft } from '@mui/icons-material';
import { CartContext } from '../../providers/CartProvider';

type Props = {
    recapState: RecapState;
    invoices: Array<string>;
    handlePrint: () => void;
    handlePlaceOrder: (cartID: string) => void;
    isPlacingOrder?: boolean;
    handleNextOrder?: (index: number | null) => void;
};

const Container = styled(Paper)(() => ({
    width: '100%',
    minHeight: '166px',
    padding: '43px 51px 53px 48px',
    position: 'sticky',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const Title = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left',
}));
const Buttons = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
}));
const StyledEdit = styled(Edit)(() => ({
    height: '16px',
    width: '16px',
    marginRight: '8px',
}));
const StyledPrint = styled(Print)(() => ({
    height: '16px',
    width: '16px',
    marginRight: '8px',
}));
const StyledSave = styled(SaveAlt)(() => ({
    height: '16px',
    width: '16px',
    marginRight: '8px',
}));
const StyledRma = styled(UTurnLeft)(() => ({
    height: '16px',
    width: '16px',
}));
const StyledButton = styled(Button)(() => ({
    height: '30px',
    marginLeft: '7px',
    padding: '4px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.primary.main,
    background: 'transparent',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
}));
const ValidateButton = styled(StyledButton)(() => ({
    height: '36px',
    padding: '6px 16px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
const BackButton = styled(StyledButton)(() => ({
    background: 'rgba(47, 43, 153, 0.08)',
    border: 'none',
    '&:hover': {
        background: 'rgba(47, 43, 153, 0.08)',
    },
}));

const RecapHeader: React.FC<Props> = ({
    recapState,
    invoices,
    handlePrint,
    handlePlaceOrder,
    isPlacingOrder,
    handleNextOrder,
}) => {
    const history = useHistory();
    const { state } = useLocation<OrderGridLocation>();
    const { selectCart } = React.useContext(CartContext);

    return (
        <Container elevation={1}>
            <Title>
                <Typography variant="h3">
                    Récapitulatif de la commande
                </Typography>

                <Typography>{recapState.id}</Typography>
            </Title>
            {{
                cart: (
                    <Buttons>
                        <StyledButton
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <StyledEdit />
                            MODIFIER
                        </StyledButton>

                        <StyledButton disabled onClick={handlePrint}>
                            <StyledPrint />
                            IMPRIMER
                        </StyledButton>

                        <ValidateButton
                            disabled={isPlacingOrder}
                            sx={{
                                color: isPlacingOrder
                                    ? theme.palette.action.disabled
                                    : '',
                                background: isPlacingOrder
                                    ? theme.palette.action.disabledBackground
                                    : '',
                                '&:hover': {
                                    color: isPlacingOrder
                                        ? theme.palette.action.disabled
                                        : '',
                                    background: isPlacingOrder
                                        ? theme.palette.action
                                              .disabledBackground
                                        : '',
                                },
                            }}
                            onClick={() => {
                                handlePlaceOrder(recapState.id);
                            }}
                        >
                            VALIDER LA COMMANDE
                        </ValidateButton>
                    </Buttons>
                ),

                order: (
                    <Buttons>
                        <BackButton
                            onClick={() => {
                                if (recapState?.isFromCart) {
                                    history.push('/');
                                    return;
                                }
                                history.push('/commandes', {
                                    ...state,
                                    gridKeepPagination: true,
                                });
                            }}
                        >
                            {recapState?.isFromCart ? 'FERMER' : 'RETOUR'}
                        </BackButton>

                        {handleNextOrder && (
                            <StyledButton onClick={() => handleNextOrder(null)}>
                                COMMANDE SUIVANTE
                            </StyledButton>
                        )}

                        <StyledButton onClick={handlePrint}>
                            <StyledPrint />
                            IMPRIMER
                        </StyledButton>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={recapState.rma_url}
                        >
                            <StyledButton>
                                <StyledRma />
                                RMA
                            </StyledButton>
                        </a>

                        {invoices?.length ? (
                            invoices.map((invoice, index) => (
                                <a
                                    key={index + 1}
                                    href={invoice}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <StyledButton>
                                        <StyledSave />
                                        TÉLÉCHARGER FACTURE N° {index + 1}
                                    </StyledButton>
                                </a>
                            ))
                        ) : (
                            <StyledButton disabled={true}>
                                <StyledSave />
                                TÉLÉCHARGER FACTURE
                            </StyledButton>
                        )}
                    </Buttons>
                ),
            }[recapState.type] || ''}
        </Container>
    );
};

export default RecapHeader;
