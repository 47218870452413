import React from 'react';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { PersonAddAlt1Outlined } from '@mui/icons-material';

type Props = {
    onClickHandler: () => void;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledButton = styled(Button)(() => ({
    height: '36px',
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: 'uppercase',
    '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));
const PersonIcon = styled(PersonAddAlt1Outlined)(() => ({
    height: '18px',
    width: '18px',
    marginLeft: '10px',
    color: theme.palette.secondary.contrastText,
}));

const NewCustomerButton: React.FC<Props> = ({ onClickHandler }) => {
    return (
        <Container>
            <StyledButton type="submit" onClick={onClickHandler}>
                Nouveau client
                <PersonIcon />
            </StyledButton>
        </Container>
    );
};

export default NewCustomerButton;
