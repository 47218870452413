import React from 'react';

import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

type Props = {
    content: number | string | null;
    defaultContent: string;
};

const StyledChip = styled(Chip)(() => ({
    height: '24px',
    width: 'fit-content',
    borderRadius: '16px',
    background: 'rgba(27, 31, 82, 0.1)',
}));

const ProductStockChip: React.FC<Props> = ({ content, defaultContent }) => {
    return <StyledChip label={content ?? defaultContent} />;
};

export default ProductStockChip;
