import { gql } from '@apollo/client';

export const CART_APPLYGIFTCARDORCOUPON = gql`
    mutation posApplyGiftCardOrCouponCodeOnCart(
        $cart_id: String!
        $code: String!
    ) {
        posApplyGiftCardOrCouponCodeOnCart(
            input: { cart_id: $cart_id, code: $code }
        ) {
            cart {
                id
            }
        }
    }
`;
