import React from 'react';
import { useHistory } from 'react-router-dom';
import { CartContext } from '../../providers/CartProvider';

import { Icon } from '@mui/material';

import AddToCartIcon from '../../icons/add-to-cart.svg';
import CustomSvgIcon from '../CustomSvgIcon/CustomSvgIcon';

import { ButtonIconContainer } from '../ButtonIconContainer/ButtonIconContainer';

type Props = {
    barcode: string;
};

export const ButtonAddProduct: React.FC<Props> = ({ barcode }) => {
    const history = useHistory();
    const { activeCart, addProduct } = React.useContext(CartContext);

    return (
        <ButtonIconContainer
            disabled={!activeCart}
            variant="text"
            onClick={(e) => {
                e.stopPropagation();
                addProduct(barcode, activeCart);
                history.push('/', {});
            }}
        >
            <Icon>
                <CustomSvgIcon src={AddToCartIcon} height={24} width={24} />
            </Icon>
        </ButtonIconContainer>
    );
};

export default ButtonAddProduct;
