import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import apolloQuery from '../../utils/apolloQuery';
import { CUSTOMER } from '../../queries/CUSTOMER_QUERY';

import { UserContext } from '../../providers/UserProvider';
import {
    AddressTypes,
    createCustomerAddress,
    CustomerFormData,
    CustomerFormTypesEnum,
    CustomerGridLocation,
    emptyCustomerAddress,
    formatCustomerAddress,
    formatCustomerInfo,
    FormattedCustomerInfo,
    updateCustomerAddress,
    updateCustomerInfo,
    updateDefaultBillingAddress,
} from '../../services/customer.service';

import { styled } from '@mui/material/styles';
import { Box, CircularProgress, RadioGroup, Typography } from '@mui/material';

import { CustomerSubscriptionCheckbox } from './CustomerSubscriptionCheckbox';
import { CustomerFields } from './CustomerFields';
import { CustomerFormButtons } from './CustomerFormButtons';
import { CustomerBISCheckbox } from './CustomerBISCheckbox';
import { CustomerAddAddressButton } from './CustomerAddAddressButton';
import { CustomerSelectAddressRadio } from './CustomerSelectAddressRadio';

const FormContainer = styled(Box)(() => ({
    display: 'flex',
}));
const LeftFormContainer = styled(Box)(() => ({
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
}));
const FormSection = styled(Box)(() => ({
    padding: '31px 50px 31px 33px',
    marginBottom: '22px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    background: '#f9f9f9',
}));
const RightFormContainer = styled(Box)(() => ({
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const CustomerInfo: React.FunctionComponent = () => {
    const { user } = React.useContext(UserContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [customerID, setCustomerID] = React.useState<number>();
    const [shippingID, setShippingID] = React.useState<number>();
    const [billingID, setBillingID] = React.useState<number>();
    const [isSameAddress, setIsSameAddress] = React.useState<boolean>(false);
    const [customerInfo, setCustomerInfo] =
        React.useState<Array<FormattedCustomerInfo>>();
    const [infoHasChanged, setInfoHasChanged] = React.useState<boolean>(false);
    const [shippingHasChanged, setShippingHasChanged] =
        React.useState<boolean>(false);
    const [billingHasChanged, setBillingHasChanged] =
        React.useState<boolean>(false);
    const [isNewShipping, setIsNewShipping] = React.useState<boolean>(false);
    const [isNewBilling, setIsNewBilling] = React.useState<boolean>(false);
    const [BISHasChanged, setBISHasChanged] = React.useState<boolean>(false);
    const [customerShipping, setCustomerShipping] =
        React.useState<Array<FormattedCustomerInfo>>();
    const [customerBilling, setCustomerBilling] =
        React.useState<Array<FormattedCustomerInfo>>();
    // const [isSubscribed, setIsSubscribed] = React.useState<boolean>();
    const [selectedShipping, setSelectedShipping] = React.useState<
        'primary' | 'secondary'
    >('primary');
    const [selectedBilling, setSelectedBilling] = React.useState<
        'primary' | 'secondary'
    >('primary');

    const { state } = useLocation<CustomerGridLocation>();
    const history = useHistory();
    const formMethods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    React.useEffect(() => {
        if (state) {
            const queryOptions = {
                query: CUSTOMER,
                variables: {
                    store_id: user.storeId,
                    email: state.email,
                },
            };
            const customer = async () => {
                return await apolloQuery(queryOptions);
            };

            setIsLoading(true);
            customer()
                .then((customerData) => {
                    customerData = customerData.data.posCustomer;

                    setCustomerID(customerData.id);
                    setShippingID(customerData.default_shipping);
                    setBillingID(customerData.default_billing);
                    setIsSameAddress(
                        customerData.default_billing ==
                            customerData.default_shipping
                    );
                    setCustomerInfo(formatCustomerInfo(customerData));
                    setCustomerShipping(
                        formatCustomerAddress(
                            customerData,
                            CustomerFormTypesEnum.SHIPPING
                        )
                    );
                    setCustomerBilling(
                        formatCustomerAddress(
                            customerData,
                            CustomerFormTypesEnum.BILLING
                        )
                    );
                    // setIsSubscribed(customerData.is_subscribed);

                    setIsLoading(false);
                })
                .catch((customerErr) => {
                    console.error(customerErr);
                });
        } else {
            console.error('No email was found');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialBIS = () => {
        return shippingID == billingID;
    };
    const handleInfoChange = (newSubscriptionState?: boolean) => {
        setInfoHasChanged(true);
        // if (newSubscriptionState !== undefined)
        //     setIsSubscribed(newSubscriptionState);
    };
    const handleShippingChange = () => {
        setShippingHasChanged(true);
    };
    const handleBillingChange = () => {
        setBillingHasChanged(true);
    };
    const handleBISChange = () => {
        const defaultValues: any = {};
        customerBilling?.forEach((info) => {
            defaultValues['billing' + info.id.toString()] = info.value;
        });
        formMethods.reset({ ...defaultValues });
        setBISHasChanged(true);
        setIsSameAddress((prevState) => !prevState);
    };
    const handleAddAddress = (section: AddressTypes) => {
        console.log(isSameAddress);
        if (isSameAddress) handleBISChange();

        switch (section) {
            case CustomerFormTypesEnum.SHIPPING:
                setIsNewShipping(true);
                setSelectedShipping('secondary');
                break;
            case CustomerFormTypesEnum.BILLING:
                setIsNewBilling(true);
                setSelectedBilling('secondary');
                break;
        }
    };

    const onSubmit: SubmitHandler<any> = async (formData: CustomerFormData) => {
        const isPrimary = (addressType: AddressTypes): boolean => {
            if (addressType == CustomerFormTypesEnum.SHIPPING) {
                return formData.shippingform == 'primary';
            } else {
                return formData.billingform == 'primary';
            }
        };
        const formInfo = () => {
            return !!(infoHasChanged && customerID);
        };
        const formShipping = () => {
            return !!(
                shippingHasChanged &&
                shippingID &&
                isPrimary(CustomerFormTypesEnum.SHIPPING)
            );
        };
        const formBillingNeverBIS = () => {
            return !!(
                billingHasChanged &&
                billingID &&
                !isSameAddress &&
                !initialBIS() &&
                isPrimary(CustomerFormTypesEnum.BILLING)
            );
        };
        const formNewShipping = () => {
            return !!(
                isNewShipping &&
                customerID &&
                !isPrimary(CustomerFormTypesEnum.SHIPPING)
            );
        };
        const formBillingInitialBIS = () => {
            return !!(
                billingHasChanged &&
                billingID &&
                initialBIS() &&
                !isSameAddress &&
                isPrimary(CustomerFormTypesEnum.BILLING)
            );
        };
        const formNewBilling = () => {
            return !!(
                isNewBilling &&
                billingID &&
                !isSameAddress &&
                !isPrimary(CustomerFormTypesEnum.BILLING)
            );
        };
        const formBISChanged = () => {
            return !initialBIS() && isSameAddress;
        };

        if (formInfo() && customerID) {
            const updateError = await updateCustomerInfo(formData, customerID);
            if (!updateError) {
                return true;
            }
            const formattedErrorMessages = [
                'Les clients ayant le même e-mail existent déjà dans un site web du groupe.',
                'n’est pas une adresse email valide.',
            ];
            const message = formattedErrorMessages.find((error) =>
                updateError.message.includes(error)
            );
            if (message) {
                formMethods.setError('infoemail', { message });
            }
            return false;
        }
        if (formNewShipping() && customerID) {
            if (isSameAddress) {
                createCustomerAddress(
                    formData,
                    customerID,
                    CustomerFormTypesEnum.NEWSHIPPING,
                    isSameAddress
                );
            } else {
                createCustomerAddress(
                    formData,
                    customerID,
                    CustomerFormTypesEnum.NEWSHIPPING,
                    isSameAddress
                );
            }
        }
        if (formShipping() && shippingID) {
            updateCustomerAddress(
                formData,
                shippingID,
                CustomerFormTypesEnum.SHIPPING
            );
        }
        if (formBillingNeverBIS() && billingID) {
            updateCustomerAddress(
                formData,
                billingID,
                CustomerFormTypesEnum.BILLING
            );
        }
        if (formBillingInitialBIS() && customerID) {
            createCustomerAddress(
                formData,
                customerID,
                CustomerFormTypesEnum.BILLING
            );
        }
        if (formNewBilling() && customerID) {
            createCustomerAddress(
                formData,
                customerID,
                CustomerFormTypesEnum.NEWBILLING
            );
        }
        if (formBISChanged() && shippingID) {
            updateDefaultBillingAddress(formData, shippingID);
        }

        return true;
    };

    return (
        <FormProvider {...formMethods}>
            <FormContainer
                component="form"
                onSubmit={formMethods.handleSubmit((formData) =>
                    onSubmit(formData).then((isValid: boolean) => {
                        if (!isValid) return;
                        setInfoHasChanged(false);
                        setShippingHasChanged(false);
                        setBillingHasChanged(false);
                        setIsNewShipping(false);
                        setIsNewBilling(false);
                        setSelectedShipping('primary');
                        setSelectedBilling('primary');

                        history.push('/clients', {
                            ...state,
                            gridKeepPagination: true,
                        });
                    })
                )}
            >
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <LeftFormContainer>
                        <FormSection>
                            <Typography component="h2" variant="subtitle2">
                                Informations client
                            </Typography>

                            <CustomerFields
                                section={CustomerFormTypesEnum.INFO}
                                customerInfo={customerInfo}
                                onChangeHandler={() => handleInfoChange()}
                            />
                        </FormSection>

                        <FormSection>
                            <Typography component="h2" variant="subtitle2">
                                Adresse de livraison
                            </Typography>

                            <RadioGroup
                                value={selectedShipping}
                                {...formMethods.register('shippingform', {
                                    required: false,
                                })}
                            >
                                <CustomerSelectAddressRadio
                                    addressType={CustomerFormTypesEnum.SHIPPING}
                                    addressSelected="primary"
                                    onClickHandler={() => {
                                        setSelectedShipping('primary');
                                    }}
                                />
                                <CustomerFields
                                    section={CustomerFormTypesEnum.SHIPPING}
                                    customerInfo={customerShipping}
                                    onChangeHandler={() =>
                                        handleShippingChange()
                                    }
                                />

                                {!isNewShipping ? (
                                    <CustomerAddAddressButton
                                        addressType={
                                            CustomerFormTypesEnum.SHIPPING
                                        }
                                        onClickHandler={() =>
                                            handleAddAddress(
                                                CustomerFormTypesEnum.SHIPPING
                                            )
                                        }
                                    />
                                ) : (
                                    <>
                                        <CustomerSelectAddressRadio
                                            addressType={
                                                CustomerFormTypesEnum.SHIPPING
                                            }
                                            addressSelected="secondary"
                                            onClickHandler={() => {
                                                setSelectedShipping(
                                                    'secondary'
                                                );
                                            }}
                                        />
                                        <CustomerFields
                                            section={
                                                CustomerFormTypesEnum.NEWSHIPPING
                                            }
                                            customerInfo={
                                                customerShipping ??
                                                emptyCustomerAddress
                                            }
                                        />
                                    </>
                                )}
                            </RadioGroup>
                        </FormSection>

                        <FormSection>
                            <Typography component="h2" variant="subtitle2">
                                Adresse de facturation
                            </Typography>

                            <CustomerBISCheckbox
                                isSameAddress={isSameAddress}
                                onChangeHandler={() => handleBISChange()}
                            />

                            <RadioGroup
                                value={selectedBilling}
                                {...formMethods.register('billingform', {
                                    required: false,
                                })}
                            >
                                <CustomerSelectAddressRadio
                                    addressType={CustomerFormTypesEnum.BILLING}
                                    addressSelected="primary"
                                    BiS={isSameAddress}
                                    onClickHandler={() => {
                                        setSelectedBilling('primary');
                                    }}
                                />
                                <CustomerFields
                                    section={CustomerFormTypesEnum.BILLING}
                                    customerInfo={customerBilling}
                                    onChangeHandler={() =>
                                        handleBillingChange()
                                    }
                                    isSameAddress={isSameAddress}
                                />

                                {!isNewBilling ? (
                                    <CustomerAddAddressButton
                                        addressType={
                                            CustomerFormTypesEnum.BILLING
                                        }
                                        onClickHandler={() =>
                                            handleAddAddress(
                                                CustomerFormTypesEnum.BILLING
                                            )
                                        }
                                    />
                                ) : (
                                    <>
                                        <CustomerSelectAddressRadio
                                            addressType={
                                                CustomerFormTypesEnum.BILLING
                                            }
                                            addressSelected="secondary"
                                            BiS={isSameAddress}
                                            onClickHandler={() => {
                                                setSelectedBilling('secondary');
                                            }}
                                        />
                                        <CustomerFields
                                            section={
                                                CustomerFormTypesEnum.NEWBILLING
                                            }
                                            customerInfo={
                                                customerBilling ??
                                                emptyCustomerAddress
                                            }
                                            isSameAddress={isSameAddress}
                                        />
                                    </>
                                )}
                            </RadioGroup>
                        </FormSection>

                        {/* Section hidden for now - backend not ready
                        <FormSection>
                            <Typography component="h2" variant="subtitle2">
                                Abonnement
                            </Typography>

                            {isSubscribed !== undefined && (
                                <CustomerSubscriptionCheckbox
                                    subscribed={isSubscribed}
                                    handleSubscriptionChange={handleInfoChange}
                                />
                            )}
                        </FormSection>
                        */}
                    </LeftFormContainer>
                )}

                <RightFormContainer>
                    <CustomerFormButtons
                        disabled={
                            !(
                                infoHasChanged ||
                                billingHasChanged ||
                                shippingHasChanged ||
                                isNewBilling ||
                                isNewShipping ||
                                BISHasChanged
                            )
                        }
                    />
                </RightFormContainer>
            </FormContainer>
        </FormProvider>
    );
};

export default CustomerInfo;
