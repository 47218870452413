import React from 'react';

import { APIStockSummary } from '../../services/products.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

type Props = {
    stockInfo: Array<APIStockSummary> | null;
};

const Container = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    padding: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
}));
const StockContainer = styled(Box)(() => ({
    overflowY: 'scroll',
    scrollbarSize: '0',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
}));
const StockLine = styled(Typography)(() => ({
    textAlign: 'left',
    marginBottom: '2px',
}));
const ScrollIndicator = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));
const Arrow = styled(KeyboardArrowDown)(() => ({
    height: '10px',
    width: '10px',
    opacity: '0.3',
}));

const ProductStockInfo: React.FC<Props> = ({ stockInfo }) => {
    stockInfo?.sort((a, b) => b.qty - a.qty);
    return (
        <Container>
            <StockContainer>
                {stockInfo &&
                    stockInfo.map((item: APIStockSummary, index: number) => {
                        return (
                            <StockLine
                                key={index}
                                variant="body2"
                                sx={{
                                    color:
                                        item.qty > 0
                                            ? theme.palette.primary.main
                                            : theme.palette.error.main,
                                }}
                            >
                                {item.code} : {item.qty}
                            </StockLine>
                        );
                    })}
            </StockContainer>

            {stockInfo && stockInfo.length > 2 && (
                <ScrollIndicator>
                    <Arrow sx={{ transform: 'rotate(180deg)' }} />
                    <Arrow />
                </ScrollIndicator>
            )}
        </Container>
    );
};

export default ProductStockInfo;
