import React, { PropsWithChildren } from 'react';

import { UserContext } from '../../providers/UserProvider';
import { CUSTOMER_DROPDOWNCOLUMNS } from '../../queries/CUSTOMER_DROPDOWNCOLUMNS_QUERY';
import { fetchGridDropdownData } from '../../services/gridFilters.service';
import { useLocation } from 'react-router-dom';
import { CustomerGridLocation } from '../../services/customer.service';
import { useGridOptions } from '../../providers/GridOptionsProvider';

export const CustomerContext = React.createContext({
    customerColumnFilters: new Map(),
    customerDropdownColumns: new Map(),
    updateCustomerFilter: (column: string, value: string | number) => {
        return;
    },
    updateCustomerDropdownColumns: () => {
        return;
    },
});

export const CustomerProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = React.useContext(UserContext);
    const { state } = useLocation<CustomerGridLocation>();
    const { setGridPage } = useGridOptions();
    const [customerColumnFilters, setCustomerColumnFilters] = React.useState(
        new Map()
    );
    const [customerDropdownColumns, setCustomerDropdownColumns] =
        React.useState(new Map());

    React.useEffect(() => {
        if (!state?.filters?.customer) return;

        setCustomerColumnFilters(new Map(state.filters.customer));
    }, [state]);

    const updateCustomerFilter = (column: string, value: string | number) => {
        setGridPage(0);
        setCustomerColumnFilters(
            new Map(customerColumnFilters.set(column, value))
        );
    };

    const updateCustomerDropdownColumns = () => {
        fetchGridDropdownData(
            user.storeId,
            'customer',
            CUSTOMER_DROPDOWNCOLUMNS
        ).then((res) => {
            setCustomerDropdownColumns(new Map(res));
        });
    };

    return (
        <CustomerContext.Provider
            value={{
                customerColumnFilters,
                customerDropdownColumns,
                updateCustomerFilter,
                updateCustomerDropdownColumns,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
};
