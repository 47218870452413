import React from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
    price: number;
};

const FullPrice: React.FC<Props> = ({ price }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography>{price} €</Typography>
        </Box>
    );
};

export default FullPrice;
