import { DocumentNode } from 'graphql';
import apolloQuery from '../utils/apolloQuery';

export type APIFilterSubtotal = {
    from?: string;
    to?: string;
};
export type APIColumnValue = {
    id: string;
    label: string;
};
export type APIColumn = {
    type: string;
    label: string;
    sortable: boolean;
    filterable: boolean;
    priority: number;
    filter_code: string;
    values: Array<APIColumnValue>;
};
export type Grids = 'customer' | 'order' | 'product';
export type GridFilter = {
    column: string;
    value: string | number;
};
export type GridFilterArray = Array<GridFilter>;
export type GridFilterMap = Map<string, string | number>;
export type UpdateGridFilter = (column: string, value: string | number) => void;
export type GridFilters = {
    order?: GridFilterMap;
    product?: GridFilterMap;
    customer?: GridFilterMap;
};
export type GridFiltersLocation = {
    filters?: GridFilters;
};

export const fetchGridDropdownData = async (
    storeID: number,
    grid: Grids,
    query: DocumentNode
): Promise<Map<string, Array<APIColumnValue>>> => {
    const dropdownColumns: Map<string, Array<APIColumnValue>> = new Map();
    const queryOptions = {
        query: query,
        variables: {
            store_id: storeID,
        },
    };
    await apolloQuery(queryOptions)
        .then((res) => {
            let resData;
            switch (grid) {
                case 'customer':
                    resData = res?.data?.posCustomerGridColumns;
                    break;
                case 'order':
                    resData = res?.data?.posOrderGridColumns;
                    break;
                case 'product':
                    resData = res?.data?.posProductGridColumns;
                    break;
                default:
                    break;
            }
            if (!resData) {
                return;
            }

            resData.items.forEach((column: APIColumn) => {
                if (column.type !== 'DROPDOWN') {
                    return;
                }
                dropdownColumns.set(column.label, column.values);
            });
        })
        .catch((err) => {
            console.error('An API error has occured : ', err);
        });

    return dropdownColumns;
};

export const subtotalRange = (
    columnFilters: Map<string, string>
): APIFilterSubtotal => {
    const subtotalFrom = columnFilters.get('subtotalFrom');
    const subtotalTo = columnFilters.get('subtotalTo');

    const subtotalRange: any = {};
    if (subtotalFrom) {
        subtotalRange.from = subtotalFrom;
    }
    if (subtotalTo) {
        subtotalRange.to = subtotalTo;
    }
    return subtotalRange;
};
