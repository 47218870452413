import React from 'react';

import {
    AddressTypes,
    CustomerFormTypesEnum,
} from '../../services/customer.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup } from '@mui/material';

type Props = {
    selected: AddressTypes;
    handleSwitchAddress: (addressType: AddressTypes) => void;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: '25px',
}));
const StyledButton = styled(Button)(() => ({
    color: theme.palette.secondary.main,
}));

const AddressSwitch: React.FC<Props> = ({ selected, handleSwitchAddress }) => {
    return (
        <Container>
            <ButtonGroup disableElevation variant="outlined">
                <StyledButton
                    sx={{
                        background:
                            selected == CustomerFormTypesEnum.SHIPPING
                                ? theme.palette.secondary.main
                                : 'transparent',
                        color:
                            selected == CustomerFormTypesEnum.SHIPPING
                                ? theme.palette.secondary.contrastText
                                : '',
                        '&: focus': {
                            background:
                                selected == CustomerFormTypesEnum.SHIPPING
                                    ? theme.palette.secondary.main
                                    : 'transparent',
                            color:
                                selected == CustomerFormTypesEnum.SHIPPING
                                    ? theme.palette.secondary.contrastText
                                    : '',
                        },
                    }}
                    onClick={() => {
                        handleSwitchAddress(CustomerFormTypesEnum.SHIPPING);
                    }}
                >
                    LIVRAISON
                </StyledButton>

                <StyledButton
                    sx={{
                        background:
                            selected == CustomerFormTypesEnum.BILLING
                                ? theme.palette.secondary.main
                                : 'transparent',
                        color:
                            selected == CustomerFormTypesEnum.BILLING
                                ? theme.palette.secondary.contrastText
                                : '',
                        '&: focus': {
                            background:
                                selected == CustomerFormTypesEnum.BILLING
                                    ? theme.palette.secondary.main
                                    : 'transparent',
                            color:
                                selected == CustomerFormTypesEnum.BILLING
                                    ? theme.palette.secondary.contrastText
                                    : '',
                        },
                    }}
                    onClick={() => {
                        handleSwitchAddress(CustomerFormTypesEnum.BILLING);
                    }}
                >
                    FACTURATION
                </StyledButton>
            </ButtonGroup>
        </Container>
    );
};

export default AddressSwitch;
