import React from 'react';
import { Link } from 'react-router-dom';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Icon, Typography } from '@mui/material';

import CartIcon from '../../icons/cart.svg';
import CustomerIcon from '../../icons/customer.svg';
import ProductIcon from '../../icons/jeans.svg';
import OrderIcon from '../../icons/order.svg';
import CustomSvgIcon from '../CustomSvgIcon/CustomSvgIcon';
import { NavIconContainer } from './Navigation';

type Props = {
    collapsed: boolean;
};

const MenuList = styled('nav')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    marginTop: '55px',
    listStyle: 'none',
}));
const NavItem = styled(Link)(() => ({
    display: 'flex',
    flexDirection: 'row',
    margin: '13px 0 13px 0',
    paddingLeft: '6px',
    textDecoration: 'none',
    '&:link': { color: theme.palette.primary.contrastText },
    '&:visited': { color: theme.palette.primary.contrastText },
}));

const Menu: React.FC<Props> = ({ collapsed }) => {
    return (
        <MenuList>
            <NavItem to="/">
                <NavIconContainer>
                    <Icon>
                        <CustomSvgIcon
                            src={CartIcon}
                            height={24}
                            width={24}
                            alt="Caisse 1083"
                        />
                    </Icon>
                </NavIconContainer>
                <Typography variant="body1">
                    {!collapsed && 'Caisse'}
                </Typography>
            </NavItem>

            <NavItem to="/produits">
                <NavIconContainer>
                    <Icon>
                        <CustomSvgIcon
                            src={ProductIcon}
                            height="24px"
                            width="24px"
                            alt="Liste des produits"
                        />
                    </Icon>
                </NavIconContainer>
                {!collapsed && 'Produits'}
            </NavItem>

            <NavItem to="/clients">
                <NavIconContainer>
                    <Icon>
                        <CustomSvgIcon
                            src={CustomerIcon}
                            height={24}
                            width={24}
                            alt="Liste des clients"
                        />
                    </Icon>
                </NavIconContainer>
                {!collapsed && 'Clients'}
            </NavItem>

            <NavItem to="/commandes">
                <NavIconContainer>
                    <Icon>
                        <CustomSvgIcon
                            src={OrderIcon}
                            height={24}
                            width={24}
                            alt="Liste des commandes"
                        />
                    </Icon>
                </NavIconContainer>
                {!collapsed && 'Commandes'}
            </NavItem>
        </MenuList>
    );
};

export default Menu;
