import React from 'react';

import { CartContext } from '../../providers/CartProvider';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../themeOptions';
import { Close, WarningAmberOutlined } from '@mui/icons-material';

const Container = styled(Box)(() => ({
    height: '76px',
    width: '100%',
    position: 'fixed',
    top: 0,
    paddingLeft: '105px',
    paddingRight: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
}));
const MessageContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));
const TextContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    marginLeft: '11px',
}));
const WarningIcon = styled(WarningAmberOutlined)(() => ({
    height: '22px',
    width: '22px',
}));

const CloseIcon = styled(Close)(() => ({
    height: '22px',
    width: '22px',
}));

const CartErrorBanner: React.FC = () => {
    const { stockError, updateStockError } = React.useContext(CartContext);

    return (
        <Container sx={{ display: stockError ? 'flex' : 'none' }}>
            <MessageContainer>
                <WarningIcon />

                <TextContainer>
                    <Typography variant="button">
                        Commande en attente
                    </Typography>
                    <Typography>
                        Vous devrez vérifier manuellement la/les commande(s)
                        mise(s) en attente pour valider la commande.
                    </Typography>
                </TextContainer>
            </MessageContainer>
            <CloseIcon onClick={() => updateStockError(false)} />
        </Container>
    );
};

export default CartErrorBanner;
