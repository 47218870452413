import React from 'react';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../providers/UserProvider';
import { CartContext } from '../../providers/CartProvider';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Icon, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

import LogoutIcon from '../../icons/logout.svg';
import CustomSvgIcon from '../CustomSvgIcon/CustomSvgIcon';
import Menu from './Menu';

type Props = {
    navIsHidden: boolean;
    handleNavIsHiddenUpdate: (navIsHidden: boolean) => void;
};

const Sidebar = styled(Box)(() => ({
    height: '100%',
    minWidth: '100px',
    maxWidth: '300px',
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    paddingLeft: '26px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
}));
export const NavIconContainer = styled(Box)(() => ({
    height: '25px',
    width: '25px',
    marginRight: '32px',
}));
const LogoutContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    margin: '13px 0 13px 0',
    paddingLeft: '6px',
    '&:hover': { cursor: 'pointer' },
}));
const TopContainer = styled(Box)(() => ({
    marginTop: '30px',
}));
const BottomContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '30px',
}));

const Navigation: React.FC<Props> = ({
    navIsHidden,
    handleNavIsHiddenUpdate,
}) => {
    const location = useLocation();
    const { cartLogout } = React.useContext(CartContext);
    const [collapsed, setCollapsed] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!location?.pathname) {
            return;
        }

        if (location.pathname == '/recapitulatif') {
            handleNavIsHiddenUpdate(true);
        } else {
            handleNavIsHiddenUpdate(false);
        }
    }, [location, handleNavIsHiddenUpdate]);

    return (
        <>
            {' '}
            {!navIsHidden && (
                <Sidebar sx={{ width: collapsed ? '100px' : '100%' }}>
                    <TopContainer>
                        <NavIconContainer
                            sx={{
                                transform: collapsed ? 'rotate(180deg)' : '',
                                marginLeft: '6px',
                            }}
                            onClick={() => {
                                setCollapsed((prevState) => !prevState);
                            }}
                        >
                            <KeyboardArrowLeft />
                        </NavIconContainer>

                        <Menu collapsed={collapsed} />
                    </TopContainer>

                    <BottomContainer>
                        <LogoutContainer>
                            <NavIconContainer
                                onClick={() => {
                                    cartLogout();
                                }}
                            >
                                <Icon>
                                    <CustomSvgIcon
                                        src={LogoutIcon}
                                        height={24}
                                        width={24}
                                        alt="Déconnexion"
                                    />
                                </Icon>
                            </NavIconContainer>
                            {!collapsed && 'Déconnexion'}
                        </LogoutContainer>

                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {process.env.REACT_APP_VERSION}
                        </Typography>
                    </BottomContainer>
                </Sidebar>
            )}
        </>
    );
};

export default Navigation;
