import React from 'react';
import { CartGridContext } from './CartGridProvider';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

type Props = {
    rowID: string | number;
    discountRate?: number;
};

export const ItemDiscounts: React.FC<Props> = ({ rowID, discountRate }) => {
    const { rowsDiscount, updateRowsDiscount } =
        React.useContext(CartGridContext);
    const [value, setValue] = React.useState<string>('');

    const handleSelectItem = (e: SelectChangeEvent) => {
        updateRowsDiscount(rowID, e.target.value);
    };

    React.useEffect(() => {
        if (discountRate) {
            discountRate === 10
                ? setValue('defect10')
                : setValue('customDiscount');
            return;
        }
        if (!value) return;

        setValue(
            rowsDiscount.get(rowID) == undefined ? '' : rowsDiscount.get(rowID)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountRate, rowsDiscount]);

    return (
        <Select
            label="-choisir-"
            value={value}
            sx={{ height: '40px', width: '135px', minWidth: '135px' }}
            onChange={(e: SelectChangeEvent) => {
                handleSelectItem(e);
            }}
        >
            <MenuItem selected value="">
                {' '}
            </MenuItem>

            <MenuItem disabled={!!rowsDiscount.get(rowID)} value="defect10">
                Défaut 10%
            </MenuItem>

            <MenuItem
                disabled={!!rowsDiscount.get(rowID)}
                value="customDiscount"
            >
                Montant personnalisé
            </MenuItem>

            <MenuItem value="unapplyDiscounts">Annuler les remises</MenuItem>
        </Select>
    );
};

export default ItemDiscounts;
