import React from 'react';

import { UserContext } from '../../providers/UserProvider';
import { CartContext } from '../../providers/CartProvider';
import {
    APIShippingMethodsInput,
    DeliveryTypes,
    findDefaultCarrier,
    findDefaultDeliveryType,
    findLocationIDByStoreID,
    findMethodsByDeliveryType,
    findUniqueDeliveryTypes,
    ShippingMethods,
} from '../../services/delivery.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

import TypeSelector from './TypeSelector';
import HomeDelivery from './HomeDelivery';
import WithdrawalDelivery from './WithdrawalDelivery';

const Container = styled(Paper)(() => ({
    width: '100%',
    padding: '18px 23px',
    marginTop: '14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    background: '#fcfcfc',
}));
const Header = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fcfcfc',
}));
const SectionTitleContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));
const SectionTitle = styled(Typography)(() => ({
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
}));
const ContentContainer = styled(Box)(() => ({
    width: '100%',
    marginTop: '35px',
    marginBottom: '22px',
    paddingLeft: '76px',
    paddingRight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
}));

const CartDeliveryArea: React.FC = () => {
    const { user } = React.useContext(UserContext);
    const { activeCart, carts, setDeliveryMethod } =
        React.useContext(CartContext);
    const [uniqueDeliveryTypes, setUniqueDeliveryTypes] = React.useState<
        Array<DeliveryTypes>
    >([]);
    const [deliveryType, setDeliveryType] =
        React.useState<DeliveryTypes>('none');
    const [deliveryCarrier, setDeliveryCarrier] =
        React.useState<APIShippingMethodsInput>();
    const [homeMethods, setHomeMethods] = React.useState<ShippingMethods>([]);
    const [withdrawalMethods, setWithdrawalMethods] =
        React.useState<ShippingMethods>([]);
    const [hideNonDefaultDeliveryPoints, setHideNonDefaultDeliveryPoints] =
        React.useState<boolean>(false);

    const initDeliveryCarrier = (): boolean => {
        const currentCart = carts.get(activeCart);
        if (!currentCart || !currentCart?.shipping_addresses?.length) {
            return false;
        }
        if (currentCart?.shipping_addresses[0]?.selected_shipping_method) {
            const selectedShippingAddress =
                currentCart.shipping_addresses[0].selected_shipping_method;

            if (
                deliveryCarrier &&
                selectedShippingAddress.carrier_code !==
                    deliveryCarrier.carrier_code
            ) {
                return false;
            }

            setDeliveryCarrier({
                carrier_code: selectedShippingAddress.carrier_code,
                method_code: selectedShippingAddress.method_code,
                delivery_point_id: selectedShippingAddress.delivery_point?.id,
            });
            return true;
        }

        handleSectionSelection('none');
        return false;
    };
    const handleSectionSelection = (deliveryType: DeliveryTypes) => {
        const currentCart = carts.get(activeCart);
        setDeliveryType(deliveryType);

        const availableMethods =
            carts.get(activeCart)?.shipping_addresses[0]
                ?.available_shipping_methods;
        if (!availableMethods) {
            return;
        }

        setHideNonDefaultDeliveryPoints(
            findDefaultDeliveryType(currentCart?.items) === 'TO_DELIVERY_POINT'
        );

        switch (deliveryType) {
            case 'none':
                break;
            case 'TO_CUSTOMER_ADDRESS':
                setHomeMethods(
                    findMethodsByDeliveryType(availableMethods, deliveryType)
                );
                break;
            case 'TO_DELIVERY_POINT': {
                setWithdrawalMethods(
                    findMethodsByDeliveryType(availableMethods, deliveryType)
                );
                break;
            }
        }
    };
    const handleCarrierSelection = (methodCodes: APIShippingMethodsInput) => {
        setDeliveryCarrier(methodCodes);
    };

    React.useEffect(() => {
        const currentCart = carts.get(activeCart);
        if (
            !currentCart ||
            !currentCart?.shipping_addresses[0]?.available_shipping_methods
        ) {
            handleSectionSelection('none');
            setUniqueDeliveryTypes([]);
            return;
        }

        const availableMethods =
            currentCart.shipping_addresses[0].available_shipping_methods;
        setUniqueDeliveryTypes(findUniqueDeliveryTypes(availableMethods));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts, activeCart]);

    React.useEffect(() => {
        const currentCart = carts.get(activeCart);
        if (
            !currentCart ||
            !currentCart?.shipping_addresses[0]?.selected_shipping_method
        ) {
            return;
        }
        const selectedShippingAddress =
            currentCart.shipping_addresses[0].selected_shipping_method;
        handleSectionSelection(selectedShippingAddress.shipping_method_type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueDeliveryTypes]);

    React.useEffect(() => {
        if (!homeMethods.length) {
            return;
        }
        initDeliveryCarrier();

        setDeliveryCarrier(
            findDefaultCarrier(homeMethods, 'TO_CUSTOMER_ADDRESS')
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeMethods]);

    React.useEffect(() => {
        if (!withdrawalMethods.length) return;
        initDeliveryCarrier();

        const defaultCarrier = findDefaultCarrier(
            withdrawalMethods,
            'TO_DELIVERY_POINT'
        );
        const shippingAddress = carts.get(activeCart)?.shipping_addresses[0];
        if (defaultCarrier && shippingAddress) {
            if (shippingAddress?.selected_shipping_method?.delivery_point?.id) {
                const currentID =
                    shippingAddress.selected_shipping_method.delivery_point.id;
                defaultCarrier.delivery_point_id = currentID;
            } else {
                const locationID = findLocationIDByStoreID(
                    shippingAddress.available_shipping_methods,
                    user.storeId
                );
                defaultCarrier.delivery_point_id = locationID;
            }
        }
        setDeliveryCarrier(defaultCarrier);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withdrawalMethods]);

    React.useEffect(() => {
        if (!carts.get(activeCart) || !deliveryCarrier) return;

        const selectedShippingAddress =
            carts.get(activeCart)?.shipping_addresses[0]
                ?.selected_shipping_method;
        const isSameCarrierCode =
            selectedShippingAddress?.carrier_code ==
            deliveryCarrier?.carrier_code;
        const isSameDeliveryPoint =
            selectedShippingAddress?.delivery_point?.id ==
            deliveryCarrier?.delivery_point_id;
        const hasDeliveryPoint =
            selectedShippingAddress?.delivery_point?.id &&
            deliveryCarrier?.delivery_point_id;

        if (selectedShippingAddress && isSameCarrierCode) {
            if (!hasDeliveryPoint || isSameDeliveryPoint) return;
        }

        setDeliveryMethod(activeCart, deliveryCarrier);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryCarrier]);

    return (
        <Container>
            <Header>
                <SectionTitleContainer>
                    <SectionTitle>LIVRAISON</SectionTitle>
                </SectionTitleContainer>

                <TypeSelector
                    uniqueDeliveryTypes={uniqueDeliveryTypes}
                    deliveryType={deliveryType}
                    handleSectionSelection={handleSectionSelection}
                />
            </Header>

            {{
                none: '',

                TO_DELIVERY_POINT: (
                    <ContentContainer>
                        {!!(deliveryCarrier && withdrawalMethods?.length) && (
                            <WithdrawalDelivery
                                deliveryCarrier={deliveryCarrier}
                                withdrawalMethods={withdrawalMethods}
                                hideNonDefaultDeliveryPoints={
                                    hideNonDefaultDeliveryPoints
                                }
                                handleCarrierSelection={handleCarrierSelection}
                            />
                        )}
                    </ContentContainer>
                ),

                TO_CUSTOMER_ADDRESS: (
                    <ContentContainer>
                        <HomeDelivery
                            deliveryCarrier={deliveryCarrier}
                            homeMethods={homeMethods}
                            handleCarrierSelection={handleCarrierSelection}
                        />
                    </ContentContainer>
                ),
            }[deliveryType] || ''}
        </Container>
    );
};

export default CartDeliveryArea;
