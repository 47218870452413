import React from 'react';

import currency from '../../utils/currency';
import {
    FinalPaymentMethodsMap,
    MapPaymentMethod,
    PaymentMethods,
} from '../../services/payment.service';

import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';

type Props = {
    paymentOptions: FinalPaymentMethodsMap;
    dueRemaining: number;
    paidTotal: number;
    handleDeletePaidItem: (item: MapPaymentMethod) => void;
};

const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '36px',
}));
const SummaryContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 26px',
    background: '#f9f9f9',
}));
const SubtotalContainer = styled(Box)(() => ({
    paddingRight: '26px',
}));
const PaidList = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const PaidLine = styled(Box)(() => ({
    height: '41px',
    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: '1fr 5fr 1fr',
    alignItems: 'center',
    margin: '5px 0',
    padding: '0 15px',
    background: 'rgba(47, 43, 153, 0.08)',
    borderRadius: '60px',
}));
const Line = styled(Box)(() => ({
    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: '6fr 1fr',
    marginTop: '15px',
    paddingRight: '15px',
}));
const LineText = styled(Typography)(() => ({
    textAlign: 'right',
}));
const ClearIcon = styled(Clear)(() => ({
    '&:hover': {
        cursor: 'pointer',
    },
}));

const TotalsSummary: React.FC<Props> = ({
    paymentOptions,
    dueRemaining,
    paidTotal,
    handleDeletePaidItem,
}) => {
    const [paidItems, setPaidItems] = React.useState<PaymentMethods>();

    React.useEffect(() => {
        if (!paymentOptions) {
            return;
        }
        const newItems: PaymentMethods = [];

        Array.from(paymentOptions).forEach((option) => {
            const paymentOption = option[1];
            if (!paymentOption.used || !paymentOption.amount) {
                return;
            }

            newItems.push(paymentOption);
        });

        setPaidItems(newItems);
    }, [paymentOptions]);

    return (
        <Container>
            <SummaryContainer>
                <PaidList>
                    {paidItems
                        ? paidItems.map((item) => {
                              return (
                                  <PaidLine key={item.code}>
                                      <ClearIcon
                                          onClick={() => {
                                              handleDeletePaidItem(item);
                                          }}
                                      />

                                      <LineText>
                                          Payé par {item.label} :
                                      </LineText>

                                      <LineText>
                                          {item.amount
                                              ? currency(item.amount)
                                              : ''}
                                          €
                                      </LineText>
                                  </PaidLine>
                              );
                          })
                        : ''}
                </PaidList>

                <Line>
                    <LineText>Restant à payer TTC : </LineText>
                    <LineText>{currency(dueRemaining)}€</LineText>
                </Line>
            </SummaryContainer>

            <SubtotalContainer>
                <Line>
                    <LineText>Total Payé TTC :</LineText>
                    <LineText>{currency(paidTotal)}€</LineText>
                </Line>
            </SubtotalContainer>
        </Container>
    );
};

export default TotalsSummary;
