import { gql } from '@apollo/client';

export const CART_SETDELIVERYMETHOD = gql`
    mutation posSetShippingMethodsOnCart(
        $store_id: Int!
        $cart_id: String!
        $carrier_code: String!
        $method_code: String!
        $delivery_point_id: Int
    ) {
        posSetShippingMethodsOnCart(
            store_id: $store_id
            input: {
                cart_id: $cart_id
                shipping_methods: {
                    carrier_code: $carrier_code
                    method_code: $method_code
                    delivery_point_id: $delivery_point_id
                }
            }
        ) {
            cart {
                id
            }
        }
    }
`;
