import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Button, Paper, Typography } from '@mui/material';
import { theme } from '../../themeOptions';

type Props = {
    handleValidate: () => void;
    handleCancel: () => void;
};

const Container = styled(Box)(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2000,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(2px)',
}));
const ModalContainer = styled(Paper)(() => ({
    position: 'absolute',
    zIndex: 2010,
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -10%)',
    minWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '25px 30px',
}));
const ButtonsContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
}));
const CancelButton = styled(Button)(() => ({
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
    marginRight: '30px',
}));
const ValidateButton = styled(Button)(() => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const DiscountModal: React.FC<Props> = ({ handleValidate, handleCancel }) => {
    React.useEffect(() => {
        const dataGridScroller = document.getElementsByClassName(
            'MuiDataGrid-virtualScroller'
        );
        dataGridScroller[0].scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container>
            <ModalContainer>
                <Typography>
                    Toutes les remises seront retirées du produit,
                    souhaitez-vous valider cette action ?
                </Typography>
                <ButtonsContainer>
                    <CancelButton onClick={() => handleCancel()}>
                        Non
                    </CancelButton>
                    <ValidateButton onClick={() => handleValidate()}>
                        Oui
                    </ValidateButton>
                </ButtonsContainer>
            </ModalContainer>
        </Container>
    );
};

export default DiscountModal;
