import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { UserContext } from '../../providers/UserProvider';
import { CartContext } from '../../providers/CartProvider';
import { RecapTypes } from '../../services/cart.service';
import {
    APICustomerAddress,
    CustomerRecap,
    findLocalCustomerData,
} from '../../services/customer.service';
import {
    fetchInvoicesList,
    fetchOrder,
    formatOrderProducts,
    OrderGridLocation,
    OrderRecap,
} from '../../services/orders.service';
import { APIProductItem } from '../../services/products.service';
import { APICompositePayment } from '../../services/payment.service';
import {
    APIDiscount,
    CardOrCoupons,
    CardsAndCoupons,
} from '../../services/giftcard.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';

import RecapHeader from '../../components/RecapHeader/RecapHeader';
import RecapProducts from '../../components/RecapProducts/RecapProducts';
import RecapPayments from '../../components/RecapPayments/RecapPayments';
import { EventEmitter, Events } from '../../utils/events';

export type RecapState = {
    type: RecapTypes;
    id: string;
    rma_url?: string;
    isFromCart?: boolean;
};

const PageContainer = styled(Box)(() => ({
    width: '100%',
    padding: '0 25px 25px 25px',
    '@media print': {
        padding: '0 25px 25px 0',
    },
}));
const Container = styled(Paper)(() => ({
    width: '100%',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media print': {
        boxShadow: 'none',
    },
}));
const RecapGrid = styled(Grid)(() => ({
    width: '100%',
    display: 'grid',
    gap: '100px 16px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr, 1fr',
}));
const GridItem = styled(Box)(() => ({
    width: '100%',
    padding: '19px 0 25px 22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
}));
const Title = styled(Typography)(() => ({
    marginBottom: '9px',
}));
const Info = styled(Typography)(() => ({
    fontWeight: 'lighter',
    marginBottom: '3px',
}));
const PaidBadge = styled(Box)(() => ({
    height: '8px',
    width: '8px',
    margin: '0 3px 0 6px',
    backgroundColor: theme.palette.success.main,
    borderRadius: '100%',
}));

const Recap: React.FC = () => {
    const history = useHistory();
    const { state } = useLocation<OrderGridLocation>();
    const { user } = React.useContext(UserContext);
    const { carts, placeOrder, removeCart, selectCart } =
        React.useContext(CartContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPlacingOrder, setIsPlacingOrder] = React.useState<boolean>(false);
    const [recapState, setRecapState] = React.useState<RecapState>();
    const [customer, setCustomer] = React.useState<CustomerRecap | null>();
    const [order, setOrder] = React.useState<OrderRecap | null>();
    const [orderIDs, setOrderIDs] = React.useState<Array<string>>([]);
    const [orderPage, setOrderPage] = React.useState<number>(0);
    const [invoices, setInvoices] = React.useState<Array<string>>([]);
    const [shipping, setShipping] = React.useState<APICustomerAddress | null>();
    const [billing, setBilling] = React.useState<APICustomerAddress | null>();
    const [products, setProducts] = React.useState<Array<APIProductItem>>();
    const [compositePayment, setCompositePayment] =
        React.useState<APICompositePayment>();
    const [cardsAndCoupons, setCardsAndCoupons] =
        React.useState<CardOrCoupons>();
    const [rma_url, setRmaUrl] = React.useState<string>();

    const recapRef = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => recapRef.current,
    });
    const handlePlaceOrder = (cartID: string) => {
        setIsPlacingOrder(true);
        placeOrder(cartID).then((orderIDsData) => {
            if (!orderIDsData?.length) {
                EventEmitter.emit(Events.GRAPHQL_ERROR, {
                    id: crypto.randomUUID(),
                    title: "La commande n'a pas pu être validée",
                    description:
                        'Veuillez consulter les erreurs afin de corriger votre panier, ou transmettre cette erreur au développeur',
                });
                setIsPlacingOrder(false);
                return;
            }

            setIsPlacingOrder(false);
            setOrderIDs(orderIDsData);
            removeCart(cartID);
            selectCart();
        });
    };
    const handleNextOrder = React.useCallback(
        (index: number | null) => {
            const maxIndex = orderIDs.length - 1;
            let nextIndex = 0;

            if (index == null)
                nextIndex = orderPage + 1 > maxIndex ? 0 : orderPage + 1;

            setOrderPage(nextIndex);
            setRecapState({
                type: 'order',
                id: orderIDs[nextIndex],
                rma_url: rma_url,
                isFromCart: true,
            });
        },
        [orderIDs, orderPage, rma_url]
    );

    React.useEffect(() => {
        if (!orderIDs?.length) return;

        handleNextOrder(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderIDs]);

    React.useEffect(() => {
        if (!state || !state?.type || !state?.id) return;

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setRecapState({
            type: state.type,
            id: state.id,
            rma_url: rma_url,
        });

        if (state?.filters?.order) {
            history.push('/recapitulatif', state);
            return;
        }
        history.push('/recapitulatif', {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts, state, rma_url]);

    React.useEffect(() => {
        if (!recapState) return;
        setIsLoading(true);
        switch (recapState.type) {
            case 'cart': {
                const currentCart = carts?.get(recapState.id);
                const shippingMethod =
                    currentCart?.shipping_addresses[0]
                        ?.selected_shipping_method;
                const shippingAddress: APICustomerAddress =
                    currentCart?.shipping_addresses[0];
                if (
                    shippingMethod?.shipping_method_type == 'TO_DELIVERY_POINT'
                ) {
                    const deliveryPoint = shippingMethod.delivery_point;
                    shippingAddress.firstname = shippingMethod.method_title;
                    shippingAddress.street[0] = deliveryPoint?.address;
                    shippingAddress.city = deliveryPoint?.city;
                    shippingAddress.country.label = deliveryPoint?.country;
                }

                const giftCards =
                    currentCart?.applied_gift_cards?.gift_cards ?? [];
                const coupons = currentCart?.applied_coupons ?? [];
                const codes: CardsAndCoupons = [...giftCards, ...coupons];

                setCustomer(findLocalCustomerData(currentCart));
                setShipping(shippingAddress);
                setBilling(currentCart?.billing_address);
                setProducts(currentCart?.items);
                setCompositePayment(currentCart?.composite_payment);
                setCardsAndCoupons(codes);
                setIsLoading(false);
                return;
            }
            case 'order': {
                fetchInvoicesList(recapState.id).then((invoicesData) => {
                    setInvoices(invoicesData);
                });

                fetchOrder(recapState.id).then((response) => {
                    const orderData = response.data.posOrder;
                    const customer: CustomerRecap = orderData?.customer ?? {
                        id: '',
                        lastname: orderData?.lastname,
                        firstname: orderData?.firstname,
                        email: orderData?.email,
                        telephone: orderData?.telephone,
                        company: orderData?.company,
                        created_at: '',
                    };
                    const giftCards = orderData?.applied_gift_cards?.gift_cards;
                    const coupons = orderData?.total?.discounts;
                    const codes: CardsAndCoupons = giftCards
                        ? [...giftCards]
                        : [];
                    coupons?.length &&
                        coupons.forEach((couponCode: APIDiscount) => {
                            codes.push({
                                code: couponCode.label,
                                applied_amount: couponCode.amount,
                                current_amount: couponCode.amount,
                                initial_amount: couponCode.amount,
                                expiration_date: '',
                            });
                        });

                    setCustomer(customer);
                    setShipping(orderData?.shipping_address);
                    setBilling(orderData?.billing_address);
                    setCompositePayment(orderData?.composite_payment);
                    setCardsAndCoupons(codes);
                    setOrder({
                        number: orderData?.number,
                        date: orderData?.order_date,
                        store: orderData?.store.label,
                    });
                    setRmaUrl(orderData?.rma_url);

                    if (!orderData?.items) return;

                    setProducts(
                        formatOrderProducts(
                            orderData.items,
                            orderData?.order_origin
                        )
                    );
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    setIsLoading(false);
                });
                return;
            }
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recapState]);

    return (
        <>
            {!!recapState && (
                <RecapHeader
                    recapState={recapState}
                    invoices={invoices}
                    handlePrint={handlePrint}
                    handlePlaceOrder={handlePlaceOrder}
                    isPlacingOrder={isPlacingOrder}
                    handleNextOrder={
                        orderIDs?.length > 1 ? handleNextOrder : undefined
                    }
                />
            )}
            {isLoading ? (
                <CircularProgress sx={{ marginTop: '10px' }} />
            ) : (
                <PageContainer id="recap">
                    <Container ref={recapRef} elevation={6}>
                        <RecapGrid>
                            <GridItem>
                                <Title variant="h3">Informations client</Title>
                                {customer && (
                                    <>
                                        <Info variant="body2">
                                            {customer.id
                                                ? 'N°' + customer.id.toString()
                                                : 'Nouveau client'}
                                        </Info>
                                        <Info variant="body2">
                                            {' '}
                                            {`${customer.lastname || ''} ${
                                                customer.firstname || ''
                                            }`}
                                        </Info>
                                        <Info variant="body2">
                                            {' '}
                                            {customer.email}
                                        </Info>
                                        <Info variant="body2">
                                            {' '}
                                            {customer.telephone}
                                        </Info>
                                        <Info variant="body2">
                                            {customer.company
                                                ? customer.company
                                                : 'Particulier'}
                                        </Info>
                                        <Info variant="body2">
                                            {customer.created_at
                                                ? 'Client depuis le ' +
                                                  customer.created_at
                                                : ''}
                                        </Info>
                                    </>
                                )}
                            </GridItem>

                            <GridItem>
                                <Title variant="h3">
                                    Informations commande
                                </Title>
                                <Info variant="body2">
                                    {' '}
                                    Référence :{' '}
                                    {order?.number ? order.number : 'n/r'}
                                </Info>
                                <Info variant="body2">
                                    {' '}
                                    Date : {order?.date ? order.date : 'n/r'}
                                </Info>
                                <Info variant="body2">
                                    {' '}
                                    Acheté :{' '}
                                    {order?.store
                                        ? order.store
                                        : user.storeCode}
                                </Info>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Info variant="body2"> Statut :</Info>
                                    <PaidBadge />
                                    <Info variant="body2">Payé</Info>
                                </Box>
                                {compositePayment && (
                                    <RecapPayments
                                        payments={compositePayment[0]?.payments}
                                        paidTotal={compositePayment[0]?.total}
                                        cardsAndCoupons={cardsAndCoupons}
                                    />
                                )}
                            </GridItem>

                            <GridItem>
                                <Title variant="h3">Adresse de livraison</Title>
                                {shipping && (
                                    <>
                                        <Info variant="body2">
                                            {shipping.lastname +
                                                ' ' +
                                                shipping.firstname}
                                        </Info>
                                        <Info variant="body2">
                                            {shipping.street[0]}
                                        </Info>
                                        <Info variant="body2">
                                            {shipping.street[1]}
                                        </Info>
                                        <Info variant="body2">
                                            {shipping.postcode +
                                                ' ' +
                                                shipping.city}
                                        </Info>
                                        <Info variant="body2">
                                            {shipping.country.label}
                                        </Info>
                                        <Info variant="body2">
                                            {shipping.telephone}
                                        </Info>
                                        <Info variant="body2">
                                            {shipping.company}
                                        </Info>
                                    </>
                                )}
                            </GridItem>

                            <GridItem>
                                <Title variant="h3">
                                    Adresse de facturation
                                </Title>
                                {billing && (
                                    <>
                                        <Info variant="body2">
                                            {billing.lastname +
                                                ' ' +
                                                billing.firstname}
                                        </Info>
                                        <Info variant="body2">
                                            {billing.street[0]}
                                        </Info>
                                        <Info variant="body2">
                                            {billing.street[1]}
                                        </Info>
                                        <Info variant="body2">
                                            {billing.postcode +
                                                ' ' +
                                                billing.city}
                                        </Info>
                                        <Info variant="body2">
                                            {billing.country.label}
                                        </Info>
                                        <Info variant="body2">
                                            {billing.telephone}
                                        </Info>
                                        <Info variant="body2">
                                            {billing.company}
                                        </Info>
                                    </>
                                )}
                            </GridItem>
                        </RecapGrid>

                        <GridItem sx={{ marginTop: '46px' }}>
                            <Title variant="h3">
                                Récapitulatif de la commande
                            </Title>
                            {products && <RecapProducts products={products} />}
                        </GridItem>
                    </Container>
                </PageContainer>
            )}
        </>
    );
};

export default Recap;
