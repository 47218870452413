import React from 'react';
import { frFR } from '@mui/x-data-grid';
import apolloQuery from '../../utils/apolloQuery';
import { UserContext } from '../../providers/UserProvider';
import { CustomerContext } from './CustomerProvider';
import { CUSTOMER_SEARCH } from '../../queries/CUSTOMER_SEARCH_QUERY';
import { customerColumns, pinnedCustomerColumns } from './customerColumns';
import { formatCustomerRows } from '../../services/customer.service';

import { Box } from '@mui/material';
import { DataGridPro, GridRowsProp } from '@mui/x-data-grid-pro';
import { useGridOptions } from '../../providers/GridOptionsProvider';
import { useHistory } from 'react-router-dom';

export const CustomerGrid: React.FunctionComponent = () => {
    const { user } = React.useContext(UserContext);
    const { customerColumnFilters, updateCustomerDropdownColumns } =
        React.useContext(CustomerContext);
    const {
        gridOption,
        setGridPage,
        setGridRowsPerPage,
        totalRows,
        setTotalRows,
        reset,
    } = useGridOptions();
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { location } = useHistory();
    React.useEffect(() => {
        if (!location.state || !(location.state as any).gridKeepPagination) {
            return reset();
        }
    }, [location, reset]);
    React.useEffect(() => {
        // Magento pages start at 1, but MUI DataGrid starts at 0
        const currentPageMagento = gridOption.page + 1;

        const queryOptions = {
            query: CUSTOMER_SEARCH,
            variables: {
                store_id: user.storeId,
                filter: {
                    email: {},
                    firstname: {},
                    lastname: {},
                    created_at: { from: undefined, to: undefined },
                    company: {},
                    telephone: {},
                    billing_postcode: {},
                    group_id: {},
                    billing_country_id: {},
                },
                pageSize: gridOption.rowsPerPage,
                currentPage: currentPageMagento,
            },
        };
        if (customerColumnFilters.get('email'))
            queryOptions.variables.filter.email = {
                contains: customerColumnFilters.get('email'),
            };
        if (customerColumnFilters.get('firstname'))
            queryOptions.variables.filter.firstname = {
                contains: customerColumnFilters.get('firstname'),
            };
        if (customerColumnFilters.get('lastname'))
            queryOptions.variables.filter.lastname = {
                contains: customerColumnFilters.get('lastname'),
            };
        if (customerColumnFilters.get('dateFrom'))
            queryOptions.variables.filter.created_at.from =
                customerColumnFilters.get('dateFrom');
        if (customerColumnFilters.get('dateTo'))
            queryOptions.variables.filter.created_at.to =
                customerColumnFilters.get('dateTo');
        if (customerColumnFilters.get('company'))
            queryOptions.variables.filter.company = {
                contains: customerColumnFilters.get('company'),
            };
        if (customerColumnFilters.get('telephone'))
            queryOptions.variables.filter.telephone = {
                contains: customerColumnFilters.get('telephone'),
            };
        if (customerColumnFilters.get('postcode'))
            queryOptions.variables.filter.billing_postcode = {
                contains: customerColumnFilters.get('postcode'),
            };
        if (customerColumnFilters.get('Groupe client'))
            queryOptions.variables.filter.group_id = {
                eq: customerColumnFilters.get('Groupe client'),
            };
        if (customerColumnFilters.get('Pays'))
            queryOptions.variables.filter.billing_country_id = {
                eq: customerColumnFilters.get('Pays'),
            };

        const delay = setTimeout(async () => {
            setLoading(true);
            const response = await apolloQuery(queryOptions);

            if (!response?.data?.posCustomerList?.items) {
                return;
            }

            setRows(formatCustomerRows(response));
            setTotalRows(
                response.data.posCustomerList.page_info.total_pages *
                    gridOption.rowsPerPage
            );
            setLoading(false);
        }, 500);

        return () => {
            clearTimeout(delay);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridOption, customerColumnFilters, user.storeId]);

    React.useEffect(() => {
        updateCustomerDropdownColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <Box>
            <DataGridPro
                autoHeight
                disableColumnMenu
                disableSelectionOnClick
                disableColumnReorder
                headerHeight={100}
                rows={rows}
                columns={customerColumns}
                initialState={{ pinnedColumns: pinnedCustomerColumns }}
                pagination
                paginationMode="server"
                rowCount={totalRows}
                rowsPerPageOptions={[7, 15, 25]}
                pageSize={gridOption.rowsPerPage}
                page={gridOption.page}
                onPageSizeChange={(newPageSize) =>
                    setGridRowsPerPage(newPageSize)
                }
                onPageChange={(newPage) => {
                    setGridPage(newPage);
                }}
                loading={loading}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>
    );
};

export default CustomerGrid;
