import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

import { styled } from '@mui/material/styles';
import { theme } from './themeOptions';

import Navigation from './components/Navigation/Navigation';
import Login from './pages/Login/Login';
import Cart from './pages/Cart/Cart';
import Products from './pages/Products/Products';
import Customers from './pages/Customers/Customers';
import CustomerInfo from './pages/CustomerInfo/CustomerInfo';
import Orders from './pages/Orders/Orders';
import Recap from './pages/Recap/Recap';
import ErrorFeed from './components/ErrorFeed/ErrorFeed';

import { UserContext } from './providers/UserProvider';
import { CartProvider } from './providers/CartProvider';
import { ErrorProvider } from './providers/ErrorProvider';
import { GridOptionsProvider } from './providers/GridOptionsProvider';

const PreAppWrapper = styled('div')(() => ({
    background: '#f9f9f9',
    textAlign: 'center',
    minHeight: '100vh',
}));
const AppWrapper = styled('div')(() => ({
    padding: '45px 45px 45px 110px',
}));

const App: React.FunctionComponent = () => {
    const { isLoggedIn, login } = React.useContext(UserContext);
    const [navIsHidden, setNavIsHidden] = React.useState<boolean>(false);

    const handleNavIsHiddenUpdate = (navIsHidden: boolean) => {
        setNavIsHidden(navIsHidden);
    };

    const userSession = {
        token: sessionStorage.getItem('token'),
        user: sessionStorage.getItem('user'),
        storeId: sessionStorage.getItem('storeID'),
        storeCode: sessionStorage.getItem('storeCode'),
        locationId: sessionStorage.getItem('locationID'),
    };

    React.useEffect(() => {
        if (
            isLoggedIn(
                userSession.token,
                userSession.user,
                userSession.storeId
            ) &&
            userSession.user &&
            userSession.storeId &&
            userSession.storeCode &&
            userSession.locationId
        ) {
            login(
                userSession.user,
                parseInt(userSession.storeId),
                userSession.storeCode,
                parseInt(userSession.locationId)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ErrorProvider>
                <GridOptionsProvider>
                    <CartProvider>
                        <ThemeProvider theme={theme}>
                            <ErrorFeed />
                            <CssBaseline />
                            <PreAppWrapper>
                                {isLoggedIn(
                                    userSession.token,
                                    userSession.user,
                                    userSession.storeId
                                ) ? (
                                    ''
                                ) : (
                                    <Login />
                                )}
                                {isLoggedIn(
                                    userSession.token,
                                    userSession.user,
                                    userSession.storeId
                                ) && (
                                    <AppWrapper
                                        sx={{
                                            padding: navIsHidden
                                                ? 0
                                                : '45px 45px 45px 110px',
                                        }}
                                    >
                                        <Router>
                                            <Navigation
                                                navIsHidden={navIsHidden}
                                                handleNavIsHiddenUpdate={
                                                    handleNavIsHiddenUpdate
                                                }
                                            />

                                            <Switch>
                                                <Route path="/produits">
                                                    <Products />
                                                </Route>
                                                <Route path="/clients">
                                                    <Customers />
                                                </Route>
                                                <Route path="/fiche-client">
                                                    <CustomerInfo />
                                                </Route>
                                                <Route path="/commandes">
                                                    <Orders />
                                                </Route>
                                                <Route path="/recapitulatif">
                                                    <Recap />
                                                </Route>
                                                <Route path="/">
                                                    <Cart />
                                                </Route>
                                            </Switch>
                                        </Router>
                                    </AppWrapper>
                                )}
                            </PreAppWrapper>
                        </ThemeProvider>
                    </CartProvider>
                </GridOptionsProvider>
            </ErrorProvider>
        </LocalizationProvider>
    );
};

export default App;
