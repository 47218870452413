import { gql } from '@apollo/client';

export const CUSTOMER_SEARCH = gql`
    query posCustomerList(
        $store_id: Int!
        $pageSize: Int!
        $currentPage: Int!
        $filter: CustomerAttributeFilterInput
    ) {
        posCustomerList(
            store_id: $store_id
            filter: $filter
            pageSize: $pageSize
            currentPage: $currentPage
        ) {
            items {
                id
                email
                firstname
                lastname
                telephone
                company
                group {
                    id
                    label
                }
                created_at
                default_billing
                addresses {
                    id
                    company
                    postcode
                    country {
                        id
                        label
                    }
                    telephone
                }
                is_subscribed
            }
            page_info {
                total_pages
            }
            total_count
        }
    }
`;

export const CUSTOMER_SEARCH_SIMPLE = gql`
    query posCustomerList(
        $store_id: Int!
        $pageSize: Int!
        $currentPage: Int!
        $filter: CustomerAttributeFilterInput
    ) {
        posCustomerList(
            store_id: $store_id
            filter: $filter
            pageSize: $pageSize
            currentPage: $currentPage
        ) {
            items {
                id
                email
            }
        }
    }
`;
