import { gql } from '@apollo/client';

export const ADDRESS_CREATE = gql`
    mutation posCreateCustomerAddress(
        $customer_id: Int!
        $lastname: String
        $firstname: String
        $telephone: String
        $city: String
        $company: String
        $country_code: CountryCodeEnum
        $street: [String]
        $postcode: String
        $default_shipping: Boolean
        $default_billing: Boolean
    ) {
        posCreateCustomerAddress(
            customer_id: $customer_id
            input: {
                lastname: $lastname
                firstname: $firstname
                telephone: $telephone
                city: $city
                company: $company
                country_code: $country_code
                street: $street
                postcode: $postcode
                default_shipping: $default_shipping
                default_billing: $default_billing
            }
        ) {
            default_billing
            default_shipping
        }
    }
`;
