import React from 'react';
import { format, isValid } from 'date-fns';

import { OrderContext } from '../OrderGrid/OrderProvider';
import { ProductContext } from '../ProductGrid/ProductProvider';
import { CustomerContext } from '../CustomerGrid/CustomerProvider';

import { Box, Button, Popover, TextField } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import { HeaderNumberFilter, RangePopoverContainer } from './StyledFilters';
import { Grids, UpdateGridFilter } from '../../services/gridFilters.service';
import { styled } from '@mui/material/styles';

export type Props = {
    placeholder: string;
    grid: Grids;
};

const PopoverContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

const RangeDatePicker: React.FC<Props> = ({ placeholder, grid }) => {
    const { updateOrderFilter, orderColumnFilters } =
        React.useContext(OrderContext);
    const { updateProductFilter, productColumnFilters } =
        React.useContext(ProductContext);
    const { updateCustomerFilter, customerColumnFilters } =
        React.useContext(CustomerContext);
    const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
    const [dateFrom, setDateFrom] = React.useState('');
    const [dateTo, setDateTo] = React.useState('');
    const [updateFilter, setUpdateFilter] = React.useState<UpdateGridFilter>();

    React.useEffect(() => {
        switch (grid) {
            case 'order':
                setUpdateFilter(() => updateOrderFilter);
                orderColumnFilters.get('dateFrom') &&
                    setDateFrom(
                        format(
                            new Date(orderColumnFilters.get('dateFrom')),
                            'dd/MM/yyyy'
                        )
                    );
                orderColumnFilters.get('dateTo') &&
                    setDateTo(
                        format(
                            new Date(orderColumnFilters.get('dateTo')),
                            'dd/MM/yyyy'
                        )
                    );
                setValue([
                    new Date(orderColumnFilters.get('dateFrom')),
                    new Date(orderColumnFilters.get('dateTo')),
                ]);
                break;
            case 'product':
                setUpdateFilter(() => updateProductFilter);
                productColumnFilters.get('dateFrom') &&
                    setDateFrom(
                        format(
                            new Date(productColumnFilters.get('dateFrom')),
                            'dd/MM/yyyy'
                        )
                    );
                productColumnFilters.get('dateTo') &&
                    setDateTo(
                        format(
                            new Date(productColumnFilters.get('dateTo')),
                            'dd/MM/yyyy'
                        )
                    );
                setValue([
                    new Date(productColumnFilters.get('dateFrom')),
                    new Date(productColumnFilters.get('dateTo')),
                ]);
                break;
            case 'customer':
                setUpdateFilter(() => updateCustomerFilter);
                customerColumnFilters.get('dateFrom') &&
                    setDateFrom(
                        format(
                            new Date(customerColumnFilters.get('dateFrom')),
                            'dd/MM/yyyy'
                        )
                    );
                customerColumnFilters.get('dateTo') &&
                    setDateTo(
                        format(
                            new Date(customerColumnFilters.get('dateTo')),
                            'dd/MM/yyyy'
                        )
                    );
                setValue([
                    new Date(customerColumnFilters.get('dateFrom')),
                    new Date(customerColumnFilters.get('dateTo')),
                ]);
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    // Popover management
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(
        null
    );
    const open = Boolean(anchorEl);
    const handleHeaderClick = (e: React.MouseEvent<HTMLInputElement>) => {
        setAnchorEl(e.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const handleClearFilter = () => {
        handlePopOverClose();
        setDateFrom('');
        setDateTo('');
        setValue([null, null]);

        if (!updateFilter) return;
        updateFilter('dateFrom', '');
        updateFilter('dateTo', '');
    };

    React.useEffect(() => {
        if (!value[0] || !value[1] || !isValid(value[0]) || !isValid(value[1]))
            return;

        setDateFrom(format(value[0], 'dd/MM/yyyy'));
        setDateTo(format(value[1], 'dd/MM/yyyy'));
    }, [value]);

    React.useEffect(() => {
        if (!dateFrom || !dateTo || !updateFilter || !value[0] || !value[1])
            return;

        updateFilter('dateFrom', format(value[0], 'yyyy/MM/dd'));
        updateFilter('dateTo', format(value[1], 'yyyy/MM/dd'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo]);

    return (
        <>
            <HeaderNumberFilter
                sx={{ padding: 0, paddingLeft: '5px' }}
                variant="standard"
                value={dateFrom || dateTo ? dateFrom + ' - ' + dateTo : ''}
                placeholder={placeholder}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    handleHeaderClick(e);
                }}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <RangePopoverContainer>
                    <DateRangePicker
                        inputFormat="dd/MM/yyyy"
                        startText="De"
                        endText="à"
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        value={value}
                        renderInput={(startProps, endProps) => (
                            <PopoverContainer>
                                <Box>
                                    <TextField {...startProps} />
                                    <TextField {...endProps} />
                                </Box>
                                {!!(dateFrom || dateTo) && (
                                    <Button onClick={handleClearFilter}>
                                        Retirer le filtre
                                    </Button>
                                )}
                            </PopoverContainer>
                        )}
                    />
                </RangePopoverContainer>
            </Popover>
        </>
    );
};

export default RangeDatePicker;
