import apolloQuery from '../utils/apolloQuery';
import currency from '../utils/currency';
import { APICart } from './cart.service';
import { PAYMENT_LIST } from '../queries/PAYMENT_LIST_QUERY';

export type APIMoney = {
    currency: string;
    value: number;
};
export type APIPrices = {
    discounts?: [
        {
            amount: number;
            label: string;
        }
    ];
    grand_total: APIMoney;
    subtotal_including_tax: APIMoney;
};
export type APIPaymentOption = {
    label: string;
    code: string;
};
export type APIPaymentOptions = undefined | [APIPaymentOption];
export type APIPaymentMethodInput = {
    amount: number;
    code: string;
    label?: string;
};
export type APIPaymentMethods = Array<APIPaymentMethodInput>;
export type APICompositePayment = Array<{
    payments: Array<APIPaymentMethodInput>;
    total: number;
}>;
export type MapPaymentMethod = {
    label: string;
    code: string;
    used: boolean;
    amount?: number;
};
export type PaymentMethods = Array<MapPaymentMethod>;
export type PaymentMethodsMap = Map<string, MapPaymentMethod>;
export type FinalPaymentMethodsMap = undefined | PaymentMethodsMap;
export type DueFormValues = {
    amount: number;
};

export const fetchPaymentOptions = async (storeID: number): Promise<any> => {
    const paymentOptions = new Map();
    const queryOptions = {
        query: PAYMENT_LIST,
        variables: {
            store_id: storeID,
        },
    };

    return await apolloQuery(queryOptions)
        .then((paymentData) => {
            const items = paymentData.data.posPaymentMethods.items;

            items.forEach((item: APIPaymentOption) => {
                paymentOptions.set(item.code, {
                    label: item.label,
                    code: item.code,
                    used: false,
                    amount: 0,
                });
            });

            return paymentOptions;
        })
        .catch((paymentError) => {
            console.error(paymentError);
        });
};

export const updatePaymentOptions = (
    compositePayments: APIPaymentMethods,
    APIPaymentOptions: FinalPaymentMethodsMap
): FinalPaymentMethodsMap => {
    if (!APIPaymentOptions) {
        return;
    }
    const newMap = APIPaymentOptions;

    compositePayments.forEach((compositePayment: APIPaymentMethodInput) => {
        const correspondingOption = newMap.get(compositePayment.code);

        if (!correspondingOption) {
            return;
        }

        correspondingOption.used = true;
        correspondingOption.amount = compositePayment.amount * 100;

        newMap.set(compositePayment.code, correspondingOption);
    });

    return newMap;
};

export const updatePaidTotal = (
    paymentOptions: FinalPaymentMethodsMap
): number => {
    if (!paymentOptions) {
        return 0;
    }
    let paidTotal = 0;

    Array.from(paymentOptions).forEach((option) => {
        const paymentOption = option[1];
        if (!paymentOption.used || !paymentOption.amount) {
            return;
        }

        paidTotal += paymentOption.amount;
    });

    return Math.round(paidTotal);
};

export const updateCompositePaidTotal = (
    compositePayments: APIPaymentMethods
): number => {
    if (!compositePayments?.length) {
        return 0;
    }
    let paidTotal = 0;

    compositePayments.forEach((compositePayment) => {
        paidTotal += compositePayment.amount * 100;
    });

    return Math.round(paidTotal);
};

export const checkHasPaidTotal = (
    paidTotal: number,
    grandTotal: number
): boolean => {
    return paidTotal == grandTotal;
};

export const convertPaymentsMapToArrayOfUsedOptions = (
    paymentOptions: FinalPaymentMethodsMap
): APIPaymentMethods | undefined => {
    if (!paymentOptions) {
        return;
    }
    const paymentsArray: APIPaymentMethods = [];

    Array.from(paymentOptions).forEach((option) => {
        const paymentOption = option[1];
        if (!paymentOption.used || !paymentOption.amount) {
            return;
        }

        paymentsArray.push({
            code: paymentOption.code,
            amount: currency(paymentOption.amount),
        });
    });

    return paymentsArray;
};

export const checkIsSamePayment = (
    compositePayment: APIPaymentMethods,
    paymentMethods: APIPaymentMethods
): boolean => {
    let isSamePayment = true;

    const longestPaymentsArray =
        compositePayment.length >= paymentMethods.length
            ? compositePayment
            : paymentMethods;
    const shortestPaymentsArray =
        compositePayment.length >= paymentMethods.length
            ? paymentMethods
            : compositePayment;

    longestPaymentsArray.forEach((longestPayment, index) => {
        const isInArray = shortestPaymentsArray.some(
            (payment) =>
                payment.code === longestPayment.code &&
                payment.amount === longestPayment.amount
        );

        if (!isInArray) {
            isSamePayment = false;
            return;
        }
    });

    return isSamePayment;
};

export const hasPaymentAmounts = (
    paymentOptions: PaymentMethodsMap
): boolean => {
    if (!paymentOptions?.size) return false;
    const optionsArray = Array.from(paymentOptions.values());

    for (let i = 0; i < optionsArray.length; i++) {
        if (optionsArray[i].amount && optionsArray[i].used) return true;
        continue;
    }
    return false;
};

export const shouldResetPayments = (
    cart: APICart,
    paymentOptions: PaymentMethodsMap
): boolean => {
    if (!cart?.composite_payment?.length) return false;
    if (hasPaymentAmounts(paymentOptions)) return false;
    return true;
};
