import { gql } from '@apollo/client';

export const PRODUCT_DROPDOWNCOLUMNS = gql`
    query posProductGridColumns {
        posProductGridColumns {
            items {
                type
                label
                values {
                    id
                    label
                }
            }
        }
    }
`;
