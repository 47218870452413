import React from 'react';

import { FinalPaymentMethodsMap } from '../../services/payment.service';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import PaymentItem from './PaymentItem';

type Props = {
    paymentOptions: FinalPaymentMethodsMap;
    currentOption: string;
    handlePaymentSelection: (code: string) => void;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

const PaymentOptions: React.FC<Props> = ({
    paymentOptions,
    currentOption,
    handlePaymentSelection,
}) => {
    return (
        <Container>
            {paymentOptions
                ? Array.from(paymentOptions).map((option) => {
                      return (
                          <PaymentItem
                              handlePaymentSelection={handlePaymentSelection}
                              key={option[1].code}
                              label={option[1].label}
                              code={option[1].code}
                              used={option[1].used}
                              currentOption={currentOption}
                          />
                      );
                  })
                : ''}
        </Container>
    );
};

export default PaymentOptions;
