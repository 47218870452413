import { gql } from '@apollo/client';

export const CART_ADDCOMMENT = gql`
    mutation posAddCartComment(
        $store_id: Int!
        $cart_id: String!
        $comment: String!
    ) {
        posAddCartComment(
            store_id: $store_id
            cart_id: $cart_id
            comment: $comment
        ) {
            id
            customer_note
        }
    }
`;
