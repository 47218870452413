import { APICustomer, APICustomerAddress } from './customer.service';
import { APIAppliedCoupons, APIAppliedGiftCards } from './giftcard.service';
import { APICompositePayment, APIPrices } from './payment.service';
import { APIProductItem } from './products.service';
import { CART_DELETE } from '../queries/CART_DELETE_MUTATION';
import apolloMutate from '../utils/apolloMutate';

export type APICart = {
    applied_coupons?: APIAppliedCoupons;
    applied_gift_cards?: APIAppliedGiftCards;
    billing_address: APICustomerAddress;
    composite_payment: APICompositePayment;
    company: string;
    customer?: APICustomer;
    customer_note: string;
    email: string;
    firstname: string;
    id: string;
    items: [APIProductItem];
    lastname: string;
    prices: APIPrices;
    shipping_addresses: [APICustomerAddress];
    telephone: string;
    total_quantity: number;
};

export type RecapTypes = 'cart' | 'order';

export type PosError = {
    extensions: {
        category: string;
    };
    locations: [
        {
            line: number;
            column: number;
        }
    ];
    message: string;
    path: [string];
};

export const handleCartWipeOnLogin = async (
    localCarts: Array<any>
): Promise<any> => {
    if (!localCarts?.length) return;

    await Promise.all(localCarts.map(deleteSingleCartOnLogin));
};

export const deleteSingleCartOnLogin = async (cart: any) => {
    console.log('deleting cart : ', cart[0], cart[1].id, cart);
    const mutationOptions = {
        mutation: CART_DELETE,
        variables: {
            cart_id: cart[0],
        },
    };

    return await apolloMutate(mutationOptions);
};
