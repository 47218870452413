import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
    AddressRadio,
    AddressRadioEnum,
    AddressRadioMethods,
} from '../../services/delivery.service';
import {
    AddressTypes,
    CustomerFormTypesEnum,
} from '../../services/customer.service';

import { FormControlLabel, Radio } from '@mui/material';

type Props = {
    addressToSelect: AddressRadio;
    addressType: AddressTypes;
    handleAddressSelection: () => void;
};

const SelectAddressRadio: React.FC<Props> = ({
    addressToSelect,
    addressType,
    handleAddressSelection,
}) => {
    const { register } = useFormContext();

    return (
        <FormControlLabel
            value={addressToSelect}
            control={
                <Radio
                    {...register(addressType + 'form', { required: false })}
                />
            }
            label={
                addressToSelect == AddressRadioEnum.DEFAULT
                    ? 'Adresse par défaut'
                    : 'Nouvelle adresse'
            }
            onClick={handleAddressSelection}
        />
    );
};

export default SelectAddressRadio;
