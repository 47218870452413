import React from 'react';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

type Props = {
    isLoading: boolean;
    enabled: boolean;
};

const SubmitContainer = styled(Box)(() => ({
    order: 100,
    width: '100%',
    marginTop: '33px',
    marginLeft: '39px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));
const StyledSubmit = styled(Button)(() => ({
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));

const CartCustomerSubmit: React.FC<Props> = ({ isLoading, enabled }) => {
    return (
        <SubmitContainer>
            <StyledSubmit
                id="cartCustomerForm"
                type="submit"
                disabled={isLoading || !enabled}
                sx={{
                    background:
                        isLoading || !enabled
                            ? theme.palette.action.disabledBackground
                            : '',
                    color:
                        isLoading || !enabled
                            ? theme.palette.action.disabled
                            : '',
                    '&:hover': {
                        background:
                            isLoading || !enabled
                                ? theme.palette.action.disabledBackground
                                : '',
                        color:
                            isLoading || !enabled
                                ? theme.palette.action.disabled
                                : '',
                    },
                }}
            >
                Valider les modifications
            </StyledSubmit>
        </SubmitContainer>
    );
};

export default CartCustomerSubmit;
