import React from 'react';

import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';

import { ColumnHeaderContainer, ColumnHeaderName } from './StyledFilters';
import FreeTextSearch from './FreeTextSearch';
import SelectDropdown from './SelectDropdown';
import RangeNumberSearch from './RangeNumberSearch';
import RangeDatePicker from './RangeDatePicker';

import { Grids } from '../../services/gridFilters.service';

export const textRenderHeader = (
    params: GridColumnHeaderParams,
    grid: Grids
): React.ReactElement => {
    return (
        <ColumnHeaderContainer>
            <ColumnHeaderName>{params.colDef.headerName}</ColumnHeaderName>
            <FreeTextSearch
                placeholder="-rechercher-"
                filter={params.colDef.field}
                grid={grid}
            />
        </ColumnHeaderContainer>
    );
};

export const dropdownRenderHeader = (
    params: GridColumnHeaderParams,
    grid: Grids,
    filter?: string
): React.ReactElement => {
    return (
        <ColumnHeaderContainer>
            <ColumnHeaderName>{params.colDef.headerName}</ColumnHeaderName>
            <SelectDropdown
                placeholder="-rechercher-"
                filter={filter ?? params.colDef.headerName}
                grid={grid}
            />
        </ColumnHeaderContainer>
    );
};

export const numberRangeRenderHeader = (
    params: GridColumnHeaderParams,
    grid: Grids
): React.ReactElement => {
    return (
        <ColumnHeaderContainer>
            <ColumnHeaderName>{params.colDef.headerName}</ColumnHeaderName>
            <RangeNumberSearch
                value=""
                placeholder="-de ... à ...-"
                filterFrom="subtotalFrom"
                filterTo="subtotalTo"
                grid={grid}
            />
        </ColumnHeaderContainer>
    );
};

export const dateRangeRenderHeader = (
    params: GridColumnHeaderParams,
    grid: Grids
): React.ReactElement => {
    return (
        <ColumnHeaderContainer>
            <ColumnHeaderName>{params.colDef.headerName}</ColumnHeaderName>
            <RangeDatePicker placeholder="-rechercher-" grid={grid} />
        </ColumnHeaderContainer>
    );
};
