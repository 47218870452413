import React from 'react';

import apolloQuery from '../../utils/apolloQuery';
import { UserContext } from '../../providers/UserProvider';
import { CartContext } from '../../providers/CartProvider';
import { ProductContext } from './ProductProvider';
import { PRODUCT_SEARCH } from '../../queries/PRODUCT_SEARCH_QUERY';
import { pinnedProductColumns, productColumns } from './productColumns';
import { QueryOptions } from '@apollo/client';
import compareMaps from '../../utils/compareMaps';
import { formatProductRows } from '../../services/products.service';
import { subtotalRange } from '../../services/gridFilters.service';

import { Box, CircularProgress } from '@mui/material';
import { frFR } from '@mui/x-data-grid';
import { DataGridPro, GridRowsProp } from '@mui/x-data-grid-pro';

export const ProductGrid: React.FunctionComponent = () => {
    const { user } = React.useContext(UserContext);
    const { activeCart } = React.useContext(CartContext);
    const {
        productColumnFilters,
        productDropdownColumns,
        updateProductDropdownColumns,
    } = React.useContext(ProductContext);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [totalRows, setTotalRows] = React.useState(10);
    const [rowsPerPage, setRowsPerPage] = React.useState(7);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [previousFilters, setPreviousFilters] = React.useState(new Map());

    React.useEffect(() => {
        setPreviousFilters(productColumnFilters);
        // Magento pages start at 1, but MUI DataGrid starts at 0
        let currentPageMagento = currentPage + 1;
        if (!compareMaps(previousFilters, productColumnFilters)) {
            currentPageMagento = 1;
        }
        const subtotalFilter = subtotalRange(productColumnFilters);

        const queryOptions: QueryOptions = {
            query: PRODUCT_SEARCH,
            variables: {
                filter: {
                    sku: {},
                    bc_barcode: {},
                    name: {},
                    manufacturer: {},
                    price: subtotalFilter,
                    couleur: {},
                    sexe: {},
                    collection_de_mode: {},
                    annee: {},
                    saison: {},
                },
                sort: { name: 'ASC' },
                pageSize: rowsPerPage,
                currentPage: currentPageMagento,
            },
            errorPolicy: 'all',
        };
        if (!queryOptions?.variables) return;
        if (productColumnFilters.get('sku'))
            queryOptions.variables.filter.sku = {
                eq: productColumnFilters.get('sku'),
            };
        if (productColumnFilters.get('barcode'))
            queryOptions.variables.filter.bc_barcode = {
                match: productColumnFilters.get('barcode'),
            };
        if (productColumnFilters.get('name'))
            queryOptions.variables.filter.name = {
                match: productColumnFilters.get('name'),
            };
        if (productColumnFilters.get('Marque'))
            queryOptions.variables.filter.manufacturer = {
                eq: productColumnFilters.get('Marque'),
            };
        if (productColumnFilters.get('Couleur'))
            queryOptions.variables.filter.couleur = {
                eq: productColumnFilters.get('Couleur'),
            };
        if (productColumnFilters.get('Sexe'))
            queryOptions.variables.filter.sexe = {
                eq: productColumnFilters.get('Sexe'),
            };
        if (productColumnFilters.get('Collection'))
            queryOptions.variables.filter.collection_de_mode = {
                eq: productColumnFilters.get('Collection'),
            };
        if (productColumnFilters.get('Année'))
            queryOptions.variables.filter.annee = {
                eq: productColumnFilters.get('Année'),
            };
        if (productColumnFilters.get('Saison'))
            queryOptions.variables.filter.saison = {
                eq: productColumnFilters.get('Saison'),
            };

        const delay = setTimeout(async () => {
            setIsLoading(true);
            const response = await apolloQuery(queryOptions);
            if (!response?.data?.posProductFilter) return;

            if (response.errors) {
                console.log('GraphQL error, see below: ');
                console.error(response.errors[0]);
            }

            setRows(formatProductRows(response));
            setTotalRows(response.data.posProductFilter.total_count);
            setIsLoading(false);
        }, 500);

        return () => {
            clearTimeout(delay);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, rowsPerPage, productColumnFilters]);

    React.useEffect(() => {
        updateProductDropdownColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <Box>
            {!(productDropdownColumns.size && activeCart) ? (
                <CircularProgress />
            ) : (
                <DataGridPro
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    disableColumnReorder
                    headerHeight={100}
                    rows={rows}
                    columns={productColumns}
                    initialState={{ pinnedColumns: pinnedProductColumns }}
                    pagination
                    paginationMode="server"
                    page={currentPage}
                    rowCount={totalRows}
                    rowsPerPageOptions={[7, 15, 25]}
                    pageSize={rowsPerPage}
                    onPageSizeChange={(newPageSize) =>
                        setRowsPerPage(newPageSize)
                    }
                    onPageChange={(newPage) => {
                        setCurrentPage(newPage);
                    }}
                    loading={isLoading}
                    localeText={
                        frFR.components.MuiDataGrid.defaultProps.localeText
                    }
                />
            )}
        </Box>
    );
};

export default ProductGrid;
