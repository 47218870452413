import React from 'react';
import { CartContext } from '../../providers/CartProvider';
import { CartGridContext } from './CartGridProvider';
import currency from '../../utils/currency';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, TextField, Typography } from '@mui/material';
import DiscountModal from './DiscountModal';
import { findItemByProductKey } from '../../services/products.service';

type Props = {
    rowID: string | number;
    itemID: string;
    price: number;
    barcode: string;
    type: string;
};

const Container = styled(Box)(() => ({
    height: '40px',
    display: 'flex',
    alignItems: 'center',
}));

const CustomPrice = styled(TextField)(() => ({
    border: '4px',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '& .MuiInputBase-formControl': {
        border: '4px',
        height: '40px',
    },
}));

const DiscountedPrice: React.FC<Props> = ({
    rowID,
    itemID,
    price,
    barcode,
    type,
}) => {
    const {
        activeCart,
        carts,
        applyCustomDiscount,
        unapplyCustomDiscount,
        updateGiftCard,
    } = React.useContext(CartContext);
    const { rowsDiscount, updateRowsDiscount } =
        React.useContext(CartGridContext);
    const [isCustomDiscount, setIsCustomDiscount] =
        React.useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

    const handleCustomDiscount = (newPrice: number) => {
        if (type == 'amgiftcard') {
            updateGiftCard(activeCart, itemID, newPrice);
            updateRowsDiscount(rowID, '');
            return;
        }
        applyCustomDiscount(activeCart, barcode, newPrice);
    };
    const handleDefect = (discount: number) => {
        const discountMultiplier = currency(100 - discount * 100);
        const newPrice = price * 100 * discountMultiplier;
        applyCustomDiscount(activeCart, barcode, currency(newPrice));
    };
    const handleUnapplyDiscounts = () => {
        setModalIsOpen(false);
        unapplyCustomDiscount(activeCart, barcode);
        updateRowsDiscount(rowID, '');
    };
    const handleCancelUnapplyDiscounts = () => {
        setModalIsOpen(false);
        updateRowsDiscount(rowID, '');
    };

    React.useEffect(() => {
        if (!rowsDiscount.get(rowID)) {
            setIsCustomDiscount(false);
            return;
        }

        if (
            carts.get(activeCart)?.items &&
            !(rowsDiscount.get(rowID) == 'unapplyDiscounts')
        ) {
            const item = findItemByProductKey(
                carts.get(activeCart).items,
                'bc_barcode',
                barcode
            );

            if (
                item?.prices.original_price?.value !== item?.prices.price?.value
            ) {
                setIsCustomDiscount(false);
                return;
            }
        }

        switch (rowsDiscount.get(rowID)) {
            case 'customDiscount':
                setIsCustomDiscount(true);
                break;
            case 'defect10':
                setIsCustomDiscount(false);
                handleDefect(0.1);
                break;
            case 'unapplyDiscounts':
                setModalIsOpen(true);
                setIsCustomDiscount(false);
                break;
            default:
                setIsCustomDiscount(false);
                break;
        }

        if (rowsDiscount.get(rowID) !== 'customDiscount') {
            setIsCustomDiscount(false);
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowID, rowsDiscount]);

    React.useEffect(() => {
        setIsCustomDiscount(false);
    }, [carts]);

    return (
        <Container>
            {isCustomDiscount ? (
                <CustomPrice
                    type="number"
                    color="secondary"
                    defaultValue={price}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        handleCustomDiscount(parseFloat(e.target.value));
                    }}
                />
            ) : (
                <Typography>{price} €</Typography>
            )}

            {modalIsOpen && (
                <DiscountModal
                    handleValidate={handleUnapplyDiscounts}
                    handleCancel={handleCancelUnapplyDiscounts}
                />
            )}
        </Container>
    );
};

export default DiscountedPrice;
