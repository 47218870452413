import React, { PropsWithChildren, useCallback } from 'react';

import { UserContext } from './UserProvider';
import { ErrorContext } from './ErrorProvider';

import {
    AddressTypes,
    assignAddressToCart,
    assignCustomerToCart,
    assignExistingAddressesToCart,
    assignGuestCustomerToCart,
    assignNonExistingAddressesToCart,
    assignNonExistingAddressToCart,
    CartFormValues,
    CustomerAddresses,
    CustomerAddressesNR,
    CustomerAddressInput,
    updateCartCustomerInfo,
} from '../services/customer.service';
import {
    findItemByProductKey,
    forceAddProduct,
    StockModal,
    StockStatusEnum,
} from '../services/products.service';
import {
    APIPaymentMethodInput,
    FinalPaymentMethodsMap,
} from '../services/payment.service';
import {
    applyGiftCardOrCouponToCart,
    unapplyGiftCardOrCouponToCart,
    updateGiftCardInCart,
} from '../services/giftcard.service';
import {
    APIShippingMethodsInput,
    setDeliveryMethodOnCart,
} from '../services/delivery.service';
import { PlaceOrderData } from '../services/orders.service';

import apolloMutate from '../utils/apolloMutate';
import apolloQuery from '../utils/apolloQuery';

import { CART_QUERY } from '../queries/CART_QUERY';
import { CART_CREATION_MUTATION } from '../queries/CART_CREATION_MUTATION';
import { CART_DELETE } from '../queries/CART_DELETE_MUTATION';
import { CART_ADDCOMMENT } from '../queries/CART_ADDCOMMENT_MUTATION';
import { CART_SETPAYMENTS } from '../queries/CART_SETPAYMENTS_MUTATION';
import { CART_RESETPAYMENTS } from '../queries/CART_RESETPAYMENTS_MUTATION';
import { CART_ADDPRODUCT_MUTATION } from '../queries/CART_ADDPRODUCT_MUTATION';
import { CART_REMOVEPRODUCT } from '../queries/CART_REMOVEPRODUCT_MUTATION';
import { PRODUCT_SETPRICE } from '../queries/PRODUCT_SETPRICE_MUTATION';
import { PRODUCT_UNSETPRICE } from '../queries/PRODUCT_UNSETPRICE_MUTATION';
import { ORDER_PLACE } from '../queries/ORDER_PLACE_MUTATION';

export const CartContext = React.createContext({
    carts: new Map(),
    activeCart: '',
    stockError: false,
    stockModal: { state: 'closed' } as StockModal,
    initCarts: (shouldSelectCart?: string) => {
        return;
    },
    updateStockError: (stockState: boolean) => {
        return;
    },
    selectCart: (cartID?: string) => {
        return;
    },
    createCart: () => {
        return;
    },
    updateCart: (cartID: string) => {
        return;
    },
    removeCart: (cartID: string) => {
        return;
    },
    addProduct: (sku: string, cartID: string, isFromScan?: boolean) => {
        return;
    },
    removeProduct: (cartID: string, uid: string) => {
        return;
    },
    cancelProductScan: (cartID: string, uid?: string) => {
        return;
    },
    addPreorderProduct: (cartID: string) => {
        return;
    },
    addOutOfStockProduct: (cartID: string, sku: string) => {
        return;
    },
    applyGiftCardOrCoupon: async (
        cartID: string,
        code: string
    ): Promise<any> => {
        return;
    },
    unapplyGiftCardOrCoupon: (cartID: string, code: string) => {
        return;
    },
    updateGiftCard: (cartID: string, itemID: string, amount: number) => {
        return;
    },
    applyCustomDiscount: (
        cartID: string,
        barcode: string,
        newPrice: number
    ) => {
        return;
    },
    unapplyCustomDiscount: (cartID: string, barcode: string) => {
        return;
    },
    assignCustomer: async (
        cartID: string,
        customerID?: number,
        customerInfo?: CartFormValues
    ): Promise<void> => {
        return;
    },
    updateCustomerInfo: async (
        cartID: string,
        customerID: number,
        customerInfo: CartFormValues
    ) => {
        return;
    },
    assignExistingAddresses: async (
        cartID: string,
        customerAddresses: CustomerAddresses
    ): Promise<void> => {
        return;
    },
    assignNonExistingAddresses: async (
        cartID: string,
        addressType?: AddressTypes,
        customerAddresses?: CustomerAddressesNR,
        customerAddress?: CustomerAddressInput,
        methodCodes?: APIShippingMethodsInput
    ): Promise<any> => {
        return;
    },
    assignAddress: (
        cartID: string,
        customerAddresses: CustomerAddressesNR,
        hasID: boolean,
        addressType: AddressTypes
    ) => {
        return;
    },
    addPayment: (
        cartID: string,
        APIPaymentMethods: Array<APIPaymentMethodInput>,
        paymentMethods: FinalPaymentMethodsMap
    ) => {
        return;
    },
    resetPayments: async (cartID: string): Promise<any> => {
        return;
    },
    setDeliveryMethod: (cartID: string, method: APIShippingMethodsInput) => {
        return;
    },
    addComment: (comment: string, cartID: string) => {
        return;
    },
    placeOrder: async (cartID: string): Promise<any> => {
        return;
    },
    cartLogout: () => {
        return;
    },
});

export const CartProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { user, logout } = React.useContext(UserContext);
    const { addError } = React.useContext(ErrorContext);

    const [carts, setCarts] = React.useState(new Map());
    const [activeCart, setActiveCart] = React.useState(
        carts.size > 0 ? carts.entries().next().value[0] : ''
    );
    const [stockError, setStockError] = React.useState<boolean>(false);
    const [stockModal, setStockModal] = React.useState<StockModal>({
        state: 'closed',
    });

    React.useEffect(() => {
        if (activeCart && carts.get(activeCart)) return;

        const localActiveCart = localStorage.getItem('activeCart');
        if (!localActiveCart) return;

        if (carts?.size) {
            selectCart(localActiveCart);
            return;
        }

        initCarts(localActiveCart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCart, carts, user]);

    const initCarts = (localActiveCart?: string) => {
        const cartsMap = new Map();
        const localCarts = JSON.parse(localStorage.getItem('carts') ?? '[]');

        if (!localActiveCart && (!localCarts || !localCarts.length)) {
            selectCart();
            return;
        }

        localCarts.forEach((cart: any) => {
            cartsMap.set(cart[0], cart[1]);
        });

        setCarts(cartsMap);
    };

    const updateStockError = (errorState: boolean) => {
        setStockError(errorState);
    };
    const createCart = () => {
        if (carts.size < 5) {
            const mutationOptions = {
                mutation: CART_CREATION_MUTATION,
                variables: {
                    store_id: user.storeId,
                },
            };

            apolloMutate(mutationOptions)
                .then((newCartData) => {
                    const cartID = newCartData.data.posCreateEmptyCart;
                    updateCart(cartID);
                    localStorage.setItem('activeCart', cartID);
                })
                .catch((newCartErr) => {
                    console.error(newCartErr);
                });
        } else {
            return false;
        }
    };
    const selectCart = useCallback(
        (cartID?: string) => {
            if (!cartID) {
                const firstCart = carts?.entries()?.next();
                if (!firstCart || !firstCart.value || !firstCart.value[0]) {
                    localStorage.removeItem('activeCart');
                    createCart();
                    return;
                }

                setActiveCart(firstCart.value[0]);
                localStorage.setItem('activeCart', firstCart.value[0]);

                return;
            }
            if (!carts.get(cartID)) return;
            setActiveCart(cartID);
            localStorage.setItem('activeCart', cartID);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [carts]
    );
    const updateCart = (cartID: string) => {
        const queryOptions = {
            query: CART_QUERY,
            variables: {
                store_id: user.storeId,
                cart_id: cartID,
            },
        };
        apolloQuery(queryOptions)
            .then((cartData) => {
                const newCarts = new Map(
                    carts.set(cartID, cartData.data.posCart)
                );

                setCarts(newCarts);
                localStorage.setItem(
                    'carts',
                    JSON.stringify(Array.from(newCarts.entries()))
                );

                selectCart(cartID);
            })
            .catch((cartErr) => {
                console.error(cartErr);
            });
    };

    const removeCart = (cartID: string) => {
        if (!carts.has(cartID)) {
            return;
        }
        carts.delete(cartID);

        setCarts(new Map(carts));
        localStorage.setItem(
            'carts',
            JSON.stringify(Array.from(carts.entries()))
        );

        const mutationOptions = {
            mutation: CART_DELETE,
            variables: {
                cart_id: cartID,
            },
        };

        apolloMutate(mutationOptions);
    };

    const addProduct = (
        barcode: string,
        cartID: string,
        isFromScan?: boolean
    ) => {
        const mutationOptions = {
            mutation: CART_ADDPRODUCT_MUTATION,
            variables: {
                store_id: user.storeId,
                cart_id: cartID,
                bc_barcode: barcode,
                quantity: 1,
            },
        };
        apolloMutate(mutationOptions)
            .then((productData) => {
                if (productData?.errors && productData?.errors?.length) {
                    for (let i = 0; i < productData.errors.length; i++) {
                        const error = productData.errors[i];
                        if (
                            isFromScan &&
                            (error.message.includes('is not available') ||
                                error.message.includes('is out of stock'))
                        ) {
                            setStockModal({
                                state: 'outofstock',
                                barcode: barcode,
                            });
                            return;
                        } else {
                            addError({
                                id: crypto.randomUUID(),
                                title: error.message,
                                description: error,
                            });
                        }
                    }
                }

                if (!isFromScan) {
                    updateCart(cartID);
                    return;
                }

                const item = findItemByProductKey(
                    productData?.data?.posAddProductsToCart?.cart?.items,
                    'bc_barcode',
                    barcode
                );
                const stockStatus = item?.pos_stock?.status;

                if (!item) {
                    return;
                }

                switch (stockStatus as StockStatusEnum) {
                    case StockStatusEnum.IN_STOCK_AT_LOCATION:
                        updateCart(cartID);
                        break;
                    case StockStatusEnum.PREORDERABLE_INFINITE:
                    case StockStatusEnum.HAS_INCOMING_QUANTITIES:
                    case StockStatusEnum.IN_STOCK_AT_DEFAULT_WAREHOUSE:
                    case StockStatusEnum.IN_STOCK_ELSEWHERE:
                        setStockModal({ state: 'preorder', uid: item.uid, barcode: barcode });
                        break;
                    case StockStatusEnum.OUT_OF_STOCK:
                        setStockModal({
                            state: 'outofstock',
                            barcode: item.product.bc_barcode,
                        });
                        break;
                    default:
                        console.error(
                            'Stock status is not supported : ',
                            stockStatus
                        );
                        return;
                }
            })
            .catch((productErr) => {
                console.error(productErr);
            });
    };

    const removeProduct = (cartID: string, uid: string) => {
        const mutationOptions = {
            mutation: CART_REMOVEPRODUCT,
            variables: {
                store_id: user.storeId,
                cart_id: cartID,
                cart_item_uid: parseInt(atob(uid)),
            },
        };

        apolloMutate(mutationOptions).then((removeRes) => {
            if (removeRes?.errors) {
                console.error(removeRes.errors[0]);
                return;
            }

            updateCart(cartID);
        });
        return;
    };

    const cancelProductScan = (cartID: string, uid?: string) => {
        if (!uid) {
            setStockModal({ state: 'closed' });
            return;
        }
        setStockModal({ state: 'closed', uid: uid });
        removeProduct(cartID, uid);
    };

    const addPreorderProduct = (cartID: string) => {
        setStockModal({ state: 'closed' });
        updateCart(cartID);
    };

    const addOutOfStockProduct = (cartID: string, barcode: string) => {
        setStockModal({ state: 'closed', barcode: barcode });
        forceAddProduct(user.storeId, cartID, barcode).then((forceAddData) => {
            updateCart(cartID);
            if (!forceAddData?.errors || !forceAddData?.errors?.length) return;

            for (const i in forceAddData.errors) {
                console.error(
                    forceAddData.errors[i]?.code,
                    forceAddData.errors[i]?.message
                );
            }
        });
    };

    const unapplyGiftCardOrCoupon = (cartID: string, code: string) => {
        unapplyGiftCardOrCouponToCart(cartID, code)
            .then(() => {
                updateCart(cartID);
            })
            .catch((codeErr) => {
                console.error(codeErr);
            });
    };

    const updateGiftCard = (cartID: string, itemID: string, amount: number) => {
        updateGiftCardInCart(cartID, itemID, amount).then(() => {
            updateCart(cartID);
        });
    };

    const applyCustomDiscount = (
        cartID: string,
        barcode: string,
        discountAmount: number
    ) => {
        const mutationOptions = {
            mutation: PRODUCT_SETPRICE,
            variables: {
                cart_id: cartID,
                barcode: barcode,
                price: discountAmount,
            },
        };

        apolloMutate(mutationOptions)
            .then((customDiscount) => {
                if (customDiscount?.errors)
                    console.error(customDiscount.errors);

                updateCart(cartID);
            })
            .catch((customDiscountError) => {
                console.error(customDiscountError);
            });
    };

    const unapplyCustomDiscount = (cartID: string, barcode: string) => {
        const mutationOptions = {
            mutation: PRODUCT_UNSETPRICE,
            variables: {
                cart_id: cartID,
                barcode: barcode,
            },
        };

        apolloMutate(mutationOptions)
            .then((customDiscount) => {
                if (customDiscount?.errors)
                    console.error(customDiscount.errors);

                updateCart(cartID);
            })
            .catch((customDiscountError) => {
                console.error(customDiscountError);
            });
    };

    const assignCustomer = async (
        cartID: string,
        customerID?: number,
        customerInfo?: CartFormValues
    ): Promise<any> => {
        const cartItems = carts.get(cartID)?.items;
        if (customerID && !customerInfo) {
            sessionStorage.setItem('customerID', customerID.toString());
            return await assignCustomerToCart(
                user.storeId,
                cartID,
                customerID,
                cartItems
            ).then(() => {
                updateCart(cartID);
                window.history.replaceState({}, document.title);
            });
        }
        if (!customerID && customerInfo) {
            return await assignGuestCustomerToCart(
                user.storeId,
                cartID,
                customerInfo,
                cartItems
            ).then((error) => {
                updateCart(cartID);
                if (error) return error;
            });
        }
    };

    const updateCustomerInfo = async (
        cartID: string,
        customerID: number,
        customerInfo: CartFormValues
    ) => {
        return await updateCartCustomerInfo(customerInfo, customerID).then(
            (customerData) => {
                if (customerData?.errors) console.error(customerData.errors);

                updateCart(cartID);
            }
        );
    };

    const assignExistingAddresses = async (
        cartID: string,
        customerAddresses: CustomerAddresses
    ): Promise<any> => {
        return await assignExistingAddressesToCart(
            user.storeId,
            cartID,
            customerAddresses
        ).then(() => {
            updateCart(cartID);
        });
    };

    const assignNonExistingAddresses = async (
        cartID: string,
        addressType?: AddressTypes,
        customerAddresses?: CustomerAddressesNR,
        customerAddress?: CustomerAddressInput,
        methodCodes?: APIShippingMethodsInput
    ): Promise<any> => {
        if (!addressType && customerAddresses) {
            return await assignNonExistingAddressesToCart(
                user.storeId,
                cartID,
                customerAddresses
            ).then(() => {
                updateCart(cartID);
            });
        }

        if (addressType && customerAddress && methodCodes) {
            return await assignNonExistingAddressToCart(
                user.storeId,
                cartID,
                addressType,
                customerAddress
            ).then(() => {
                setDeliveryMethod(cartID, methodCodes);
            });
        }
    };

    const assignAddress = (
        cartID: string,
        customerAddresses: CustomerAddressesNR,
        hasID: boolean,
        addressType: AddressTypes
    ) => {
        assignAddressToCart(
            user.storeId,
            cartID,
            customerAddresses,
            hasID,
            addressType
        ).then(() => {
            updateCart(cartID);
        });
    };

    const setDeliveryMethod = (
        cartID: string,
        methodCodes: APIShippingMethodsInput
    ) => {
        setDeliveryMethodOnCart(user.storeId, cartID, methodCodes).then(() => {
            updateCart(activeCart);
        });
        return;
    };

    const addPayment = (
        cartID: string,
        APIPaymentMethods: Array<APIPaymentMethodInput>,
        paymentMethods: FinalPaymentMethodsMap
    ) => {
        const mutationOptions = {
            mutation: CART_SETPAYMENTS,
            variables: {
                store_id: user.storeId,
                cart_id: cartID,
                payment_methods: APIPaymentMethods,
            },
        };

        apolloMutate(mutationOptions)
            .then((setMethodsData) => {
                if (setMethodsData?.errors) {
                    return false;
                }

                updateCart(activeCart);
                return true;
            })
            .catch((setMethodsError) => {
                console.error(setMethodsError);
                return false;
            });
        return false;
    };

    const resetPayments = async (cartID: string): Promise<any> => {
        const mutationOptions = {
            mutation: CART_RESETPAYMENTS,
            variables: {
                cart_id: cartID,
            },
        };

        return await apolloMutate(mutationOptions).then((resetPaymentsData) => {
            if (resetPaymentsData?.errors?.length) return;
            updateCart(cartID);
        });
    };

    const addComment = (comment: string, cartID: string) => {
        const mutationOptions = {
            mutation: CART_ADDCOMMENT,
            variables: {
                store_id: user.storeId,
                cart_id: cartID,
                comment: comment,
            },
        };

        apolloMutate(mutationOptions)
            .then(() => {
                updateCart(cartID);
            })
            .catch((commentErr) => {
                console.error(commentErr);
            });
    };

    const placeOrder = async (cartID: string): Promise<Array<string>> => {
        const mutationOptions = {
            mutation: ORDER_PLACE,
            variables: {
                cart_id: cartID,
            },
        };

        const orderIDs: Array<string> = [];

        return await apolloMutate(mutationOptions).then((orderData) => {
            if (orderData?.errors?.length) {
                console.error(orderData.errors[0].message);
                return [''];
            }

            if (!orderData?.data?.posPlaceOrder?.length) return [''];

            orderData.data.posPlaceOrder.forEach((order: PlaceOrderData) => {
                if (!order?.order?.order_number) return;
                orderIDs.push(order.order.order_number);
            });

            return orderIDs;
        });
    };

    const cartLogout = () => {
        localStorage.removeItem('activeCart');
        setActiveCart('');
        setCarts(new Map());
        logout();
    };

    return (
        <CartContext.Provider
            value={{
                carts,
                activeCart,
                stockError,
                stockModal,
                initCarts,
                updateStockError,
                selectCart,
                createCart,
                updateCart,
                removeCart,
                addProduct,
                removeProduct,
                cancelProductScan,
                addPreorderProduct,
                addOutOfStockProduct,
                applyGiftCardOrCoupon: applyGiftCardOrCouponToCart,
                unapplyGiftCardOrCoupon,
                updateGiftCard,
                applyCustomDiscount,
                unapplyCustomDiscount,
                assignCustomer,
                updateCustomerInfo,
                assignExistingAddresses,
                assignNonExistingAddresses,
                assignAddress,
                setDeliveryMethod,
                addPayment,
                resetPayments,
                addComment,
                placeOrder,
                cartLogout,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
