import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

type Props = {
    isSameAddress: boolean;
    onChangeHandler: () => void;
};

export const CustomerBISCheckbox: React.FC<Props> = ({
    isSameAddress,
    onChangeHandler,
}) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={isSameAddress}
                    onChange={() => onChangeHandler()}
                />
            }
            label="Identique à l'adresse de livraison"
        />
    );
};
