import { gql } from '@apollo/client';

export const CART_SETBILLING = gql`
    mutation posSetBillingAddressOnCart(
        $store_id: Int!
        $cart_id: String!
        $address_id: Int!
    ) {
        posSetBillingAddressOnCart(
            store_id: $store_id
            input: {
                cart_id: $cart_id
                billing_address: { customer_address_id: $address_id }
            }
        ) {
            cart {
                id
                shipping_addresses {
                    available_shipping_methods {
                        carrier_code
                        carrier_title
                        method_code
                        method_title
                        shipping_method_type
                        delivery_points {
                            id
                            city
                            name
                            store_id
                            store_code
                        }
                    }
                }
            }
        }
    }
`;

export const CART_SETBILLING_FULL = gql`
    mutation posSetBillingAddressOnCart(
        $store_id: Int!
        $cart_id: String!
        $address: CartAddressInput!
    ) {
        posSetBillingAddressOnCart(
            store_id: $store_id
            input: { cart_id: $cart_id, billing_address: { address: $address } }
        ) {
            cart {
                id
                shipping_addresses {
                    available_shipping_methods {
                        carrier_code
                        carrier_title
                        method_code
                        method_title
                        shipping_method_type
                        delivery_points {
                            id
                            city
                            name
                            store_id
                            store_code
                        }
                    }
                }
            }
        }
    }
`;
