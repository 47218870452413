import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UserContext } from '../../providers/UserProvider';
import { CartContext } from '../../providers/CartProvider';
import { APICart } from '../../services/cart.service';
import {
    CartFormValues,
    CustomerAddresses,
    SearchAutoComplete,
} from '../../services/customer.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';

import CartCustomerForm from './CartCustomerForm';
import NewCustomerButton from './NewCustomerButton';
import AdvancedSearchButton from './AdvancedSearchButton';
import SearchAutocomplete from './SearchAutocomplete';
import _ from 'lodash';

const CustomerContainer = styled(Paper)(() => ({
    padding: '18px 23px',
    marginTop: '14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
}));
const SectionTitle = styled(Typography)(() => ({
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
}));
const CustomerSelectionContainer = styled(Box)(() => ({
    width: '100%',
    marginTop: '24px',
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));
const ExistingCustomerContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '58px',
}));
const SearchContainer = styled(Box)(() => ({
    width: '270px',
    marginRight: '52px',
}));
const AdvancedSearchContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));
const CustomerFormContainer = styled(Box)(() => ({
    marginLeft: '78px',
    marginRight: '150px',
}));

export const CartCustomerArea: React.FC = () => {
    const history = useHistory();
    const { user } = React.useContext(UserContext);
    const {
        activeCart,
        assignCustomer,
        assignExistingAddresses,
        assignNonExistingAddresses,
        carts,
    } = React.useContext(CartContext);
    const { state } = useLocation<CartFormValues>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showForm, setShowForm] = React.useState<boolean>(false);
    const [hasCustomer, setHasCustomer] = React.useState<boolean>(false);
    const [hasAddresses, setHasAddresses] = React.useState<boolean>(false);
    const [autocompleteValues, setAutocompleteValues] =
        React.useState<SearchAutoComplete | null>({
            label: '',
            id: '',
        });
    const [customerValues, setCustomerValues] =
        React.useState<CartFormValues | null>(null);
    const [customerAddresses, setCustomerAddresses] = React.useState<
        CustomerAddresses | undefined
    >();
    const [isNewCustomer, setIsNewCustomer] = React.useState<boolean>(false);
    const [hasNewCustomer, setHasNewCustomer] = React.useState<boolean>(false);
    const [customerCart, setCustomerCart] = React.useState<APICart>();

    const handleNewCustomer = () => {
        setIsNewCustomer(true);
        setShowForm(true);
    };
    const handleAdvancedSearch = () => {
        history.push('/clients');
    };

    const handleResetForm = () => {
        setShowForm(false);
        setCustomerValues(null);
    };
    const handleSelectCustomer = (customerID: string) => {
        const currentCart = carts.get(activeCart);
        if (
            !customerID ||
            !currentCart ||
            currentCart.customer?.id == customerID
        )
            return;
        setIsLoading(true);
        setShowForm(true);

        assignCustomer(activeCart, parseInt(customerID)).then(() =>
            setIsLoading(false)
        );
    };
    const updateCustomer = () => {
        const currentCart = carts.get(activeCart);
        const customer = currentCart?.customer;
        setIsNewCustomer(false);

        if (!customer) {
            if (
                !currentCart?.email &&
                !currentCart?.lastname &&
                !currentCart?.firstname &&
                !currentCart?.telephone &&
                !currentCart?.company &&
                !currentCart?.is_subscribed
            )
                return;
            setHasNewCustomer(true);
            setCustomerValues({
                id: 0,
                email: currentCart.email,
                lastname: currentCart?.lastname ?? '',
                firstname: currentCart?.firstname ?? '',
                telephone: currentCart?.telephone ?? '',
                company: currentCart?.company ?? '',
                is_subscribed: currentCart?.is_subscribed ?? false,
            });
        } else {
            setCustomerValues({
                id: customer.id,
                email: customer.email,
                lastname: customer.lastname,
                firstname: customer.firstname,
                telephone: customer.telephone,
                company: customer.company,
                is_subscribed: customer.is_subscribed,
            });
            setCustomerAddresses({
                shipping: parseInt(customer.default_shipping),
                billing: parseInt(customer.default_billing),
            });
        }
        setHasCustomer(true);

        if (
            !currentCart.billing_address ||
            !currentCart.shipping_addresses.length
        ) {
            return;
        }
        setHasAddresses(true);
    };

    React.useEffect(() => {
        setCustomerCart(carts.get(activeCart));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts, activeCart, user]);

    React.useEffect(() => {
        const currentCart = carts.get(activeCart);
        if (!currentCart || !(currentCart.customer || currentCart.email)) {
            setHasCustomer(false);
            setShowForm(false);
            setAutocompleteValues({
                label: '',
                id: '',
            });
            return;
        }
        if (!localStorage.getItem('carts')) return;

        updateCustomer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCart]);

    React.useEffect(() => {
        if (_.isEqual(customerCart, carts.get(activeCart)) || !customerCart)
            return;

        updateCustomer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts]);

    React.useEffect(() => {
        const windowState = window.history.state;
        if (!windowState || !windowState.state?.id) return;

        document
            .querySelector('#area-customer')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        handleSelectCustomer(windowState.state.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, history]);

    React.useEffect(() => {
        if (!customerValues) return;

        setShowForm(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerValues]);

    React.useEffect(() => {
        if (!hasCustomer || hasAddresses) return;
        if (isNewCustomer) {
            const NRAddress = {
                city: 'n/r',
                country_code: 'FR',
                firstname: 'n/r',
                lastname: 'n/r',
                postcode: 'n/r',
                street: ['n/r'],
                telephone: 'n/r',
            };
            setIsLoading(true);
            assignNonExistingAddresses(activeCart, undefined, {
                shipping: NRAddress,
                billing: NRAddress,
            }).then(() => setIsLoading(false));
        }
        if (!customerAddresses) return;

        setIsLoading(true);
        assignExistingAddresses(activeCart, customerAddresses).then(() =>
            setIsLoading(false)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasCustomer, hasAddresses, customerAddresses]);

    return (
        <CustomerContainer sx={{ width: '100%' }} id="area-customer">
            <SectionTitle>COMPTE CLIENT</SectionTitle>

            <CustomerSelectionContainer>
                <ExistingCustomerContainer>
                    <SearchContainer>
                        {!!autocompleteValues && (
                            <SearchAutocomplete
                                currentEmail={carts.get(activeCart)?.email}
                                handleSelectCustomer={handleSelectCustomer}
                                handleResetForm={handleResetForm}
                                autocompleteValues={autocompleteValues}
                            />
                        )}
                    </SearchContainer>

                    <AdvancedSearchContainer>
                        <AdvancedSearchButton
                            onClickHandler={() => handleAdvancedSearch()}
                        />
                    </AdvancedSearchContainer>
                </ExistingCustomerContainer>

                <NewCustomerButton onClickHandler={handleNewCustomer} />
            </CustomerSelectionContainer>

            {showForm && (
                <CustomerFormContainer>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <CartCustomerForm
                            isNewCustomer={isNewCustomer}
                            hasNewCustomer={hasNewCustomer}
                            setHasNewCustomer={setHasNewCustomer}
                            customerValues={customerValues}
                        />
                    )}
                </CustomerFormContainer>
            )}
        </CustomerContainer>
    );
};

export default CartCustomerArea;
