import React from 'react';

import { CartContext } from '../../providers/CartProvider';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, Paper, Typography } from '@mui/material';

const Container = styled(Box)(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2000,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(15px)',
}));
const ModalContainer = styled(Paper)(() => ({
    position: 'absolute',
    zIndex: 2010,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '25px 30px',
}));
const Header = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));
const Content = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    textAlign: 'left',
}));
const Title = styled(Typography)(() => ({
    marginBottom: '25px',
    fontSize: '16px',
    fontWeight: 'bolder',
}));
const Buttons = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));
const StoreButton = styled(Button)(() => ({
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));
const PreorderButton = styled(Button)(() => ({
    marginLeft: '8px',
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
        background: theme.palette.warning.main,
        color: theme.palette.error.contrastText,
    },
}));

const StockModal: React.FC = () => {
    const {
        activeCart,
        addOutOfStockProduct,
        addPreorderProduct,
        cancelProductScan,
        stockModal,
    } = React.useContext(CartContext);
    const [preorderIsDisabled, setPreorderIsDisabled] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        setPreorderIsDisabled(stockModal.state !== 'preorder');
    }, [stockModal]);

    return (
        <Container>
            <ModalContainer elevation={24}>
                <Header>
                    <Button
                        sx={{ marginBottom: '25px' }}
                        onClick={() => {
                            const preorderSku =
                                stockModal.state == 'preorder'
                                    ? stockModal.uid
                                    : '';
                            cancelProductScan(activeCart, preorderSku);
                        }}
                    >
                        ANNULER LE SCAN
                    </Button>
                </Header>

                <Content>
                    <Title>PRODUIT HORS STOCK MAGASIN</Title>

                    <Typography sx={{ marginBottom: '55px' }} variant="body1">
                        Le produit scanné semble hors stock, êtes-vous sûr de
                        vouloir continuer ?<br />
                    </Typography>
                </Content>

                <Buttons>
                    <StoreButton
                        onClick={() => {
                            addOutOfStockProduct(
                                activeCart,
                                stockModal?.barcode ?? ''
                            );
                        }}
                    >
                        OUI, DISPONIBLE EN MAGASIN
                    </StoreButton>

                    <PreorderButton
                        disabled={preorderIsDisabled}
                        sx={{
                            background: preorderIsDisabled
                                ? 'white'
                                : theme.palette.warning.main,
                            color: preorderIsDisabled
                                ? theme.palette.action.disabled
                                : theme.palette.error.contrastText,
                            '&:hover': {
                                background: preorderIsDisabled
                                    ? 'white'
                                    : theme.palette.warning.main,
                                color: preorderIsDisabled
                                    ? theme.palette.action.disabled
                                    : theme.palette.error.contrastText,
                            },
                        }}
                        onClick={() => {
                            addPreorderProduct(activeCart);
                        }}
                    >
                        OUI, DISPONIBLE EN PRÉ-COMMANDE
                    </PreorderButton>
                </Buttons>
            </ModalContainer>
        </Container>
    );
};

export default StockModal;
