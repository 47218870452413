import { gql } from '@apollo/client';

export const CART_FORCEADDPRODUCT = gql`
    mutation posForceAddProductsToCart(
        $store_id: Int!
        $cart_id: String!
        $bc_barcode: String!
        $quantity: Float!
    ) {
        posForceAddProductsToCart(
            store_id: $store_id
            cart_id: $cart_id
            cart_items: [{ bc_barcode: $bc_barcode, quantity: $quantity }]
        ) {
            cart {
                id
            }
        }
    }
`;
