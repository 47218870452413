import React from 'react';
import { useHistory } from 'react-router-dom';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { ManageSearchOutlined } from '@mui/icons-material';

const StyledButton = styled(Button)(() => ({
    color: theme.palette.primary.main,
    '& svg': {
        height: '18px',
        width: '18px',
        marginRight: '8px',
    },
}));

export const AdvancedProductSearch: React.FC = () => {
    const history = useHistory();

    return (
        <StyledButton
            variant="text"
            onClick={() => {
                history.push('/produits');
            }}
        >
            <ManageSearchOutlined />
            RECHERCHE AVANCÉE
        </StyledButton>
    );
};

export default AdvancedProductSearch;
