import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CustomerInfoForm from '../../components/CustomerInfoForm/CustomerInfoForm';

const Container = styled(Box)(() => ({
    width: '100%',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    textAlign: 'left',
    background: 'white',
}));

const CustomerInfo: React.FunctionComponent = () => {
    return (
        <Container>
            <Typography component="h1" variant="h1" sx={{ marginLeft: 0 }}>
                Fiche client
            </Typography>
            <CustomerInfoForm />
        </Container>
    );
};

export default CustomerInfo;
