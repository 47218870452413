import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Modal } from '@mui/material';
import { Clear } from '@mui/icons-material';

type Props = {
    open: boolean;
    handleClose: () => void;
    image: string;
};

const Container = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '4px',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        maxHeight: '80vh',
        maxWidth: '80vw',
    },
}));
const CloseIcon = styled(Clear)(() => ({
    position: 'absolute',
    top: '2vw',
    right: '2vw',
    color: 'grey',
    cursor: 'pointer',
}));

const ImageModal: React.FC<Props> = ({ open, handleClose, image }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Container>
                <CloseIcon onClick={handleClose} />
                <img src={image} alt={image} />
            </Container>
        </Modal>
    );
};

export default ImageModal;
