import { gql } from '@apollo/client';

export const ORDER_SEARCH = gql`
    query posOrderList(
        $store_id: Int!
        $pageSize: Int!
        $currentPage: Int!
        $filter: OrderListFilterInput
    ) {
        posOrderList(
            store_id: $store_id
            pageSize: $pageSize
            currentPage: $currentPage
            filter: $filter
        ) {
            items {
                id
                number
                store {
                    id
                    label
                }
                order_date
                billing_address {
                    firstname
                    lastname
                    telephone
                }
                email
                total {
                    subtotal {
                        currency
                        value
                    }
                    grand_total {
                        currency
                        value
                    }
                }
                status {
                    id
                    label
                }
                comments {
                    message
                    timestamp
                }
                customer_note
            }
            page_info {
                total_pages
            }
            total_count
        }
    }
`;
