import React, {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from 'react';
import { defaultGridOption, GridOptionsReducer } from './GridOptionsReducer';

const GridOptionsContext = createContext({
    gridOption: defaultGridOption,
    setGridPage: (page: number) => {
        return;
    },
    setGridRowsPerPage: (rowsPerPage: number) => {
        return;
    },
    totalRows: 10,
    setTotalRows: (n: number) => {
        return;
    },
    reset: () => {
        return;
    },
});
export const useGridOptions = () => useContext(GridOptionsContext);

export const GridOptionsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [gridOption, dispatchOptions] = React.useReducer<
        typeof GridOptionsReducer
    >(GridOptionsReducer, defaultGridOption);
    const [totalRows, setTotalRows] = useState(10);
    const reset = useCallback(() => {
        dispatchOptions({ type: 'reset' });
    }, []);
    return (
        <GridOptionsContext.Provider
            value={{
                gridOption,
                totalRows,
                setTotalRows,
                setGridPage: (page) =>
                    dispatchOptions({ type: 'pageChange', page }),
                setGridRowsPerPage: (rowsPerPage) =>
                    dispatchOptions({ type: 'rowPerPageChange', rowsPerPage }),
                reset,
            }}
        >
            {children}
        </GridOptionsContext.Provider>
    );
};
