import React from 'react';
import { ProductProvider } from '../../components/ProductGrid/ProductProvider';

import { Typography } from '@mui/material';
import ProductGrid from '../../components/ProductGrid/ProductGrid';

const Products: React.FunctionComponent = () => {
    return (
        <ProductProvider>
            <Typography component="h1" variant="h1">
                Articles
            </Typography>
            <ProductGrid />
        </ProductProvider>
    );
};

export default Products;
