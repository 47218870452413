import React from 'react';

import { DeliveryTypes } from '../../services/delivery.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';

type Props = {
    selected: boolean;
    deliveryType: DeliveryTypes;
    label: string;
    handleSectionSelection: (deliveryType: DeliveryTypes) => void;
};

const DeliveryButton = styled(Fab)(() => ({
    zIndex: 100,
    height: '40px',
    marginLeft: '14px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body2.fontSize,
    '&:hover': {
        background: theme.palette.primary.main,
    },
}));
const AddIcon = styled(Add)(() => ({
    height: '23px',
    width: '23px',
}));

const TypeButton: React.FC<Props> = ({
    selected,
    deliveryType,
    label,
    handleSectionSelection,
}) => {
    return (
        <DeliveryButton
            variant="extended"
            sx={{
                background: selected ? theme.palette.secondary.main : '',
                color: selected ? theme.palette.secondary.contrastText : '',
                '&:hover': {
                    background: selected ? theme.palette.secondary.main : '',
                    color: selected ? theme.palette.secondary.contrastText : '',
                },
            }}
            onClick={() => {
                handleSectionSelection(deliveryType);
            }}
        >
            <AddIcon />
            {label}
        </DeliveryButton>
    );
};

export default TypeButton;
