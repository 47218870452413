import { APICustomerAddress } from './customer.service';
import apolloMutate from '../utils/apolloMutate';
import apolloQuery from '../utils/apolloQuery';
import { CART_SETDELIVERYMETHOD } from '../queries/CART_SETDELIVERYMETHOD_MUTATION';
import { LOCATIONS } from '../queries/LOCATIONS_QUERY';
import { APIProductItem, StockStatusEnum } from './products.service';
import { RegisterOptions } from 'react-hook-form';

export type LocationsData = {
    id: number;
    name: string;
    store_id: number;
    store_code: string;
    selected?: boolean;
};
export type Locations = Array<LocationsData>;

export type APIShippingMethod = {
    amount: {
        value: number;
    };
    carrier_code: string;
    carrier_title: string;
    delivery_points?: Array<APIDeliveryPoint> | null;
    delivery_point?: APIDeliveryPoint;
    method_code: string;
    method_title: string;
    shipping_method_type: DeliveryTypes;
};

export type APIShippingMethodsInput = {
    carrier_code: string;
    method_code: string;
    delivery_point_id?: number;
};

export type APIDeliveryPoint = {
    address?: string;
    id: number;
    city: string;
    country?: string;
    name: string;
    store_id: number;
    store_code: string;
};

export enum AddressRadioEnum {
    DEFAULT = 'default',
    NEW = 'new',
}

export type AddressRadio = AddressRadioEnum.DEFAULT | AddressRadioEnum.NEW;

export type AddressRadioMethods = {
    shipping: AddressRadio;
    billing: AddressRadio;
};

export type ShippingMethods = Array<APIShippingMethod>;

export type DeliveryAddressFields = {
    id: string;
    label: string;
    value: string | undefined;
};

export type DeliveryTypes =
    | 'TO_DELIVERY_POINT'
    | 'TO_CUSTOMER_ADDRESS'
    | 'none';

export const fetchLocations = (user: string): Promise<any> => {
    const queryOptions = {
        query: LOCATIONS,
        variables: {
            user: user,
        },
    };
    return apolloQuery(queryOptions);
};

export const findUniqueDeliveryTypes = (
    availableMethods: ShippingMethods
): Array<DeliveryTypes> => {
    const uniqueDeliveryTypes: Array<DeliveryTypes> = [];
    availableMethods.forEach((method: APIShippingMethod) => {
        const methodType = method.shipping_method_type;

        if (uniqueDeliveryTypes.includes(methodType)) {
            return;
        }

        uniqueDeliveryTypes.push(methodType);
    });

    return uniqueDeliveryTypes;
};

export const findMethodsByDeliveryType = (
    availableMethods: ShippingMethods,
    deliveryType: DeliveryTypes
): ShippingMethods => {
    const methods: ShippingMethods = [];
    availableMethods.forEach((method) => {
        const methodType = method.shipping_method_type;

        if (methodType !== deliveryType) {
            return;
        }

        methods.push(method);
    });

    return methods;
};

export const findLocationIDByStoreID = (
    availableMethods: ShippingMethods,
    storeID: number
): number | undefined => {
    let locationID = undefined;
    let deliveryPointsMethod: APIShippingMethod | undefined;

    availableMethods?.forEach((availableMethod: APIShippingMethod) => {
        if (availableMethod?.shipping_method_type == 'TO_DELIVERY_POINT') {
            deliveryPointsMethod = availableMethod;
            return;
        }
    });

    if (!deliveryPointsMethod) {
        return;
    }

    deliveryPointsMethod?.delivery_points?.forEach((deliveryPoint) => {
        const pointStoreID = deliveryPoint.store_id;

        if (pointStoreID !== storeID) {
            return;
        }

        locationID = deliveryPoint.id;
    });

    return locationID;
};

export const findDefaultCarrier = (
    methods: ShippingMethods,
    deliveryType: DeliveryTypes
): APIShippingMethodsInput | undefined => {
    let defaultCarrier: APIShippingMethodsInput | undefined;
    let isDefault = false;

    methods.forEach((method) => {
        switch (deliveryType) {
            case 'none':
                break;
            case 'TO_CUSTOMER_ADDRESS':
                if (
                    method.carrier_code !== 'colissimo' &&
                    method.method_code !== 'domicileas'
                ) {
                    isDefault = false;
                    break;
                }
                isDefault = true;
                break;
            case 'TO_DELIVERY_POINT':
                if (
                    method.carrier_code !== 'amstorepickup' &&
                    method.method_code !== 'amstorepickup'
                ) {
                    isDefault = false;
                    break;
                }
                isDefault = true;
                break;
        }

        if (!isDefault) {
            return;
        }

        defaultCarrier = {
            carrier_code: method.carrier_code,
            method_code: method.method_code,
        };
    });

    return defaultCarrier;
};

export const emptyDeliveryAddress = [
    { id: 'lastname', label: 'Nom', value: '', required: true },
    { id: 'firstname', label: 'Prénom', value: '', required: true },
    { id: 'street', label: 'Rue', value: '', required: true },
    {
        id: 'street2',
        label: 'Informations supplémentaires',
        value: '',
        required: false,
    },
    { id: 'postcode', label: 'Code postal', value: '', required: true },
    { id: 'city', label: 'Ville', value: '', required: true },
    { id: 'telephone', label: 'Téléphone', value: '', required: true },
    { id: 'company', label: 'Société', value: '', required: false },
];

export const formatDeliveryAddress = (
    address: APICustomerAddress
): Array<DeliveryAddressFields> => {
    return [
        { id: 'lastname', label: 'Nom', value: address.lastname },
        { id: 'firstname', label: 'Prénom', value: address.firstname },
        { id: 'street', label: 'Rue', value: address.street[0] },
        {
            id: 'street2',
            label: 'Informations supplémentaires',
            value: address.street[1],
        },
        { id: 'postcode', label: 'Code postal', value: address.postcode },
        { id: 'city', label: 'Ville', value: address.city },
        { id: 'telephone', label: 'Téléphone', value: address.telephone },
        { id: 'company', label: 'Société', value: address.company },
    ];
};

export const setDeliveryMethodOnCart = (
    storeID: number,
    cartID: string,
    methodCodes: APIShippingMethodsInput
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_SETDELIVERYMETHOD,
        variables: {
            store_id: storeID,
            cart_id: cartID,
            carrier_code: methodCodes.carrier_code,
            method_code: methodCodes.method_code,
            delivery_point_id: methodCodes.delivery_point_id,
        },
    };

    return apolloMutate(mutationOptions);
};

export const findDefaultDeliveryType = (
    items: Array<APIProductItem>
): DeliveryTypes => {
    for (let i = 0; i < items.length; i++) {
        const stockStatus = items[i]?.pos_stock?.status;

        switch (stockStatus as StockStatusEnum) {
            case StockStatusEnum.IN_STOCK_AT_LOCATION:
            case StockStatusEnum.OUT_OF_STOCK:
                break;
            case StockStatusEnum.PREORDERABLE_INFINITE:
            case StockStatusEnum.HAS_INCOMING_QUANTITIES:
            case StockStatusEnum.IN_STOCK_AT_DEFAULT_WAREHOUSE:
            case StockStatusEnum.IN_STOCK_ELSEWHERE:
                return 'TO_CUSTOMER_ADDRESS';
            default:
                break;
        }
    }

    return 'TO_DELIVERY_POINT';
};

export const getAddressFieldsRegisterOptions = (
    id: string,
    required: boolean
): RegisterOptions => {
    const validateNumberAndDefault = (
        len: number
    ): ((value: string) => string | undefined) => {
        return (value: string | undefined): string | undefined => {
            if (!value) {
                return;
            }
            if (value === 'n/r') {
                return;
            }
            if (value.length > len) {
                return `${len} nombres maximum`;
            }
            if (value.length < len) {
                return `${len}  nombres minimum`;
            }
            if (!/^[0-9]*$/i.test(value)) {
                return 'Seuls les nombres sont autorisés';
            }
        };
    };
    switch (id) {
        case 'telephone':
            return {
                required,
                validate: validateNumberAndDefault(10),
            };
        case 'postcode':
            return {
                required,
                validate: validateNumberAndDefault(5),
            };
        case 'email':
        default:
            return {
                required,
            };
    }
};
