import React from 'react';

import { UserContext } from '../../providers/UserProvider';
import {
    fetchCustomersAutocomplete,
    formatCustomersAutocomplete,
    SearchAutoComplete,
} from '../../services/customer.service';

import { Autocomplete, TextField } from '@mui/material';

type Props = {
    currentEmail: undefined | string;
    handleSelectCustomer: (customerID: string) => void;
    handleResetForm: () => void;
    autocompleteValues: SearchAutoComplete;
};

const SearchAutocomplete: React.FC<Props> = ({
    currentEmail,
    handleSelectCustomer,
    handleResetForm,
    autocompleteValues,
}) => {
    const { user } = React.useContext(UserContext);
    const [value, setValue] = React.useState<SearchAutoComplete | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const defaultOptions = [
        { label: 'exemple1@email.fr', id: '0' },
        { label: 'exemple2@email.fr', id: '1' },
    ];
    const [options, setOptions] =
        React.useState<Array<SearchAutoComplete>>(defaultOptions);
    const [loading, setLoading] = React.useState<boolean>(false);

    const resetValues = () => {
        setInputValue('');
        setValue({ label: '', id: '' });
    };

    React.useEffect(() => {
        let active = true;

        const delay = setTimeout(async () => {
            setLoading(true);

            fetchCustomersAutocomplete(user.storeId, inputValue)
                .then((result) => {
                    if (active) {
                        const formattedOptions = formatCustomersAutocomplete(
                            result.data.posCustomerList.items
                        );
                        setOptions(formattedOptions);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }, 250);

        return () => {
            clearTimeout(delay);
            active = false;
        };
    }, [user, inputValue]);

    React.useEffect(() => {
        if (!value?.label || !value?.id) return;
        if (value == autocompleteValues) return;
        if (currentEmail == value.label) {
            resetValues();
            return;
        }

        handleSelectCustomer(value.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    React.useEffect(() => {
        if (!autocompleteValues) return;

        if (!autocompleteValues?.label || !autocompleteValues?.id) {
            resetValues();
            handleResetForm();
            return;
        }

        setValue(autocompleteValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteValues]);

    return (
        <Autocomplete
            id="customer-search-autocomplete"
            filterOptions={(x) => x}
            options={options}
            loading={loading}
            value={value}
            onChange={(event: any, newValue) => {
                setValue(newValue);
            }}
            onBlur={() => {
                resetValues();
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Adresse mail"
                    variant="standard"
                />
            )}
            isOptionEqualToValue={() => {
                return true;
            }}
            noOptionsText="Aucun résultat"
        />
    );
};

export default SearchAutocomplete;
