import React from 'react';
import { useForm } from 'react-hook-form';

import { useSessionStorage } from '../../hooks/useSessionStorage';
import { LoginFormContext } from './LoginWizard';
import { UserContext } from '../../providers/UserProvider';

import { styled } from '@mui/material/styles';
import { theme } from '../../themeOptions';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { ErrorContext } from '../../providers/ErrorProvider';

type LoginFormValues = {
    username: string;
    password: string;
    location: string;
    apiError: string;
};

const LoginStepWrapper = styled(Box)(() => ({
    textAlign: 'left',
}));
const LoginStepForm = styled(Box)(() => ({
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));
const LoginStepTextField = styled(TextField)(() => ({
    height: '56px',
    width: '418px',
}));
const LoginStepSubmit = styled(Button)(() => ({
    height: '42px',
    width: '418px',
    marginTop: '32px',
}));
const LoginError = styled(Typography)(() => ({
    color: theme.palette.error.main,
    textAlign: 'left',
    marginTop: '16px',
}));
const LoginProgress = styled(CircularProgress)(() => ({
    height: '25px !important',
    width: '25px !important',
}));

export const LoginStep: React.FunctionComponent = () => {
    const context = React.useContext(LoginFormContext);
    const { generateToken } = React.useContext(UserContext);
    const { addError } = React.useContext(ErrorContext);

    const {
        register,
        handleSubmit,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm<LoginFormValues>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [, setToken] = useSessionStorage('token', null);
    const [, setUser] = useSessionStorage('user', null);

    return (
        <Box id="login-step">
            <LoginStepWrapper>
                <Typography component="h1" variant="h4">
                    Connexion
                </Typography>
                <Typography
                    component="p"
                    variant="body1"
                    sx={{ marginTop: '8px' }}
                >
                    Bonjour !
                </Typography>
            </LoginStepWrapper>
            <LoginStepForm
                component="form"
                onChange={() => clearErrors()}
                onSubmit={handleSubmit((loginData) => {
                    setIsLoading(true);

                    generateToken(loginData.username, loginData.password)
                        .then((tokenData: any) => {
                            setIsLoading(false);
                            if (tokenData?.errors?.length) return;

                            setToken(
                                tokenData.data.posGenerateSellerToken.token
                            );
                            setUser(loginData.username);
                            context.handleNext();
                        })
                        .catch((error) => {
                            addError({
                                id: crypto.randomUUID(),
                                title: 'Erreur réseau ou CORS',
                                description:
                                    'Veuillez vérifier votre connexion et vos paramètres réseau. ' +
                                    'Si le problème persiste, merci de contacter un développeur 1083.',
                            });
                            setIsLoading(false);
                            console.error(error);
                        });
                })}
                title="loginForm"
            >
                <LoginStepTextField
                    id="username"
                    label="Nom d'utilisateur"
                    variant="outlined"
                    sx={{ marginTop: '63px' }}
                    {...register('username', { required: true })}
                    {...(errors.username && { error: true })}
                />

                <LoginStepTextField
                    id="password"
                    label="Mot de passe"
                    variant="outlined"
                    type="password"
                    sx={{ marginTop: '16px' }}
                    {...register('password', { required: true })}
                    {...(errors.password && { error: true })}
                />

                <LoginStepSubmit
                    type="submit"
                    variant="contained"
                    disabled={
                        !getValues('username') ||
                        !getValues('password') ||
                        isLoading
                    }
                >
                    {isLoading ? <LoginProgress /> : 'CONNEXION'}
                </LoginStepSubmit>

                {errors.apiError && (
                    <LoginError variant="body2">
                        {errors.apiError.message}
                    </LoginError>
                )}
            </LoginStepForm>
        </Box>
    );
};
