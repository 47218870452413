const _events: any = {};

export enum Events {
    AUTHENTICATION_EXPIRED = 'authenticationExpired',
    GRAPHQL_ERROR = 'graphQLError',
}

export type EventCallBack = (data?: any) => void;

export const EventEmitter: any = {
    emit: (event: Events, data: any) => {
        if (!_events[event]) return;
        _events[event].forEach((callback: any) => callback(data));
    },

    subscribe: (event: Events, callback: EventCallBack) => {
        if (!_events[event]) _events[event] = [];
        _events[event].push(callback);
    },

    unsubscribe: (event: Events) => {
        if (!_events[event]) return;
        delete _events[event];
    },

    events: () => {
        return _events;
    },
};
