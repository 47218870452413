import { gql } from '@apollo/client';

export const CART_SETCUSTOMER = gql`
    mutation posAssignCartToCustomer(
        $store_id: Int!
        $cart_id: String!
        $customer_id: Int!
    ) {
        posAssignCartToCustomer(
            store_id: $store_id
            cart_id: $cart_id
            customer_id: $customer_id
        ) {
            customer {
                default_billing
                default_shipping
            }
        }
    }
`;

export const CART_SETGUESTCUSTOMER = gql`
    mutation posSetGuestDataOnCart(
        $cart_id: String!
        $firstname: String
        $lastname: String
        $email: String!
        $telephone: String
        $company: String
    ) {
        posSetGuestDataOnCart(
            input: {
                cart_id: $cart_id
                firstname: $firstname
                lastname: $lastname
                email: $email
                telephone: $telephone
                company: $company
            }
        ) {
            cart {
                id
            }
        }
    }
`;
