import React from 'react';

import { CartContext } from '../../providers/CartProvider';
import { APICart } from '../../services/cart.service';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import CartTab from './CartTab';
import CartAddButton from '../CartAddButton/CartAddButton';

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row-reverse',
}));

const CartList: React.FC = () => {
    const { carts } = React.useContext(CartContext);

    return (
        <Container>
            {!!carts?.size &&
                Array.from(carts).map(
                    (cartMap: [string, APICart], cartNumber) => (
                        <CartTab
                            key={cartNumber}
                            cartNumber={cartNumber}
                            cartID={cartMap[0]}
                            cartsSize={carts.size}
                        />
                    )
                )}

            <CartAddButton />
        </Container>
    );
};

export default CartList;
