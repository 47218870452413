import React from 'react';
import { APIStock } from '../../services/products.service';
import { Box, Typography } from '@mui/material';

type Props = {
    stockInfo: APIStock;
};

export const StockStatus: React.FC<Props> = ({ stockInfo }) => {
    return (
        <Box>
            <Typography>{stockInfo?.status_label ?? 'n/r'}</Typography>
        </Box>
    );
};

export default StockStatus;
