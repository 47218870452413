import React from 'react';

import {
    AddressTypes,
    CustomerFormTypesEnum,
} from '../../services/customer.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

type Props = {
    isDisabled: boolean;
    addressType: AddressTypes;
};

const StyledButton = styled(Button)(() => ({
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    marginTop: '20px',
}));

const SubmitButton: React.FC<Props> = ({ isDisabled, addressType }) => {
    const sx = {
        background: isDisabled ? theme.palette.action.disabledBackground : '',
        color: isDisabled ? theme.palette.action.disabled : '',
        '&:hover': {
            background: isDisabled
                ? theme.palette.action.disabledBackground
                : '',
            color: isDisabled ? theme.palette.action.disabled : '',
        },
    };

    return (
        <StyledButton sx={sx} disabled={isDisabled} type="submit">
            Valider
            {addressType == CustomerFormTypesEnum.SHIPPING
                ? ' les adresses de livraison et facturation'
                : " l'adresse de facturation"}
        </StyledButton>
    );
};

export default SubmitButton;
