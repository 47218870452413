import React from 'react';

import { CartContext } from '../../providers/CartProvider';
import {
    fetchProductsAutocomplete,
    formatProductsAutocomplete,
    ProductAutocomplete,
} from '../../services/products.service';

import { styled } from '@mui/material/styles';
import { Autocomplete, Box, TextField } from '@mui/material';

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
        height: '40px',
        width: '290px',
        padding: '0 5px',
    },
    '& .MuiAutocomplete-input .MuiAutocomplete-input input': {
        padding: 0,
    },
}));
const SearchField = styled(TextField)(() => ({
    height: '40px',
    width: '290px',
}));

const QuickProductSearch: React.FC = () => {
    const { addProduct, activeCart } = React.useContext(CartContext);
    const [value, setValue] = React.useState<ProductAutocomplete | null>(null);
    const [inputValue, setInputValue] = React.useState<string>('');
    const defaultOptions = [{ label: '', id: '0' }];
    const [options, setOptions] =
        React.useState<Array<ProductAutocomplete>>(defaultOptions);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        let active = true;

        const delay = setTimeout(async () => {
            if (!inputValue) return;
            setLoading(true);

            fetchProductsAutocomplete(inputValue)
                .then((result) => {
                    if (active) {
                        const formattedOptions = formatProductsAutocomplete(
                            result.data.posProductSearch?.items
                        );
                        setOptions(formattedOptions);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }, 250);

        return () => {
            clearTimeout(delay);
            active = false;
        };
    }, [inputValue]);

    React.useEffect(() => {
        if (!value?.label || !value?.id) {
            return;
        }

        addProduct(value.id, activeCart);
        setValue(null);
        setInputValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Container>
            <Autocomplete
                id="product-quicksearch-autocomplete"
                filterOptions={(x) => x}
                loading={loading}
                options={options}
                value={value}
                onChange={(event: any, newValue) => {
                    setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event: any, newInputValue: string) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <SearchField
                        {...params}
                        label="Référence"
                        variant="outlined"
                    />
                )}
                isOptionEqualToValue={() => {
                    return true;
                }}
                noOptionsText="Aucun résultat"
                loadingText="Référence / Nom de l'article"
            />
        </Container>
    );
};

export default QuickProductSearch;
