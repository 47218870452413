import React from 'react';
import { useFormContext } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import { FormControlLabel, Radio } from '@mui/material';

import {
    AddressTypes,
    CustomerFormTypesEnum,
} from '../../services/customer.service';

type Props = {
    addressType: AddressTypes;
    addressSelected: 'primary' | 'secondary';
    BiS?: boolean;
    onClickHandler: () => void;
};

const StyledRadio = styled(FormControlLabel)(() => ({
    margin: '20px 0 0 20px',
}));

export const CustomerSelectAddressRadio: React.FC<Props> = ({
    addressType,
    addressSelected,
    BiS,
    onClickHandler,
}) => {
    const { register } = useFormContext();

    return (
        <StyledRadio
            value={addressSelected}
            control={
                <Radio
                    disabled={
                        !!(addressType == CustomerFormTypesEnum.BILLING && BiS)
                    }
                    {...register(addressType + 'form', { required: false })}
                />
            }
            label={
                addressSelected == 'primary'
                    ? 'Adresse principale'
                    : 'Adresse secondaire'
            }
            onClick={onClickHandler}
        />
    );
};
