import { gql } from '@apollo/client';

export const PRODUCT_SEARCH = gql`
    query products(
        $pageSize: Int!
        $currentPage: Int!
        $sort: ProductAttributeSortInput
        $filter: ProductAttributeFilterInput
    ) {
        posProductFilter(
            filter: $filter
            pageSize: $pageSize
            currentPage: $currentPage
            sort: $sort
        ) {
            items {
                uid
                bc_barcode
                name
                sku
                annee
                annee__detail {
                    id
                    label
                }
                collection_de_mode
                collection_de_mode__detail {
                    id
                    label
                }
                couleur
                couleur__detail {
                    id
                    label
                }
                final_price {
                    value
                    currency
                }
                manufacturer
                manufacturer__detail {
                    id
                    label
                }
                pos_stock {
                    delay_information
                    global_qty
                    incoming_date
                    incoming_qty
                    local_qty
                    status
                    status_label
                    summary {
                        code
                        incoming_date
                        incoming_qty
                        name
                        preorder_date
                        qty
                    }
                }
                saison
                saison__detail {
                    id
                    label
                }
                sexe
                sexe__detail {
                    items {
                        id
                        label
                    }
                }
                stock_status
                image {
                    label
                    url
                }
                thumbnail {
                    label
                    url
                }
                type_id
            }
            page_info {
                total_pages
            }
            total_count
        }
    }
`;

export const PRODUCT_QUICKSEARCH = gql`
    query posProductSearch(
        $pageSize: Int!
        $currentPage: Int!
        $sort: ProductAttributeSortInput
        $search: String!
    ) {
        posProductSearch(
            search: $search
            pageSize: $pageSize
            currentPage: $currentPage
            sort: $sort
        ) {
            items {
                bc_barcode
                name
                sku
                stock_status
            }
        }
    }
`;
