import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icon } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';

import AddToCartIcon from '../../icons/add-to-cart.svg';
import CustomSvgIcon from '../CustomSvgIcon/CustomSvgIcon';
import { ButtonIconContainer } from '../ButtonIconContainer/ButtonIconContainer';

type Props = {
    params: GridCellParams;
};

export const ButtonAddCustomer: React.FC<Props> = ({ params }) => {
    const history = useHistory();

    return (
        <ButtonIconContainer
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();

                history.push('/#area-customer', {
                    id: params.row.id,
                    email: params.row.email,
                    lastname: params.row.lastname,
                    firstname: params.row.firstname,
                    telephone: params.row.telephone,
                    company: params.row.company,
                    is_subscribed: params.row.is_subscribed,
                });
            }}
        >
            <Icon>
                <CustomSvgIcon src={AddToCartIcon} height={24} width={24} />
            </Icon>
        </ButtonIconContainer>
    );
};

export default ButtonAddCustomer;
