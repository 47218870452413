import { gql } from '@apollo/client';

export const ORDER_QUERY = gql`
    query posOrder($order_number: String!) {
        posOrder(order_number: $order_number) {
            id
            email
            company
            firstname
            lastname
            telephone
            rma_url
            composite_payment {
                total
                payments {
                    code
                    amount
                    label
                }
            }
            applied_gift_cards {
                gift_cards {
                    code
                    initial_amount {
                        value
                        currency
                    }
                    current_amount {
                        value
                        currency
                    }
                    applied_amount {
                        value
                        currency
                    }
                    expiration_date
                }
                total_amount {
                    value
                }
            }
            shipping_address {
                firstname
                lastname
                company
                street
                city
                region
                postcode
                telephone
                country {
                    id
                    label
                }
            }

            billing_address {
                firstname
                lastname
                company
                street
                city
                region
                postcode
                telephone
                country {
                    id
                    label
                }
            }
            customer {
                id
                company
                default_billing
                default_shipping
                email
                firstname
                lastname
                is_subscribed
                telephone
                created_at
            }
            items {
                availability
                availability_label
                delivery_delay
                discounts {
                    amount {
                        value
                        currency
                    }
                    label
                }
                custom_discount_percent
                id
                product_name
                product_sale_price {
                    value
                    currency
                }
                product_original_price {
                    value
                    currency
                }
                product_original_price_including_tax {
                    value
                    currency
                }
                product_sku
                product_type
                quantity_invoiced
                quantity_ordered
                quantity_shipped
                selected_options {
                    label
                    value
                }
                status
                tax_percent
                tax_amount
            }
            carrier
            number
            order_date
            order_origin
            shipping_method
            status {
                id
                label
            }
            store {
                id
                label
            }
            total {
                base_grand_total {
                    currency
                    value
                }
                discounts {
                    amount {
                        currency
                        value
                    }
                    label
                }
                grand_total {
                    currency
                    value
                }
                shipping_handling {
                    amount_excluding_tax {
                        currency
                        value
                    }
                    amount_including_tax {
                        currency
                        value
                    }
                    discounts {
                        amount {
                            currency
                            value
                        }
                    }
                    taxes {
                        amount {
                            currency
                            value
                        }
                        rate
                        title
                    }
                    total_amount {
                        currency
                        value
                    }
                }
                subtotal {
                    currency
                    value
                }
                taxes {
                    amount {
                        currency
                        value
                    }
                    rate
                    title
                }
                total_shipping {
                    currency
                    value
                }
                total_tax {
                    currency
                    value
                }
            }
        }
    }
`;
