import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icon } from '@mui/material';
import { CustomerContext } from './CustomerProvider';

import { GridCellParams } from '@mui/x-data-grid';

import EditIcon from '../../icons/edit.svg';
import CustomSvgIcon from '../CustomSvgIcon/CustomSvgIcon';
import { ButtonIconContainer } from '../ButtonIconContainer/ButtonIconContainer';

type Props = {
    params: GridCellParams;
};

export const ButtonEditCustomer: React.FC<Props> = ({ params }) => {
    const history = useHistory();
    const { customerColumnFilters } = React.useContext(CustomerContext);

    return (
        <ButtonIconContainer
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                history.push('/fiche-client', {
                    email: params.row.email,
                    gridKeepPagination: true,
                    filters: { customer: customerColumnFilters },
                });
            }}
        >
            <Icon>
                <CustomSvgIcon src={EditIcon} height={24} width={24} />
            </Icon>
        </ButtonIconContainer>
    );
};

export default ButtonEditCustomer;
