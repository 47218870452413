import React, { Dispatch, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { CartContext } from '../../providers/CartProvider';
import {
    CartFormValues,
    formatCartCustomerInfo,
} from '../../services/customer.service';
import { PosError } from '../../services/cart.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
} from '@mui/material';
import CartCustomerSubmit from './CartCustomerSubmit';
import timestampEmail from '../../utils/timestampEmail';
import { getAddressFieldsRegisterOptions } from '../../services/delivery.service';

type Props = {
    isNewCustomer: boolean;
    hasNewCustomer: boolean;
    setHasNewCustomer: Dispatch<boolean>;
    customerValues: CartFormValues | null;
};

const FormContainer = styled('form')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
}));
const StyledTextFieldContainer = styled(Box)(() => ({
    display: 'flex',
    paddingLeft: '12px',
}));
const StyledTextField = styled(TextField)(() => ({
    height: '40px',
    width: '100%',
    margin: '20px 24px',
}));
const GenerateEmailContainer = styled(Box)(() => ({
    marginLeft: '36px',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));
const GenerateEmailButton = styled(Button)(() => ({
    height: '36px',
    width: '130px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
/*const SubscriptionControl = styled(FormControlLabel)(() => ({
    marginTop: '40px',
    marginLeft: '15px',
    textAlign: 'left',
}));*/
const defaultCustomerValue: CartFormValues = {
    firstname: '',
    lastname: '',
    email: '',
    telephone: '',
    company: '',
    // is_subscribed: false,
};
export const CartCustomerForm: React.FC<Props> = ({
    isNewCustomer,
    hasNewCustomer,
    setHasNewCustomer,
    customerValues,
}) => {
    const { activeCart, assignCustomer, carts, updateCustomerInfo } =
        React.useContext(CartContext);
    const [isGenerated, setIsGenerated] = useState<boolean>(false);
    /*const [subscribed, setSubscribed] = useState<boolean>(
        customerValues?.is_subscribed || false
    );*/
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        setFocus,
        formState: { errors, isDirty },
    } = useForm();
    useEffect(() => {
        if (isNewCustomer) {
            reset(defaultCustomerValue);
            return;
        }
        // setSubscribed(customerValues?.is_subscribed || false);
        reset(customerValues || defaultCustomerValue);
    }, [isNewCustomer, customerValues, reset]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleEmailFocus = () => {
        setFocus('email');
    };

    return (
        <FormContainer
            id="cartCustomerForm"
            onSubmit={handleSubmit(
                (data) => {
                    const email = data?.email ?? carts.get(activeCart)?.email;

                    if (!email) return;
                    if (isNewCustomer || hasNewCustomer) {
                        clearErrors();
                        setIsLoading(true);
                        setHasNewCustomer(true);
                        assignCustomer(activeCart, undefined, {
                            email,
                            firstname: data?.firstname,
                            lastname: data?.lastname,
                            telephone: data?.telephone
                                ? data?.telephone
                                : 'n/r',
                            company: data?.company,
                            // is_subscribed: subscribed,
                        }).then((assignError: PosError | void) => {
                            setIsLoading(false);
                            if (!assignError?.message) return;
                            const formattedErrorMessages = [
                                {
                                    error: 'Error loading customer through email',
                                    message: 'Adresse email déjà utilisée',
                                },
                                {
                                    error: 'Invalid email format',
                                    message:
                                        "Le format de l'adresse email est invalide",
                                },
                            ];
                            formattedErrorMessages.forEach(
                                ({ error, message }) => {
                                    if (!assignError.message.includes(error))
                                        return;
                                    setError('email', { message });
                                }
                            );
                        });
                        return;
                    }

                    const customerID = carts.get(activeCart)?.customer?.id;
                    if (!customerID) return;

                    setIsLoading(true);
                    updateCustomerInfo(activeCart, customerID, {
                        email,
                        firstname: data?.firstname,
                        lastname: data?.lastname,
                        telephone: data?.telephone ? data?.telephone : 'n/r',
                        company: data?.company,
                        // is_subscribed: data?.is_subscribed,
                    }).then(() => setIsLoading(false));
                },
                (errors) => {
                    console.error('invalid', errors);
                }
            )}
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                formatCartCustomerInfo(
                    customerValues || defaultCustomerValue
                ).map((info, index) => {
                    if (info.id === 'is_subscribed') {
                        return '';
                    }

                    return (
                        <StyledTextFieldContainer
                            key={info.id}
                            sx={{
                                width: info.width !== '' ? info.width : '50%',
                                order: index * 10,
                            }}
                        >
                            {{
                                email: (
                                    <StyledTextField
                                        disabled={
                                            isNewCustomer
                                                ? isGenerated
                                                : info.disabled
                                        }
                                        label={info.label}
                                        type={info.type}
                                        required={info.required ?? false}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register('email', {
                                            required: info.required,
                                        })}
                                        {...(errors.email && { error: true })}
                                        helperText={
                                            errors?.email?.message?.toString() ??
                                            null
                                        }
                                    />
                                ),

                                /*is_subscribed: (
                                    <SubscriptionControl
                                        disabled={info.disabled}
                                        control={
                                            <Checkbox
                                                onChange={() =>
                                                    setSubscribed(
                                                        (prev) => !prev
                                                    )
                                                }
                                                checked={subscribed}
                                            />
                                        }
                                        label={
                                            'Abonnement à la Newsletter Modetic '
                                        }
                                        onChange={() =>
                                            setSubscribed((prev) => !prev)
                                        }
                                    />
                                ),*/

                                telephone: (
                                    <StyledTextField
                                        disabled={info.disabled}
                                        label={info.label}
                                        type={info.type ?? 'text'}
                                        required={info.required ?? false}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register(
                                            'telephone',
                                            getAddressFieldsRegisterOptions(
                                                'telephone',
                                                info.required ?? false
                                            )
                                        )}
                                        {...(errors.telephone && {
                                            error: true,
                                        })}
                                        helperText={
                                            errors?.telephone?.message?.toString() ??
                                            null
                                        }
                                    />
                                ),
                            }[info.id] || (
                                <StyledTextField
                                    disabled={info.disabled}
                                    label={info.label}
                                    type={info.type ?? 'text'}
                                    required={info.required ?? false}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...register(info.id.toString() as any, {
                                        required: info.required ?? false,
                                    })}
                                />
                            )}
                        </StyledTextFieldContainer>
                    );
                })
            )}
            {isNewCustomer && !isLoading && (
                <GenerateEmailContainer sx={{ order: 25 }}>
                    <GenerateEmailButton
                        onClick={() => {
                            setValue(
                                'email',
                                isGenerated ? '' : timestampEmail()
                            );
                            setIsGenerated(!isGenerated);
                            handleEmailFocus();
                        }}
                    >
                        {isGenerated ? 'RETIRER @' : 'GÉNÉRER @'}
                    </GenerateEmailButton>
                </GenerateEmailContainer>
            )}
            {(isDirty || isGenerated) && (
                <CartCustomerSubmit
                    isLoading={isLoading}
                    enabled={isDirty || isGenerated}
                />
            )}
        </FormContainer>
    );
};

export default CartCustomerForm;
