import React from 'react';

import { CartContext } from '../../providers/CartProvider';

import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

import CartContent from '../CartContent/CartContent';
import CodesForm from './CodesForm';
import CodesList from './CodesList';
import QuickProductSearch from './QuickProductSearch';
import AdvancedProductSearch from './AdvancedProductSearch';

const CartContentSurround = styled(Paper)(() => ({
    height: '67px',
    paddingLeft: '28px',
    paddingRight: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
const CartContentContainer = styled(Box)(() => ({
    margin: '1px 0',
    background: '#fcfcfc',
}));
const GiftCardsContainer = styled(Box)(() => ({
    width: '100%',
    marginTop: '1px',
    padding: '25px 44px',
    background: 'white',
}));

export const CartProductsArea: React.FC = () => {
    const { carts, activeCart } = React.useContext(CartContext);
    const currentCart = carts.get(activeCart);
    const couponCodes = currentCart?.applied_coupons
        ? [...currentCart.applied_coupons]
        : [];
    const giftCards = currentCart?.applied_gift_cards?.gift_cards
        ? [...currentCart.applied_gift_cards.gift_cards]
        : [];

    return (
        <Box sx={{ width: '100%' }}>
            <CartContentSurround elevation={1}>
                <QuickProductSearch />

                <AdvancedProductSearch />
            </CartContentSurround>

            <CartContentContainer>
                <CartContent />
            </CartContentContainer>

            <CartContentSurround elevation={1} sx={{ height: '75px' }}>
                <CodesForm />

                {carts && activeCart && currentCart?.prices ? (
                    <Typography>
                        {currentCart.prices.grand_total.value.toString() + ' €'}
                    </Typography>
                ) : null}
            </CartContentSurround>

            {giftCards.length || couponCodes.length ? (
                <GiftCardsContainer>
                    <CodesList
                        giftCards={giftCards}
                        couponCodes={couponCodes}
                    />
                </GiftCardsContainer>
            ) : null}
        </Box>
    );
};

export default CartProductsArea;
