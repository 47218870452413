import React from 'react';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import LoginWizard from '../../components/LoginWizard/LoginWizard';

const Container = styled(Box)(() => ({
    height: '100%',
    width: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const ImageContainer = styled('img')(() => ({
    height: '100vh',
    maxWidth: '50%',

    objectFit: 'cover',
    overflow: 'hidden',
}));
const ContentContainer = styled(Box)(() => ({
    width: '50%',
}));

const Login: React.FunctionComponent = () => {
    return (
        <Container>
            <ImageContainer
                src={
                    process.env.REACT_APP_GRAPHQL_HOST + 'media/pos-banner.jpg'
                }
            />

            <ContentContainer>
                <LoginWizard />
            </ContentContainer>
        </Container>
    );
};

export default Login;
