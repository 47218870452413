import React from 'react';

import { styled } from '@mui/material/styles';

const CustomSvgIcon = styled('img')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export default CustomSvgIcon;
