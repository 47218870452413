import React from 'react';

import { CartContext } from '../../providers/CartProvider';
import { UserContext } from '../../providers/UserProvider';
import {
    checkHasPaidTotal,
    checkIsSamePayment,
    convertPaymentsMapToArrayOfUsedOptions,
    fetchPaymentOptions,
    FinalPaymentMethodsMap,
    MapPaymentMethod,
    shouldResetPayments,
    updatePaidTotal,
    updatePaymentOptions,
} from '../../services/payment.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

import FoldButton from './FoldButton';
import PaymentOptions from './PaymentOptions';
import DueForm from './DueForm';
import TotalsSummary from './TotalsSummary';

const Container = styled(Paper)(() => ({
    width: '100%',
    padding: '18px 23px',
    marginTop: '14px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    background: '#fcfcfc',
}));
const Header = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fcfcfc',
}));
const SectionTitleContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

const SectionTitle = styled(Typography)(() => ({
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
}));
const PaymentContent = styled(Box)(() => ({
    width: '100%',
    marginTop: '35px',
    marginBottom: '22px',
    paddingLeft: '76px',
    paddingRight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    transition: '.2s ease-in-out',
}));

const CartPaymentArea: React.FC = () => {
    const { user } = React.useContext(UserContext);
    const { carts, activeCart, addPayment, resetPayments } =
        React.useContext(CartContext);
    const [folded, setFolded] = React.useState<boolean>(true);
    const [paymentOptions, setPaymentOptions] =
        React.useState<FinalPaymentMethodsMap>();
    const [currentOption, setCurrentOption] = React.useState<string>('');
    const [dueRemaining, setDueRemaining] = React.useState<number>(0);
    const [paidTotal, setPaidTotal] = React.useState<number>(0);
    const [hasPaidTotal, setHasPaidTotal] = React.useState<boolean>(false);

    const currentCart = carts.get(activeCart);
    const hasProducts = currentCart?.items?.length;
    const hasCustomer = currentCart?.email;
    const hasShipping = currentCart?.shipping_addresses?.length;
    const hasBilling = currentCart?.billing_address;
    const hasSelectedShippingMethod =
        currentCart?.shipping_addresses[0]?.selected_shipping_method;
    const isUnfoldable =
        hasProducts &&
        hasCustomer &&
        hasShipping &&
        hasBilling &&
        hasSelectedShippingMethod;

    const awaitPaymentOptions = async () => {
        await fetchPaymentOptions(user.storeId).then((paymentOptions) => {
            if (!paymentOptions) {
                return;
            }

            setPaymentOptions(paymentOptions);
        });
    };
    const handleFoldToggle = () => {
        if (!isUnfoldable) return;
        setFolded((prevstate) => !prevstate);
    };
    const handlePaymentSelection = (code: string) => {
        if (!paymentOptions || paymentOptions.get(code)?.used) return;

        setCurrentOption(code);
    };
    const handleDueFormValidation = (amount: number) => {
        const prevOption = paymentOptions?.get(currentOption);
        if (!prevOption || !paymentOptions) return;

        setPaymentOptions(
            new Map(
                paymentOptions.set(currentOption, {
                    label: prevOption.label,
                    code: prevOption.code,
                    used: true,
                    amount: amount * 100,
                })
            )
        );
    };
    const handleDeletePaidItem = (item: MapPaymentMethod) => {
        const prevOption = paymentOptions?.get(item.code);
        if (!prevOption || !paymentOptions) return;

        setPaymentOptions(
            new Map(
                paymentOptions.set(item.code, {
                    label: prevOption.label,
                    code: prevOption.code,
                    used: false,
                    amount: 0,
                })
            )
        );
        setHasPaidTotal(false);
    };

    React.useEffect(() => {
        if (!currentCart) return;

        if (!currentCart?.composite_payment?.length) {
            awaitPaymentOptions();
            setFolded(true);
            return;
        }
        const compositePayments = currentCart.composite_payment[0].payments;
        fetchPaymentOptions(user.storeId).then(
            (APIPaymentOptions: FinalPaymentMethodsMap) => {
                const newMap = updatePaymentOptions(
                    compositePayments,
                    APIPaymentOptions
                );
                if (!newMap) return;

                setPaymentOptions(newMap);
                setFolded(false);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCart, carts]);

    React.useEffect(() => {
        const currentCart = carts.get(activeCart);
        if (!currentCart || !paymentOptions) return;

        setPaidTotal(Math.round(updatePaidTotal(paymentOptions)));
        if (shouldResetPayments(currentCart, paymentOptions))
            resetPayments(activeCart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentOptions, activeCart]);

    React.useEffect(() => {
        const currentCart = carts.get(activeCart);
        if (!currentCart) return;

        const grandTotal = Math.round(
            currentCart.prices.grand_total.value * 100
        );
        setDueRemaining(Math.round(grandTotal - paidTotal));
        setHasPaidTotal(checkHasPaidTotal(paidTotal, grandTotal));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paidTotal, carts, activeCart]);

    React.useEffect(() => {
        if (!hasPaidTotal) return;

        const paymentMethods =
            convertPaymentsMapToArrayOfUsedOptions(paymentOptions);
        if (!paymentMethods || !paymentMethods.length) return;

        if (carts.get(activeCart)?.composite_payment?.length) {
            const isSamePayment = checkIsSamePayment(
                carts.get(activeCart).composite_payment[0].payments,
                paymentMethods
            );
            if (isSamePayment) return;
        }

        addPayment(activeCart, paymentMethods, paymentOptions);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPaidTotal]);

    return (
        <Container>
            <Header onClick={handleFoldToggle}>
                <SectionTitleContainer>
                    <SectionTitle>MODE DE PAIEMENT</SectionTitle>
                </SectionTitleContainer>

                <FoldButton folded={folded} />
            </Header>

            {!folded && (
                <PaymentContent>
                    <PaymentOptions
                        handlePaymentSelection={handlePaymentSelection}
                        paymentOptions={paymentOptions}
                        currentOption={currentOption}
                    />

                    <DueForm
                        amount={dueRemaining}
                        currentOption={currentOption}
                        handleDueFormValidation={handleDueFormValidation}
                        hasPaidTotal={hasPaidTotal}
                    />

                    <TotalsSummary
                        paymentOptions={paymentOptions}
                        dueRemaining={dueRemaining}
                        paidTotal={paidTotal}
                        handleDeletePaidItem={handleDeletePaidItem}
                    />
                </PaymentContent>
            )}
        </Container>
    );
};

export default CartPaymentArea;
