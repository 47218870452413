import React from 'react';
import { UserContext } from '../../providers/UserProvider';
import { Box } from '@mui/material';
import { LoginStep } from './LoginStep';
import { StoreSelectionWrapper } from './StoreSelectionWrapper';

export type LoginFormState = {
    handleNext: () => void;
    handleBack: () => void;
    user: string;
    activeStep: number;
};

export const LoginFormContext = React.createContext({
    handleNext: () => {
        return;
    },
    handleBack: () => {
        return;
    },
    user: '',
    activeStep: 0,
});

export const LoginWizard: React.FunctionComponent = () => {
    const { logout } = React.useContext(UserContext);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        logout();
    };

    const state: LoginFormState = {
        handleNext,
        handleBack,
        user: '',
        activeStep,
    };

    return (
        <LoginFormContext.Provider value={state}>
            <Box sx={{ marginLeft: '93px' }}>
                {activeStep === 0 ? <LoginStep /> : <StoreSelectionWrapper />}
            </Box>
        </LoginFormContext.Provider>
    );
};

export default LoginWizard;
