import React from 'react';
import { useHistory } from 'react-router-dom';

import { OrderContext } from './OrderProvider';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

type Props = {
    orderNumber: string;
};

const StyledButton = styled(Button)(() => ({
    height: '30px',
    width: '130px',
    background: 'transparent',
    color: theme.palette.primary.main,
}));
const ArrowIcon = styled(ArrowForward)(() => ({
    height: '18px',
    width: '18px',
    marginLeft: '8px',
}));

export const ButtonShowOrder: React.FC<Props> = ({ orderNumber }) => {
    const history = useHistory();
    const { orderColumnFilters } = React.useContext(OrderContext);

    return (
        <StyledButton
            variant="outlined"
            onClick={(e) => {
                e.stopPropagation();
                history.push('/recapitulatif', {
                    type: 'order',
                    id: orderNumber,
                    filters: {
                        order: orderColumnFilters,
                    },
                });
            }}
        >
            CONSULTER
            <ArrowIcon />
        </StyledButton>
    );
};

export default ButtonShowOrder;
