import { gql } from '@apollo/client';

export const PRODUCT_UNSETPRICE = gql`
    mutation posUnapplyFreePrice($cart_id: String!, $barcode: String!) {
        posUnapplyFreePrice(
            input: { cart_id: $cart_id, bc_barcode: $barcode }
        ) {
            cart {
                id
            }
        }
    }
`;
