import { gql } from '@apollo/client';

export const CART_ADDPRODUCT_MUTATION = gql`
    mutation posAddProductsToCart(
        $store_id: Int!
        $cart_id: String!
        $bc_barcode: String!
        $quantity: Float!
    ) {
        posAddProductsToCart(
            store_id: $store_id
            cart_id: $cart_id
            cart_items: [{ bc_barcode: $bc_barcode, quantity: $quantity }]
        ) {
            cart {
                id
                items {
                    uid
                    product {
                        bc_barcode
                        sku
                        name
                        uid
                        id
                        type_id
                    }
                    pos_stock {
                        delay_information
                        global_qty
                        incoming_date
                        incoming_qty
                        local_qty
                        status
                        summary {
                            code
                            incoming_date
                            incoming_qty
                            name
                            preorder_date
                            qty
                        }
                    }
                }
            }
        }
    }
`;
