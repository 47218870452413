import React from 'react';

import { APIPaymentMethodInput } from '../../services/payment.service';
import { CardOrCoupons } from '../../services/giftcard.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type Props = {
    payments: Array<APIPaymentMethodInput> | undefined;
    paidTotal: number | undefined;
    cardsAndCoupons?: CardOrCoupons;
};

const SummaryContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '21px',
    padding: '18px 26px',
    background: '#f9f9f9',
    borderRadius: '4px',
}));
const PaidList = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
const PaidLine = styled(Box)(() => ({
    height: '41px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0',
    padding: '0 24px',
    background: 'rgba(47, 43, 153, 0.08)',
    borderRadius: '60px',
}));
const Line = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0',
    padding: '0 24px',
}));

const RecapPayments: React.FC<Props> = ({
    payments,
    paidTotal,
    cardsAndCoupons,
}) => {
    return (
        <>
            <SummaryContainer>
                {!!cardsAndCoupons &&
                    cardsAndCoupons.map((cardOrCoupon, index) => {
                        return (
                            <Line
                                sx={{ color: theme.palette.error.main }}
                                key={index}
                            >
                                <Typography variant={'subtitle2'}>
                                    Code : {cardOrCoupon?.code}
                                </Typography>
                                <Typography variant={'subtitle2'}>
                                    -
                                    {cardOrCoupon &&
                                        cardOrCoupon?.applied_amount?.value}
                                    €
                                </Typography>
                            </Line>
                        );
                    })}

                <PaidList>
                    {payments &&
                        payments.map((payment, index) => {
                            return (
                                <PaidLine key={index}>
                                    <Typography variant={'subtitle2'}>
                                        Payé par {payment?.label} :
                                    </Typography>
                                    <Typography variant={'subtitle2'}>
                                        {payment?.amount}€
                                    </Typography>
                                </PaidLine>
                            );
                        })}
                </PaidList>
            </SummaryContainer>

            <Line sx={{ padding: '0 50px' }}>
                <Typography variant={'subtitle2'}>Total payé TTC :</Typography>
                <Typography variant={'subtitle2'}>{paidTotal ?? 0}€</Typography>
            </Line>
        </>
    );
};

export default RecapPayments;
