import React from 'react';

import {
    GridCellParams,
    GridColDef,
    GridPinnedColumns,
    GridRenderCellParams,
} from '@mui/x-data-grid-pro';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import {
    dropdownRenderHeader,
    numberRangeRenderHeader,
    textRenderHeader,
} from '../Filters/RenderFilters';
import ProductImage from './ProductImage';
import ButtonAddProduct from './ButtonAddProduct';
import ProductStockInfo from './ProductStockInfo';
import ProductStockChip from './ProductStockChip';

export const productColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        width: 90,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
    },
    {
        field: 'image',
        headerName: 'Image',
        width: 90,
        hideSortIcons: true,
        sortable: false,
        resizable: false,
        renderCell: (params: GridRenderCellParams): React.ReactElement => {
            const Container = styled(Box)(() => ({
                height: '100%',
                width: '100%',
            }));

            return (
                <Container>
                    <ProductImage
                        smallImage={params.row.image}
                        image={params.row.image_modal}
                    />
                </Container>
            );
        },
    },
    { field: 'image_modal', hide: true },
    {
        field: 'sku',
        headerName: 'SKU',
        width: 300,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'product'),
    },
    {
        field: 'name',
        headerName: 'Nom',
        width: 400,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'product'),
    },
    {
        field: 'barcode',
        headerName: 'Code barre',
        width: 200,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => textRenderHeader(params, 'product'),
    },
    {
        field: 'manufacturer',
        headerName: 'Marque',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'product'),
    },
    {
        field: 'price',
        headerName: 'Prix',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => numberRangeRenderHeader(params, 'product'),
    },
    {
        field: 'couleur',
        headerName: 'Couleur',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'product'),
    },
    {
        field: 'sexe',
        headerName: 'Genre',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) =>
            dropdownRenderHeader(params, 'product', 'Sexe'),
    },
    {
        field: 'collection_de_mode',
        headerName: 'Collection',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'product'),
    },
    {
        field: 'annee',
        headerName: 'Année',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'product'),
    },
    {
        field: 'saison',
        headerName: 'Saison',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        resizable: true,
        renderHeader: (params) => dropdownRenderHeader(params, 'product'),
    },
    {
        field: 'stock',
        headerName: 'État du stock',
        width: 200,
        hideSortIcons: true,
        sortable: false,
        align: 'left',
        resizable: true,
        renderCell: (params: GridCellParams): React.ReactElement => {
            return <ProductStockInfo stockInfo={params.row.stock} />;
        },
    },
    {
        field: 'local_qty',
        headerName: 'Qté Mon Magasin',
        width: 150,
        hideSortIcons: true,
        sortable: false,
        align: 'center',
        resizable: true,
        renderCell: (params: GridCellParams): React.ReactElement => {
            return (
                <ProductStockChip
                    content={params.row.local_qty}
                    defaultContent="0"
                />
            );
        },
    },
    {
        field: 'stock_label',
        headerName: 'Stock',
        width: 200,
        hideSortIcons: true,
        sortable: false,
        headerAlign: 'center',
        resizable: true,
        renderCell: (params: GridCellParams): React.ReactElement => {
            return (
                <ProductStockChip
                    content={params.row.stock_label}
                    defaultContent="Pas d'information"
                />
            );
        },
    },
    {
        field: 'delay_info',
        headerName: "Délai d'expédition",
        width: 300,
        hideSortIcons: true,
        sortable: false,
        headerAlign: 'center',
        resizable: true,
        renderCell: (params: GridCellParams): React.ReactElement => {
            return (
                <ProductStockChip
                    content={params.row.delay_info}
                    defaultContent="Pas d'information"
                />
            );
        },
    },
    {
        field: 'add',
        headerName: '',
        width: 50,
        hideSortIcons: true,
        sortable: false,
        align: 'center',
        resizable: false,
        renderCell: (params: GridCellParams): React.ReactElement => {
            return (
                <Box sx={{ lineHeight: 'initial' }}>
                    <ButtonAddProduct barcode={params.row.barcode} />
                </Box>
            );
        },
    },
];

export const pinnedProductColumns: GridPinnedColumns = {
    left: ['sku'],
    right: ['add'],
};
