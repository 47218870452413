import React from 'react';

import { HeaderTextFilter } from './StyledFilters';
import { OrderContext } from '../OrderGrid/OrderProvider';
import { ProductContext } from '../ProductGrid/ProductProvider';
import { CustomerContext } from '../CustomerGrid/CustomerProvider';
import { Grids, UpdateGridFilter } from '../../services/gridFilters.service';

type Props = {
    placeholder: string;
    filter: string;
    grid: Grids;
};

const FreeTextSearch: React.FC<Props> = ({ placeholder, filter, grid }) => {
    const { updateOrderFilter, orderColumnFilters } =
        React.useContext(OrderContext);
    const { updateProductFilter, productColumnFilters } =
        React.useContext(ProductContext);
    const { updateCustomerFilter, customerColumnFilters } =
        React.useContext(CustomerContext);
    const [value, setValue] = React.useState<string>('');
    const [updateFilter, setUpdateFilter] = React.useState<UpdateGridFilter>();

    React.useEffect(() => {
        switch (grid) {
            case 'order':
                setUpdateFilter(() => updateOrderFilter);
                orderColumnFilters.get(filter) &&
                    setValue(orderColumnFilters.get(filter));
                break;
            case 'product':
                setUpdateFilter(() => updateProductFilter);
                productColumnFilters.get(filter) &&
                    setValue(productColumnFilters.get(filter));
                break;
            case 'customer':
                setUpdateFilter(() => updateCustomerFilter);
                customerColumnFilters.get(filter) &&
                    setValue(customerColumnFilters.get(filter));
                break;
            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    return (
        <HeaderTextFilter
            variant="standard"
            value={value}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (updateFilter == undefined) return;

                setValue(e.target.value);
                updateFilter(filter, e.target.value);
            }}
            placeholder={placeholder}
        />
    );
};

export default FreeTextSearch;
