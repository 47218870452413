import React from 'react';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
import {
    Image,
    CreditCard,
    CardGiftcard,
    PaymentsOutlined,
    LocationCity,
    ParkOutlined,
    ReceiptOutlined,
} from '@mui/icons-material';

type Props = {
    label: string;
    code: string;
    used: boolean;
    currentOption: string;
    handlePaymentSelection: (code: string) => void;
};

const Item = styled(Paper)(() => ({
    height: '92px',
    width: '92px',
    paddingTop: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '14px',
}));
const IconContainer = styled(Box)(() => ({
    height: '35px',
    width: '35px',
    color: theme.palette.primary.main,
}));
const Label = styled(Typography)(() => ({
    lineHeight: 1,
    fontSize: '12px',
}));

const PaymentItem: React.FC<Props> = ({
    label,
    code,
    used,
    currentOption,
    handlePaymentSelection,
}) => {
    return (
        <Item
            elevation={4}
            sx={{
                background:
                    used || currentOption == code
                        ? 'rgba(47, 43, 153, 0.08)'
                        : 'initial',
                cursor: used || currentOption == code ? 'default' : 'pointer',
            }}
            onClick={() => handlePaymentSelection(code)}
        >
            <IconContainer>
                {{
                    checkmo: <ReceiptOutlined />,
                    cash: <PaymentsOutlined />,
                    localcash: <LocationCity />,
                    offlinecreditcard: <CreditCard />,
                    greencheckmo: <ParkOutlined />,
                    ethikdo: <CardGiftcard />,
                }[code] || <Image />}
            </IconContainer>

            <Label variant="body2">{label}</Label>
        </Item>
    );
};

export default PaymentItem;
