import React from 'react';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import {
    AddressTypes,
    CustomerFormTypesEnum,
} from '../../services/customer.service';

type Props = {
    addressType: AddressTypes;
    onClickHandler: () => void;
};

const AddIcon = styled(AddCircleOutlineOutlined)(() => ({
    height: '15px',
    width: '15px',
    marginRight: '10px',
}));
const StyledButton = styled(Button)(() => ({
    margin: '20px 0 0 24px',
}));

export const CustomerAddAddressButton: React.FC<Props> = ({
    addressType,
    onClickHandler,
}) => {
    return (
        <StyledButton onClick={onClickHandler}>
            <AddIcon />
            Ajouter une adresse de{' '}
            {addressType == CustomerFormTypesEnum.SHIPPING
                ? 'livraison'
                : 'facturation'}
        </StyledButton>
    );
};
