import React from 'react';

import { ErrorContext } from '../../providers/ErrorProvider';

import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Box } from '@mui/material';

const Container = styled(Box)(() => ({
    position: 'fixed',
    bottom: '1vw',
    right: '1vw',
    zIndex: '500',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
}));
const Error = styled(Alert)(() => ({
    width: '80%',
    marginTop: '1vw',
}));

const ErrorFeed: React.FC = () => {
    const { errorFeed, removeError } = React.useContext(ErrorContext);

    return (
        <Container>
            {errorFeed &&
                Array.from(errorFeed).map((errorArray: any, index: any) => {
                    const error = errorArray[1];
                    return (
                        <Error
                            key={index}
                            onClose={() => removeError(error.id)}
                            severity="error"
                        >
                            <AlertTitle>{error.title}</AlertTitle>
                            <p>{JSON.stringify(error.description)}</p>
                        </Error>
                    );
                })}
        </Container>
    );
};

export default ErrorFeed;
