import React from 'react';

import { APIProductItem } from '../../services/products.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

type Props = {
    products: Array<APIProductItem>;
};

const Products = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
}));
const LineContainer = styled(Box)(() => ({
    display: 'flex',
    marginBottom: '11px',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
}));
const ProductLine = styled(Box)(() => ({
    width: '100%',
    padding: '16px 0 16px 43px',
    '@media print': {
        padding: '16px 0 16px 15px',
    },
    borderLeft: 'solid 15px #8FF4C1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));
const ProductContent = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
}));
const ProductText = styled(Typography)(() => ({
    '@media print': {
        fontSize: '10pt',
        wordBreak: 'normal',
    },
}));
const ProductCol = styled(Box)(() => ({
    paddingRight: '30px',
    '@media print': {
        paddingRight: '10px',
    },
}));
const FixedBadgeContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));
const FixedBadge = styled(Box)(() => ({
    height: '20px',
    margin: '10px 24px 0 0 ',
    padding: '0px 6.5px',
    color: theme.palette.warning.contrastText,
    fontSize: '12px',
    backgroundColor: theme.palette.warning.main,
    borderRadius: '64px',
}));

const RecapProducts: React.FC<Props> = ({ products }) => {
    return (
        <Products>
            {products.map((item, index) => {
                const isDelayed = false;
                const isManuallyFixed = false;
                const productPrice = {
                    value: item.prices.original_price_including_tax?.value,
                    currency:
                        item.prices.original_price_including_tax?.currency,
                };
                const totalItemDiscount = item.prices.custom_discount_percent
                    ? {
                          value: item.prices.custom_discount_percent,
                          currency: '%',
                      }
                    : {
                          value: item.prices.total_item_discount?.value,
                          currency: item.prices.total_item_discount?.currency,
                      };
                const getItemOptions = (): string => {
                    const itemOptions: string[] = [];
                    if (!item?.selected_options?.length) return '';

                    item.selected_options.forEach((option) =>
                        itemOptions.push(option.value)
                    );

                    return itemOptions.join('-');
                };

                return (
                    <LineContainer key={index}>
                        <ProductLine
                            sx={{
                                borderLeftColor: isDelayed
                                    ? '#FF9359'
                                    : '#8FF4C1',
                            }}
                        >
                            <ProductContent>
                                <ProductCol
                                    sx={{
                                        width: '225px',
                                    }}
                                >
                                    <ProductText variant="subtitle2">
                                        Article
                                    </ProductText>
                                    <ProductText variant="body2">
                                        {`${
                                            item.product.name
                                        } ${getItemOptions()}`}
                                    </ProductText>
                                </ProductCol>

                                <ProductCol>
                                    <ProductText variant="subtitle2">
                                        Quantité
                                    </ProductText>
                                    <ProductText variant="body2">
                                        {item.quantity ?? 'n/r'}
                                    </ProductText>
                                </ProductCol>

                                <ProductCol sx={{ whiteSpace: 'nowrap' }}>
                                    <ProductText variant="subtitle2">
                                        TVA
                                    </ProductText>
                                    <ProductText variant="body2">
                                        {item.tax_percent ?? 'n/r'} %
                                    </ProductText>
                                </ProductCol>

                                <ProductCol sx={{ whiteSpace: 'nowrap' }}>
                                    <ProductText variant="subtitle2">
                                        TTC
                                    </ProductText>
                                    <ProductText variant="body2">
                                        {(productPrice.value ?? 'n/r') + ' '}
                                        {productPrice.currency == 'EUR'
                                            ? '€'
                                            : productPrice.currency}
                                    </ProductText>
                                </ProductCol>

                                <ProductCol sx={{ whiteSpace: 'nowrap' }}>
                                    <ProductText variant="subtitle2">
                                        Remise
                                    </ProductText>
                                    {!!totalItemDiscount.value && (
                                        <ProductText variant="body2">
                                            {(totalItemDiscount?.value ??
                                                'n/r') + ' '}
                                            {totalItemDiscount?.currency ==
                                            'EUR'
                                                ? '€'
                                                : totalItemDiscount?.currency}
                                        </ProductText>
                                    )}
                                </ProductCol>

                                <ProductCol
                                    sx={{
                                        width: '90px',
                                    }}
                                >
                                    <ProductText variant="subtitle2">
                                        Stock
                                    </ProductText>
                                    <ProductText variant="body2">
                                        {item?.pos_stock?.availability_label ??
                                            'Magasin'}
                                    </ProductText>
                                </ProductCol>

                                <ProductCol
                                    sx={{
                                        '@media print': {
                                            marginLeft: 'auto',
                                            textAlign: 'right',
                                        },
                                    }}
                                >
                                    <ProductText variant="subtitle2">
                                        {"Délai d'expé"}
                                    </ProductText>
                                    <ProductText variant="body2">
                                        {item?.pos_stock?.delay_information ??
                                            'n/r'}
                                    </ProductText>
                                </ProductCol>
                            </ProductContent>

                            {isManuallyFixed && (
                                <FixedBadgeContainer>
                                    <FixedBadge>
                                        Corrigé manuellement
                                    </FixedBadge>
                                </FixedBadgeContainer>
                            )}
                        </ProductLine>
                    </LineContainer>
                );
            })}
        </Products>
    );
};

export default RecapProducts;
