import { gql } from '@apollo/client';

export const PAYMENT_LIST = gql`
    query posPaymentMethods($store_id: Int!) {
        posPaymentMethods(store_id: $store_id) {
            items {
                label
                code
            }
        }
    }
`;
