import { gql } from '@apollo/client';

export const CUSTOMER_UPDATE = gql`
    mutation posUpdateCustomer(
        $customer_id: Int!
        $lastname: String
        $firstname: String
        $email: String
        $company: String
        $telephone: String
        $is_subscribed: Boolean
    ) {
        posUpdateCustomer(
            customer_id: $customer_id
            input: {
                lastname: $lastname
                firstname: $firstname
                email: $email
                company: $company
                telephone: $telephone
                is_subscribed: $is_subscribed
            }
        ) {
            customer {
                email
            }
        }
    }
`;
