import React from 'react';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';

type Props = {
    folded: boolean;
};

const ActionButton = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '48px',
    width: '80%',
}));
const FoldIcon = styled(ExpandLess)(() => ({
    height: '23px',
    width: '23px',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: '.2s ease-in-out',
}));

const FoldButton: React.FC<Props> = ({ folded }) => {
    return (
        <ActionButton>
            <FoldIcon sx={{ transform: folded ? '' : 'rotate(180deg)' }} />
        </ActionButton>
    );
};

export default FoldButton;
