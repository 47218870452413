import React, { PropsWithChildren } from 'react';

import { UserContext } from '../../providers/UserProvider';
import { ORDER_DROPDOWNCOLUMNS } from '../../queries/ORDER_DROPDOWNCOLUMNS_QUERY';
import {
    fetchGridDropdownData,
    GridFiltersLocation,
} from '../../services/gridFilters.service';
import { useLocation } from 'react-router-dom';
import { useGridOptions } from '../../providers/GridOptionsProvider';

export const OrderContext = React.createContext({
    orderColumnFilters: new Map(),
    orderDropdownColumns: new Map(),
    updateOrderFilter: (column: string, value: string | number) => {
        return;
    },
    updateOrderDropdownColumns: () => {
        return;
    },
});

export const OrderProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = React.useContext(UserContext);
    const { state } = useLocation<GridFiltersLocation>();
    const [orderColumnFilters, setOrderColumnFilters] = React.useState(
        new Map()
    );
    const [orderDropdownColumns, setOrderDropdownColumns] = React.useState(
        new Map()
    );
    const { setGridPage } = useGridOptions();
    React.useEffect(() => {
        if (!state?.filters?.order) return;

        setOrderColumnFilters(new Map(state.filters.order));
    }, [state]);

    const updateOrderFilter = (column: string, value: string | number) => {
        setGridPage(0);
        setOrderColumnFilters(new Map(orderColumnFilters.set(column, value)));
    };

    const updateOrderDropdownColumns = () => {
        fetchGridDropdownData(
            user.storeId,
            'order',
            ORDER_DROPDOWNCOLUMNS
        ).then((res) => {
            setOrderDropdownColumns(new Map(res));
        });
    };

    return (
        <OrderContext.Provider
            value={{
                orderColumnFilters,
                orderDropdownColumns,
                updateOrderFilter,
                updateOrderDropdownColumns,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};
