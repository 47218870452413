import { QueryOptions } from '@apollo/client';
import { apolloClient } from '../apolloClient';
import { EventEmitter, Events } from './events';

async function apolloQuery(queryOptions: QueryOptions): Promise<any> {
    const response = await apolloClient.query(queryOptions);

    if (!response?.errors || !response.errors?.length) return response;

    for (let i = 0; i < response.errors.length; i++) {
        const errorMessage = response.errors[i].message;

        if (
            errorMessage.includes('Seller not found') ||
            errorMessage.includes('Not authorized')
        ) {
            EventEmitter.emit(Events.AUTHENTICATION_EXPIRED, response);
            return;
        }

        EventEmitter.emit(Events.GRAPHQL_ERROR, {
            id: crypto.randomUUID(),
            title: errorMessage,
            description: response.errors[i],
        });
    }

    return response;
}

export default apolloQuery;
