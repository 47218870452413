import { gql } from '@apollo/client';

export const PRODUCT_SKU = gql`
    query products($sku: String!) {
        posProductFilter(filter: { sku: { eq: $sku } }) {
            items {
                id
                uid
                name
                sku
            }
        }
    }
`;
