import apolloMutate from '../utils/apolloMutate';
import { CART_APPLYGIFTCARDORCOUPON } from '../queries/CART_APPLYGIFTCARD_MUTATION';
import { CART_UNAPPLYGIFTCARDORCOUPON } from '../queries/CART_UNAPPLYGIFTCARDORCOUPON_MUTATION';
import { CART_UPDATEGIFTCARD } from '../queries/CART_UPDATEGIFTCARD_MUTATION';
import { APIMoney } from './payment.service';

export type APIGiftCard = {
    code: string;
    initial_amount: APIMoney;
    current_amount: APIMoney;
    applied_amount: APIMoney;
    expiration_date: string;
};
export type APIGiftCardsArray = Array<APIGiftCard>;

export type APIAppliedGiftCards = {
    gift_cards: APIGiftCardsArray;
    total_amount: APIMoney;
};

export type APICoupon = {
    code: string;
    description: string;
    name: string;
};

export type APIDiscount = {
    amount: APIMoney;
    label: string;
};

export type APIAppliedCoupons = Array<APICoupon>;

export type CardOrCoupon = {
    code: string;
    initial_amount?: APIMoney;
    current_amount?: APIMoney;
    applied_amount?: APIMoney;
    expiration_date?: string;
    description?: string;
    name?: string;
};

export type CardsAndCoupons = Array<APIGiftCard | APICoupon>;
export type CardOrCoupons = Array<CardOrCoupon>;

export const applyGiftCardOrCouponToCart = async (
    cartID: string,
    code: string
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_APPLYGIFTCARDORCOUPON,
        variables: {
            cart_id: cartID,
            code: code.trim(),
        },
    };

    return apolloMutate(mutationOptions);
};

export const unapplyGiftCardOrCouponToCart = async (
    cartID: string,
    code: string
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_UNAPPLYGIFTCARDORCOUPON,
        variables: {
            cart_id: cartID,
            code: code.trim(),
        },
    };

    return apolloMutate(mutationOptions);
};

export const updateGiftCardInCart = async (
    cartID: string,
    itemID: string,
    amount: number
): Promise<any> => {
    const mutationOptions = {
        mutation: CART_UPDATEGIFTCARD,
        variables: {
            cart_id: cartID,
            item_id: itemID,
            amount: amount,
        },
    };

    return apolloMutate(mutationOptions);
};
