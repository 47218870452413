import React from 'react';

import {
    APIAppliedCoupons,
    APIGiftCardsArray,
    CardOrCoupon,
} from '../../services/giftcard.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

import CodeItem from './CodeItem';

type Props = {
    giftCards: APIGiftCardsArray;
    couponCodes: APIAppliedCoupons;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const Header = styled(Box)(() => ({
    height: '41px',
    display: 'grid',
    padding: '0 22px 0 30px',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'end',
    gridAutoRows: '1fr',
    gridTemplateColumns: '9fr 3fr 3fr 4fr',
    '& p': {
        fontSize: theme.typography.overline.fontSize,
        fontWeight: 'bolder',
    },
    '& *': {
        marginRight: '20px',
    },
}));

const CodesList: React.FC<Props> = ({ giftCards, couponCodes }) => {
    const cardsAndCoupons = [...giftCards, ...couponCodes];

    return (
        <Container>
            <Header>
                <Typography sx={{ textAlign: 'start' }}>Référence</Typography>
                <Typography>Montant déduit</Typography>
                <Typography>Restant</Typography>
                <Typography>Date d&apos;expiration</Typography>
            </Header>
            {cardsAndCoupons.map((card: CardOrCoupon, index) => {
                return <CodeItem key={index} code={card} />;
            })}
        </Container>
    );
};

export default CodesList;
