import { gql } from '@apollo/client';

export const LOCATIONS = gql`
    query posSeller($user: String!) {
        posSeller(user: $user) {
            locations {
                id
                name
                store_id
                store_code
            }
        }
    }
`;
