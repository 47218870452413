import { gql } from '@apollo/client';

export const ORDER_DROPDOWNCOLUMNS = gql`
    query posOrderGridColumns($store_id: Int!) {
        posOrderGridColumns(store_id: $store_id) {
            items {
                type
                label
                values {
                    id
                    label
                }
            }
        }
    }
`;
