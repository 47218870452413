import React from 'react';

import { OrderProvider } from '../../components/OrderGrid/OrderProvider';
import { OrderGrid } from '../../components/OrderGrid/OrderGrid';

import { Typography } from '@mui/material';

const Orders: React.FunctionComponent = () => {
    return (
        <OrderProvider>
            <Typography component="h1" variant="h1">
                Commandes
            </Typography>
            <OrderGrid />
        </OrderProvider>
    );
};

export default Orders;
