import { gql } from '@apollo/client';

export const ORDER_PLACE = gql`
    mutation posPlaceOrder($cart_id: String!) {
        posPlaceOrder(input: { cart_id: $cart_id }) {
            order {
                order_number
            }
        }
    }
`;
