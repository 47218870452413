import { gql } from '@apollo/client';

export const CART_UPDATEGIFTCARD = gql`
    mutation posUpdateGiftCardInCart(
        $cart_id: String!
        $item_id: String!
        $amount: Float!
    ) {
        posUpdateGiftCardInCart(
            cart_id: $cart_id
            item_id: $item_id
            input: { amount: $amount }
        ) {
            id
        }
    }
`;
