import React from 'react';

import { CartContext } from '../../providers/CartProvider';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

import CommentButton from './CommentButton';
import CommentForm from './CommentForm';
import CommentTooltip from './CommentTooltip';

const Container = styled(Paper)(() => ({
    width: '100%',
    padding: '18px 23px',
    marginTop: '14px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    background: '#fcfcfc',
    transition: '.2s ease-in-out',
}));
const Header = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fcfcfc',
}));
const SectionTitleContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));
const SectionTitle = styled(Typography)(() => ({
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    marginRight: '10px',
}));
const FormContainer = styled(Box)(() => ({
    width: '100%',
}));

const CartCommentArea: React.FC = () => {
    const { carts, activeCart } = React.useContext(CartContext);
    const [folded, setFolded] = React.useState<boolean>(true);

    const handleFoldToggle = () => {
        setFolded((prevstate) => !prevstate);
    };

    React.useEffect(() => {
        if (!carts.get(activeCart)) {
            return;
        }

        setFolded(!carts.get(activeCart).customer_note);
    }, [carts, activeCart]);

    return (
        <Container
            sx={{
                height: folded ? '70px' : 'fit-content',
            }}
        >
            <Header onClick={handleFoldToggle}>
                <SectionTitleContainer>
                    <SectionTitle>AJOUTER UN COMMENTAIRE</SectionTitle>
                    {!folded && <CommentTooltip />}
                </SectionTitleContainer>

                <CommentButton folded={folded} />
            </Header>

            <FormContainer>{!folded && <CommentForm />}</FormContainer>
        </Container>
    );
};

export default CartCommentArea;
