import React from 'react';

import { Box } from '@mui/material';

import { CartContext } from '../../providers/CartProvider';
import {
    formatCartRows,
    FormattedCartRow,
} from '../../services/products.service';
import validKey from '../../utils/validKey';
import CartGrid from '../CartGrid/CartGrid';
import StockModal from '../StockModal/StockModal';
import { CartGridProvider } from '../CartGrid/CartGridProvider';
import { ErrorContext } from '../../providers/ErrorProvider';

export const CartContent: React.FC = () => {
    const { carts, activeCart, addProduct, stockModal } =
        React.useContext(CartContext);
    const { addError } = React.useContext(ErrorContext);
    const [barcode, setBarcode] = React.useState('');
    const [, setAddedSku] = React.useState<string>('');
    const [cartContent, setCartContent] = React.useState<
        Array<FormattedCartRow>
    >(carts.get(activeCart) || []);

    let handleTimeout:
        | number
        | ReturnType<typeof setTimeout>
        | ReturnType<typeof clearTimeout> = 0;
    let input: string[] = [];

    const handleKeyDown = (e: KeyboardEvent) => {
        // New timer for each valid keypress to check if the input is fast enough to come from a barcode scanner
        if (handleTimeout == 0) clearTimeout(handleTimeout);
        if (!e.key || e.key.length > 1) return;
        input = [...input, e.key];

        handleTimeout = setTimeout(() => {
            if (input.length <= 10) {
                input = [];
                return;
            }
            if (input.some((char) => !validKey(char))) {
                addError({
                    id: crypto.randomUUID(),
                    title: 'Code barre invalide',
                    description: `Ce code barre est invalide : ${input.join(
                        ''
                    )}`,
                });

                input = [];
                return;
            }
            setBarcode(input.join(''));
            input = [];
        }, 250);
    };

    React.useEffect(() => {
        if (!barcode) return;

        (async () => {
            addProduct(barcode, activeCart, true);
            setAddedSku(barcode);
            setBarcode('');
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barcode]);

    React.useEffect(() => {
        if (!carts.get(activeCart)) return;

        setCartContent(formatCartRows(carts.get(activeCart)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts, activeCart]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    return (
        <Box>
            <CartGridProvider>
                <CartGrid items={cartContent} />
            </CartGridProvider>

            {stockModal.state !== 'closed' && <StockModal />}
        </Box>
    );
};

export default CartContent;
