import React from 'react';

import { OrderContext } from '../OrderGrid/OrderProvider';
import { ProductContext } from '../ProductGrid/ProductProvider';
import { CustomerContext } from '../CustomerGrid/CustomerProvider';

import { Button, Popover, TextField } from '@mui/material';
import {
    HeaderNumberFilter,
    RangeFieldsContainer,
    RangePopoverContainer,
} from './StyledFilters';
import { Grids, UpdateGridFilter } from '../../services/gridFilters.service';

type Props = {
    value: string;
    placeholder: string;
    filterFrom: string;
    filterTo: string;
    grid: Grids;
};

const RangeNumberSearch: React.FC<Props> = ({
    placeholder,
    filterFrom,
    filterTo,
    grid,
}) => {
    const { updateOrderFilter, orderColumnFilters } =
        React.useContext(OrderContext);
    const { updateProductFilter, productColumnFilters } =
        React.useContext(ProductContext);
    const { updateCustomerFilter, customerColumnFilters } =
        React.useContext(CustomerContext);
    const [lowerValue, setLowerValue] = React.useState('');
    const [upperValue, setUpperValue] = React.useState('');
    const [updateFilter, setUpdateFilter] = React.useState<UpdateGridFilter>();

    React.useEffect(() => {
        switch (grid) {
            case 'order':
                setUpdateFilter(() => updateOrderFilter);
                orderColumnFilters.get('subtotalFrom') &&
                    setLowerValue(orderColumnFilters.get('subtotalFrom'));
                orderColumnFilters.get('subtotalTo') &&
                    setUpperValue(orderColumnFilters.get('subtotalTo'));
                break;
            case 'product':
                setUpdateFilter(() => updateProductFilter);
                productColumnFilters.get('subtotalFrom') &&
                    setLowerValue(productColumnFilters.get('subtotalFrom'));
                productColumnFilters.get('subtotalTo') &&
                    setUpperValue(productColumnFilters.get('subtotalTo'));
                break;
            case 'customer':
                setUpdateFilter(() => updateCustomerFilter);
                customerColumnFilters.get('subtotalFrom') &&
                    setLowerValue(customerColumnFilters.get('subtotalFrom'));
                customerColumnFilters.get('subtotalTo') &&
                    setUpperValue(customerColumnFilters.get('subtotalTo'));
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    // Popover management
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(
        null
    );
    const open = Boolean(anchorEl);
    const handleHeaderClick = (e: React.MouseEvent<HTMLInputElement>) => {
        setAnchorEl(e.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const handleClearFilter = () => {
        handlePopOverClose();
        setLowerValue('');
        setUpperValue('');

        if (!updateFilter) return;
        updateFilter('subtotalFrom', '');
        updateFilter('subtotalTo', '');
    };

    return (
        <>
            <HeaderNumberFilter
                variant="standard"
                value={lowerValue + '-' + upperValue}
                placeholder={placeholder}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    handleHeaderClick(e);
                }}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <RangePopoverContainer>
                    <RangeFieldsContainer>
                        <TextField
                            key={1}
                            type="number"
                            placeholder="de ..."
                            value={lowerValue}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (!updateFilter) return;

                                setLowerValue(e.target.value);
                                updateFilter(filterFrom, e.target.value);
                            }}
                        />

                        <TextField
                            key={2}
                            type="number"
                            placeholder="à ..."
                            value={upperValue}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (!updateFilter) return;

                                setUpperValue(e.target.value);
                                updateFilter(filterTo, e.target.value);
                            }}
                        />
                    </RangeFieldsContainer>

                    <Button onClick={handleClearFilter}>
                        Retirer le filtre
                    </Button>
                </RangePopoverContainer>
            </Popover>
        </>
    );
};

export default RangeNumberSearch;
