import React from 'react';

import { CartContext } from '../../providers/CartProvider';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { ClearOutlined, DeleteOutline } from '@mui/icons-material';

type Props = {
    cartNumber: number;
    cartID: string;
    cartsSize: number;
};

const CartButtonContainer = styled(Box)(() => ({
    height: '36px',
    marginRight: '16px',
    padding: 0,
    display: 'flex',
    borderRadius: '4px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    hover: { background: theme.palette.secondary.main },
}));
const CartButton = styled(Button)(() => ({
    height: '36px',
    padding: 0,
    paddingRight: '16px',
    background: 'transparent',
    color: theme.palette.primary.contrastText,
}));
const RemoveIconContainer = styled(Box)(() => ({
    height: '36px',
    width: '48px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '12px',
    cursor: 'pointer',
}));
const StyledClear = styled(ClearOutlined)(() => ({
    height: '100%',
    width: '100%',
}));
const StyledDelete = styled(DeleteOutline)(() => ({
    height: '100%',
    width: '100%',
}));

const CartTab: React.FC<Props> = ({ cartNumber, cartID, cartsSize }) => {
    const { activeCart, removeCart, selectCart } =
        React.useContext(CartContext);
    const [isRemoving, setIsRemoving] = React.useState<boolean>(false);

    const handleRemoveClick = (e: React.MouseEvent, hasValidated: boolean) => {
        e.preventDefault();

        if (!hasValidated) {
            setIsRemoving(true);
            return;
        }

        removeCart(cartID);
        setIsRemoving(false);
    };

    return (
        <ClickAwayListener onClickAway={() => setIsRemoving(false)}>
            <CartButtonContainer
                key={cartNumber + 1}
                sx={{
                    order: cartNumber + 1,
                    background: isRemoving
                        ? theme.palette.error.main
                        : activeCart == cartID
                        ? theme.palette.secondary.main
                        : theme.palette.primary.dark,
                }}
            >
                <RemoveIconContainer
                    onClick={(e: React.MouseEvent) => {
                        handleRemoveClick(e, isRemoving);
                    }}
                >
                    {isRemoving ? <StyledDelete /> : <StyledClear />}
                </RemoveIconContainer>

                <CartButton
                    onClick={() => {
                        selectCart(cartID);
                        setIsRemoving(false);
                    }}
                >
                    PANIER {cartNumber + 1}
                </CartButton>
            </CartButtonContainer>
        </ClickAwayListener>
    );
};

export default CartTab;
