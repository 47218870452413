import React from 'react';
import { useHistory } from 'react-router-dom';

import { CartContext } from '../../providers/CartProvider';
import {
    checkHasPaidTotal,
    updateCompositePaidTotal,
} from '../../services/payment.service';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}));
const StyledButton = styled(Button)(() => ({
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    marginBottom: '85px',
}));

const CartSubmitButton: React.FC = () => {
    const history = useHistory();
    const { carts, activeCart } = React.useContext(CartContext);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!carts.get(activeCart)) {
            return;
        }
        const currentCart = carts.get(activeCart);

        const hasProducts = currentCart?.items?.length;
        const hasCustomer = currentCart?.email;
        const hasShippingAddress = currentCart?.shipping_addresses?.length;
        const hasBillingAddress = currentCart?.billing_address;
        let compositePaidTotal = 0;
        if (
            currentCart?.composite_payment &&
            currentCart?.composite_payment[0]
        ) {
            compositePaidTotal = updateCompositePaidTotal(
                currentCart?.composite_payment[0]?.payments
            );
        }
        const grandTotal = Math.round(
            currentCart?.prices?.grand_total?.value * 100
        );
        const hasPaidTotal = checkHasPaidTotal(compositePaidTotal, grandTotal);

        if (
            !hasProducts ||
            !hasCustomer ||
            !hasShippingAddress ||
            !hasBillingAddress ||
            !hasPaidTotal
        ) {
            if (isDisabled) {
                return;
            }

            setIsDisabled(true);
            return;
        }

        setIsDisabled(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCart, carts]);

    return (
        <Container>
            <StyledButton
                onClick={() => {
                    if (isDisabled) {
                        return;
                    }

                    history.push('/recapitulatif', {
                        type: 'cart',
                        id: activeCart,
                    });
                }}
                sx={{
                    backgroundColor: isDisabled
                        ? theme.palette.action.disabledBackground
                        : theme.palette.secondary.main,
                    color: isDisabled
                        ? theme.palette.action.disabled
                        : theme.palette.secondary.contrastText,
                    '&:hover': {
                        backgroundColor: isDisabled
                            ? theme.palette.action.disabledBackground
                            : theme.palette.secondary.main,
                        color: isDisabled
                            ? theme.palette.action.disabled
                            : theme.palette.secondary.contrastText,
                        cursor: isDisabled ? 'default' : 'pointer',
                    },
                }}
            >
                FINALISER LA COMMANDE
            </StyledButton>
        </Container>
    );
};

export default CartSubmitButton;
