import { MutationOptions } from '@apollo/client';
import { apolloClient } from '../apolloClient';
import { EventEmitter, Events } from './events';

const ignoredErrors = [
    'Invalid customer address id',
    'Current customer does not have permission to address with ID',
    'Set the address and try again.',
    'is not available',
    'is out of stock',
];

async function apolloMutate(mutationOptions: MutationOptions): Promise<any> {
    const response = await apolloClient.mutate(mutationOptions);

    if (!response?.errors || !response.errors?.length) return response;

    for (let i = 0; i < response.errors.length; i++) {
        const errorMessage = response.errors[i].message;

        for (let j = 0; j < ignoredErrors.length; j++) {
            if (errorMessage.includes(ignoredErrors[j])) return response;
        }

        if (
            errorMessage.includes('Seller not found') ||
            errorMessage.includes('Not authorized')
        ) {
            EventEmitter.emit(Events.AUTHENTICATION_EXPIRED, response);
            return;
        }

        EventEmitter.emit(Events.GRAPHQL_ERROR, {
            id: crypto.randomUUID(),
            title: errorMessage,
            description: response.errors[i],
        });
    }

    return response;
}

export default apolloMutate;
