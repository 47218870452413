import React from 'react';

import { styled } from '@mui/material/styles';
import ImageModal from '../ImageModal/ImageModal';

type Props = {
    smallImage: string;
    image: string;
};

const ImageContainer = styled('img')(() => ({
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    boxShadow: '24px',
}));

const ProductImage: React.FC<Props> = ({ smallImage, image }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <ImageContainer src={smallImage} onClick={() => handleOpen()} />
            <ImageModal open={open} handleClose={handleClose} image={image} />
        </>
    );
};

export default ProductImage;
