import apolloQuery from '../utils/apolloQuery';
import { ORDER_QUERY } from '../queries/ORDER_QUERY';
import { APICustomer, APICustomerAddress } from './customer.service';
import { APIAppliedGiftCards } from './giftcard.service';
import { APICompositePayment, APIMoney } from './payment.service';
import {
    APIOrderProductItem,
    APIProductItem,
    StockStatusEnum,
} from './products.service';
import { ORDER_GETINVOICES } from '../queries/ORDER_GETINVOICES_QUERY';
import { RecapTypes } from './cart.service';
import { GridFilters } from './gridFilters.service';

export enum OrderOriginEnum {
    WEB = 'WEB',
    STORE = 'STORE',
}

export type OrderOrigin = OrderOriginEnum.WEB | OrderOriginEnum.STORE;

export type APIOrder = {
    applied_gift_cards: APIAppliedGiftCards;
    billing_address: APICustomerAddress;
    carrier: string;
    company: string;
    composite_payment: APICompositePayment;
    customer: APICustomer;
    email: string;
    firstname: string;
    id: string;
    rma_url: string;
    items: [APIOrderProductItem];
    lastname: string;
    number: string;
    order_date: string;
    order_origin: OrderOrigin;
    shipping_address: APICustomerAddress;
    shipping_method: string;
    status: {
        id: string;
        label: string;
    };
    store: {
        id: string;
        label: string;
    };
    telephone: string;
    total: {
        base_grand_total: APIMoney;
        discounts: [
            {
                amount: APIMoney;
                label: string;
            }
        ];
        grand_total: APIMoney;
        shipping_handling: {
            amount_excluding_tax: APIMoney;
            amount_including_tax: APIMoney;
            discounts: {
                amount: APIMoney;
                label: string;
            };
            taxes: {
                amount: APIMoney;
                rate: number;
                title: string;
            };
            total_amount: APIMoney;
        };
        subtotal: APIMoney;
        taxes: {
            amount: number;
            rate: number;
            title: string;
        };
        total_shipping: APIMoney;
        total_tax: APIMoney;
    };
};
export type APIOrderResponse = {
    data: {
        posOrder: APIOrder;
    };
    errors?: any;
};
export type APIOrderStore = {
    id: number;
    label: string;
};
export type APIOrderAddress = {
    firstname: string;
    lastname: string;
    telephone: string;
};

export type APIOrderTotal = {
    subtotal?: APIMoney;
    grand_total: APIMoney;
};

export type APIOrderStatus = {
    id: number;
    label: string;
};

export type APIOrderComment = {
    message: string;
    timestamp: string;
};

export type APIOrderRow = {
    id: string;
    number: string;
    store: APIOrderStore;
    order_date: string;
    billing_address: APIOrderAddress;
    email: string;
    total: APIOrderTotal;
    status: APIOrderStatus;
    customer_note: string;
};

export type FormattedOrderRow = {
    id: string;
    number: string;
    store_label: string;
    order_date: string;
    lastname: string;
    firstname: string;
    email: string;
    telephone: string;
    subtotal: string;
    status: string;
    customer_note: string;
};

export type OrderListResponse = {
    data: {
        posOrderList: {
            items: Array<APIOrderRow>;
        };
    };
};

export type OrderRecap = {
    number: string;
    date: string;
    store: string;
};

export type PlaceOrderData = {
    order: {
        order_number: string;
    };
};

export type OrderGridLocation = {
    type: RecapTypes;
    id: string;
    filters?: GridFilters;
};

export const formatOrderRows = (
    response: OrderListResponse
): Array<FormattedOrderRow> => {
    const items = response.data.posOrderList.items;
    const formattedOrderRows: Array<FormattedOrderRow> = [];

    items.forEach((item: APIOrderRow) => {
        const formattedRow = {
            id: item.id,
            number: item.number,
            store_label: item.store.label,
            order_date: item.order_date,
            lastname: item.billing_address.lastname,
            firstname: item.billing_address.firstname,
            email: item.email,
            telephone: item.billing_address.telephone,
            subtotal:
                item?.total?.grand_total.value.toString() +
                ' ' +
                item?.total?.grand_total.currency,
            status: item.status.label,
            customer_note: item.customer_note,
        };
        formattedOrderRows.push(formattedRow);
    });

    return formattedOrderRows;
};

export const fetchOrder = async (
    orderNumber: string
): Promise<APIOrderResponse> => {
    const queryOptions = {
        query: ORDER_QUERY,
        variables: {
            order_number: orderNumber,
        },
    };

    return await apolloQuery(queryOptions);
};

export const formatOrderProducts = (
    orderProducts: Array<APIOrderProductItem>,
    orderOrigin: OrderOrigin
): Array<APIProductItem> => {
    const products: Array<APIProductItem> = [];
    let stockStatus = '';

    switch (orderOrigin) {
        case OrderOriginEnum.STORE:
            stockStatus = StockStatusEnum.IN_STOCK_AT_LOCATION;
            break;
        case OrderOriginEnum.WEB:
            stockStatus = StockStatusEnum.HAS_INCOMING_QUANTITIES;
            break;
    }

    orderProducts.forEach((orderProduct) => {
        const totalItemDiscount = (): number => {
            let total = 0;
            orderProduct.discounts.forEach((discount) => {
                total += discount.amount.value;
            });
            return total;
        };

        const product: APIProductItem = {
            uid: orderProduct.id,
            product: {
                sku: orderProduct.product_sku,
                name: orderProduct.product_name,
                type_id: orderProduct.product_type,
            },
            prices: {
                original_price_including_tax: {
                    value: orderProduct.product_original_price_including_tax
                        .value,
                    currency:
                        orderProduct.product_original_price_including_tax
                            .currency,
                },
                total_item_discount: {
                    value: totalItemDiscount(),
                    currency: orderProduct?.discounts[0]?.amount?.currency
                        ? orderProduct.discounts[0].amount.currency
                        : '/',
                },
            },
            pos_stock: {
                delay_information: orderProduct.delivery_delay,
                status: stockStatus,
                status_label: orderProduct.status,
                availability: orderProduct.availability,
                availability_label: orderProduct.availability_label,
            },
            selected_options: orderProduct?.selected_options ?? [],
            quantity: orderProduct.quantity_ordered,
            tax_percent: orderProduct?.tax_percent,
        };
        products.push(product);
    });

    return products;
};

export const fetchInvoicesList = async (
    orderNumber: string
): Promise<Array<string>> => {
    const queryOptions = {
        query: ORDER_GETINVOICES,
        variables: {
            order_number: orderNumber,
        },
    };

    return await apolloQuery(queryOptions)
        .then((invoicesData) => {
            if (!invoicesData?.data?.posGetInvoicesUrl?.length) return [];
            return invoicesData.data.posGetInvoicesUrl;
        })
        .catch(() => {
            return [];
        });
};
