import React from 'react';
import { useForm } from 'react-hook-form';

import { CartContext } from '../../providers/CartProvider';

import { styled } from '@mui/material/styles';
import { theme } from '../../themeOptions';
import { Box, Button, TextField } from '@mui/material';

type CommentValues = {
    comment: string;
};

const StyledForm = styled('form')(() => ({
    width: '90%',
    marginTop: '35px',
    marginBottom: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    transition: '.2s ease-in-out',
}));
const MultilineTextField = styled(TextField)(() => ({
    width: '100%',
    height: 'fit-content',
    minHeight: '115px',
    background: 'rgba(27, 31, 82, 0.05)',
}));
const ButtonContainer = styled(Box)(() => ({
    width: '100%',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
}));

const CommentForm: React.FC = () => {
    const { carts, activeCart, addComment } = React.useContext(CartContext);
    const { register, handleSubmit } = useForm<CommentValues>();
    const [comment, setComment] = React.useState<string>('');
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!carts.get(activeCart)?.customer_note) {
            setComment('');
            return;
        }

        setComment(carts.get(activeCart).customer_note);
    }, [carts, activeCart]);

    React.useEffect(() => {
        if (!comment || comment == carts.get(activeCart)?.customer_note) {
            setIsDisabled(true);
            return;
        }

        setIsDisabled(false);
    }, [comment, carts, activeCart]);

    return (
        <StyledForm
            onSubmit={handleSubmit((commentData) => {
                setIsDisabled(true);
                addComment(commentData.comment, activeCart);
                setComment(commentData.comment);
            })}
        >
            <MultilineTextField
                value={comment}
                multiline
                minRows={4}
                id="comment"
                helperText={'50 caractères maximum'}
                placeholder="Écrivez un commentaire. Exemple : cette commande est un cadeau"
                {...register('comment', { required: true })}
                onChange={({
                    target: { value: val },
                }: React.ChangeEvent<HTMLInputElement>) => {
                    val.length < 51 && setComment(val);
                }}
            />

            <ButtonContainer>
                <Button
                    type="submit"
                    disabled={isDisabled}
                    sx={{
                        background: isDisabled
                            ? theme.palette.action.disabledBackground
                            : theme.palette.warning.main,
                        color: isDisabled
                            ? theme.palette.action.disabled
                            : theme.palette.warning.contrastText,
                        '&:hover': {
                            background: isDisabled
                                ? theme.palette.action.disabledBackground
                                : theme.palette.warning.main,
                            color: isDisabled
                                ? theme.palette.action.disabled
                                : theme.palette.warning.contrastText,
                        },
                    }}
                >
                    Valider
                </Button>
            </ButtonContainer>
        </StyledForm>
    );
};

export default CommentForm;
