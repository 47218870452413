import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { CartContext } from '../../providers/CartProvider';
import {
    AddressTypes,
    APICustomerAddress,
    CustomerFormTypesEnum,
} from '../../services/customer.service';
import {
    AddressRadio,
    AddressRadioEnum,
    AddressRadioMethods,
    APIShippingMethodsInput,
    ShippingMethods,
} from '../../services/delivery.service';

import { styled } from '@mui/material/styles';
import { Box, RadioGroup } from '@mui/material';

import AddressSwitch from './AddressSwitch';
import SelectAddressRadio from './SelectAddressRadio';
import Address from './Address';
import SubmitButton from './SubmitButton';
import CarrierSwitch from './CarrierSwitch';

type Props = {
    deliveryCarrier: APIShippingMethodsInput | undefined;
    homeMethods: ShippingMethods;
    handleCarrierSelection: (methodCodes: APIShippingMethodsInput) => void;
};

const Container = styled(Box)(() => ({
    //
}));
const RadioPrimaryContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

const HomeDelivery: React.FC<Props> = ({
    deliveryCarrier,
    homeMethods,
    handleCarrierSelection,
}) => {
    const { activeCart, carts, assignNonExistingAddresses } =
        React.useContext(CartContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [addressType, setAddressType] = React.useState<AddressTypes>(
        CustomerFormTypesEnum.SHIPPING
    );
    const [currentAddressSelected, setCurrentAddressSelected] =
        React.useState<AddressRadio>(AddressRadioEnum.DEFAULT);
    const [addressesSelected, setAddressesSelected] =
        React.useState<AddressRadioMethods>({
            shipping: AddressRadioEnum.DEFAULT,
            billing: AddressRadioEnum.DEFAULT,
        });
    const [address, setAddress] = React.useState<APICustomerAddress>();
    const formMethods = useForm();

    const handleSwitchAddress = (addressType: AddressTypes) => {
        addressType == CustomerFormTypesEnum.SHIPPING
            ? setCurrentAddressSelected(addressesSelected.shipping)
            : setCurrentAddressSelected(addressesSelected.billing);
        setAddressType(addressType);
    };
    const handleAddressSelection = (addressSelected: AddressRadio) => {
        setCurrentAddressSelected(addressSelected);
        setAddressesSelected((prevstate) => {
            return {
                shipping:
                    addressType == CustomerFormTypesEnum.SHIPPING
                        ? addressSelected
                        : prevstate.shipping,
                billing:
                    addressType == CustomerFormTypesEnum.BILLING
                        ? addressSelected
                        : prevstate.billing,
            };
        });
    };
    const onSubmit: SubmitHandler<any> = (formData) => {
        if (
            formData.shippingform == 'default' &&
            formData.billingform == 'default'
        )
            return;

        switch (addressType) {
            case CustomerFormTypesEnum.SHIPPING: {
                const shippingAddress = {
                    city: formData.shippingcity,
                    country_code: 'FR',
                    firstname: formData.shippingfirstname,
                    lastname: formData.shippinglastname,
                    postcode: formData.shippingpostcode,
                    street: [formData.shippingstreet, formData.shippingstreet2],
                    telephone: formData.shippingtelephone,
                    company: formData?.shippingcompany,
                };
                setIsLoading(true);
                assignNonExistingAddresses(
                    activeCart,
                    undefined,
                    {
                        shipping: shippingAddress,
                        billing: shippingAddress,
                    },
                    undefined,
                    deliveryCarrier
                ).then(() => {
                    handleAddressSelection(AddressRadioEnum.DEFAULT);
                    setIsLoading(false);
                });
                break;
            }

            case CustomerFormTypesEnum.BILLING:
                setIsLoading(true);
                assignNonExistingAddresses(
                    activeCart,
                    CustomerFormTypesEnum.BILLING,
                    undefined,
                    {
                        city: formData.billingcity,
                        country_code: 'FR',
                        firstname: formData.billingfirstname,
                        lastname: formData.billinglastname,
                        postcode: formData.billingpostcode,
                        street: [
                            formData.billingstreet,
                            formData.billingstreet2,
                        ],
                        telephone: formData.billingtelephone,
                        company: formData?.billingcompany,
                    },
                    deliveryCarrier
                ).then(() => {
                    handleAddressSelection(AddressRadioEnum.DEFAULT);
                    setIsLoading(false);
                });
                break;

            default:
                break;
        }
    };

    React.useEffect(() => {
        if (!carts.get(activeCart)) {
            return;
        }
        switch (addressType) {
            case CustomerFormTypesEnum.SHIPPING:
                if (!carts.get(activeCart).shipping_addresses) {
                    break;
                }
                setAddress(carts.get(activeCart).shipping_addresses[0]);
                break;

            case CustomerFormTypesEnum.BILLING:
                if (!carts.get(activeCart).billing_address) {
                    break;
                }
                setAddress(carts.get(activeCart).billing_address);
                break;

            default:
                break;
        }
    }, [carts, activeCart, addressType]);

    return (
        <Container>
            {homeMethods.length ? (
                ''
            ) : (
                <CarrierSwitch
                    methods={homeMethods}
                    handleCarrierSelection={handleCarrierSelection}
                />
            )}

            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <RadioGroup
                        value={currentAddressSelected}
                        {...formMethods.register(addressType + 'form', {
                            required: false,
                        })}
                    >
                        <RadioPrimaryContainer>
                            <SelectAddressRadio
                                addressToSelect={AddressRadioEnum.DEFAULT}
                                addressType={addressType}
                                handleAddressSelection={() => {
                                    handleAddressSelection(
                                        AddressRadioEnum.DEFAULT
                                    );
                                }}
                            />

                            <AddressSwitch
                                selected={addressType}
                                handleSwitchAddress={handleSwitchAddress}
                            />
                        </RadioPrimaryContainer>

                        <Address
                            address={address}
                            addressSelected={AddressRadioEnum.DEFAULT}
                        />

                        <SelectAddressRadio
                            addressToSelect={AddressRadioEnum.NEW}
                            addressType={addressType}
                            handleAddressSelection={() => {
                                handleAddressSelection(AddressRadioEnum.NEW);
                            }}
                        />
                    </RadioGroup>
                    {((addressType == CustomerFormTypesEnum.SHIPPING &&
                        addressesSelected.shipping == AddressRadioEnum.NEW) ||
                        (CustomerFormTypesEnum.BILLING &&
                            addressesSelected.billing ==
                                AddressRadioEnum.NEW)) && (
                        <>
                            <Address
                                addressSelected={AddressRadioEnum.NEW}
                                addressType={addressType}
                            />

                            <SubmitButton
                                isDisabled={isLoading}
                                addressType={addressType}
                            />
                        </>
                    )}
                </form>
            </FormProvider>
        </Container>
    );
};

export default HomeDelivery;
