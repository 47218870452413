import React, { PropsWithChildren, useEffect } from 'react';

import { EventEmitter, Events } from '../utils/events';

type Error = {
    id: string;
    title: string;
    description: string;
};

export const ErrorContext = React.createContext({
    errorFeed: new Map(),
    addError: (error: Error) => {
        return;
    },
    removeError: (id: string) => {
        return;
    },
});

function isExcludeError(error: Error): boolean {
    return [
        "Code can't be applied as a coupon code nor as a gift card code.",
        'Error loading customer through email',
        'Invalid email format',
        'n’est pas une adresse email valide.',
        'Les clients ayant le même e-mail existent déjà dans un site web du groupe.',
    ].some((txt) => error.title.includes(txt));
}

export const ErrorProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [errorFeed, setErrorFeed] = React.useState<Map<string, Error>>(
        new Map()
    );

    const addError = (error: Error) => {
        if (isExcludeError(error)) return;
        EventEmitter.emit(Events.GRAPHQL_ERROR, error);
        return;
    };

    const removeError = (id: string) => {
        errorFeed.delete(id);
        setErrorFeed(new Map(errorFeed));
        return;
    };
    useEffect(() => {
        EventEmitter.subscribe(Events.GRAPHQL_ERROR, (error: Error) => {
            if (isExcludeError(error)) return;
            setErrorFeed(
                (prevErrorFeed) => new Map(prevErrorFeed.set(error.id, error))
            );
            return;
        });
        return () => {
            EventEmitter.unsubscribe(Events.GRAPHQL_ERROR);
        };
    }, []);

    return (
        <ErrorContext.Provider value={{ addError, errorFeed, removeError }}>
            {children}
        </ErrorContext.Provider>
    );
};
