import { gql } from '@apollo/client';

export const CART_SETPAYMENTS = gql`
    mutation posSetCompositeOfflinePaymentMethodsOnCart(
        $store_id: Int!
        $cart_id: String!
        $payment_methods: [CompositeOfflinePaymentMethodInput!]!
    ) {
        posSetCompositeOfflinePaymentMethodsOnCart(
            store_id: $store_id
            cart_id: $cart_id
            payment_methods: $payment_methods
        ) {
            id
        }
    }
`;
