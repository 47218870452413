import React from 'react';
import { CustomerProvider } from '../../components/CustomerGrid/CustomerProvider';
import CustomerGrid from '../../components/CustomerGrid/CustomerGrid';
import { Typography } from '@mui/material';

const Customers: React.FunctionComponent = () => {
    return (
        <CustomerProvider>
            <Typography component="h1" variant="h1">
                Clients
            </Typography>
            <CustomerGrid />
        </CustomerProvider>
    );
};

export default Customers;
