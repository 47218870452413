import React from 'react';

import { DeliveryTypes } from '../../services/delivery.service';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import TypeButton from './TypeButton';

type Props = {
    uniqueDeliveryTypes: Array<DeliveryTypes>;
    deliveryType: DeliveryTypes;
    handleSectionSelection: (deliveryType: DeliveryTypes) => void;
};

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

const TypeSelector: React.FC<Props> = ({
    uniqueDeliveryTypes,
    deliveryType,
    handleSectionSelection,
}) => {
    return (
        <Container>
            {!!(uniqueDeliveryTypes && uniqueDeliveryTypes.length) &&
                uniqueDeliveryTypes.map(
                    (type: DeliveryTypes, index: number) => {
                        const label =
                            type == 'TO_DELIVERY_POINT'
                                ? 'MAGASIN'
                                : 'DOMICILE';

                        return (
                            <TypeButton
                                key={index}
                                selected={deliveryType == type}
                                deliveryType={type}
                                label={label}
                                handleSectionSelection={handleSectionSelection}
                            />
                        );
                    }
                )}
        </Container>
    );
};

export default TypeSelector;
