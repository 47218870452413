import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../providers/UserProvider';
import { PRODUCT_DROPDOWNCOLUMNS } from '../../queries/PRODUCT_DROPDOWNCOLUMNS_QUERY';
import {
    fetchGridDropdownData,
    GridFiltersLocation,
} from '../../services/gridFilters.service';

export const ProductContext = React.createContext({
    productColumnFilters: new Map(),
    productDropdownColumns: new Map(),
    updateProductFilter: (column: string, value: string | number) => {
        return;
    },
    updateProductDropdownColumns: () => {
        return;
    },
});

export const ProductProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = React.useContext(UserContext);
    const { state } = useLocation<GridFiltersLocation>();
    const [productColumnFilters, setProductColumnFilters] = React.useState(
        new Map()
    );
    const [productDropdownColumns, setProductDropdownColumns] = React.useState(
        new Map()
    );

    React.useEffect(() => {
        if (!state?.filters?.customer) return;

        setProductColumnFilters(new Map(state.filters.product));
    }, [state]);

    const updateProductFilter = (column: string, value: string | number) => {
        setProductColumnFilters(
            new Map(productColumnFilters.set(column, value))
        );
    };

    const updateProductDropdownColumns = () => {
        fetchGridDropdownData(
            user.storeId,
            'product',
            PRODUCT_DROPDOWNCOLUMNS
        ).then((res) => {
            setProductDropdownColumns(new Map(res));
        });
    };

    return (
        <ProductContext.Provider
            value={{
                productColumnFilters,
                productDropdownColumns,
                updateProductFilter,
                updateProductDropdownColumns,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};
