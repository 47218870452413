import React from 'react';

import { OrderContext } from '../OrderGrid/OrderProvider';
import { ProductContext } from '../ProductGrid/ProductProvider';
import { CustomerContext } from '../CustomerGrid/CustomerProvider';

import { MenuItem } from '@mui/material';
import { HeaderDropdownFilter } from './StyledFilters';
import {
    GridFilterArray,
    Grids,
    UpdateGridFilter,
} from '../../services/gridFilters.service';

type Props = {
    placeholder: string;
    filter: string | undefined;
    grid: Grids;
};

const SelectDropdown: React.FC<Props> = ({ placeholder, filter, grid }) => {
    const { updateOrderFilter, orderDropdownColumns, orderColumnFilters } =
        React.useContext(OrderContext);
    const {
        updateProductFilter,
        productDropdownColumns,
        productColumnFilters,
    } = React.useContext(ProductContext);
    const {
        updateCustomerFilter,
        customerDropdownColumns,
        customerColumnFilters,
    } = React.useContext(CustomerContext);
    const [value, setValue] = React.useState('');
    const [dropdownColumns, setDropdownColumns] = React.useState<
        Map<string, GridFilterArray>
    >(new Map());
    const [updateFilter, setUpdateFilter] = React.useState<UpdateGridFilter>();
    const [dropdownValues, setDropdownValues] = React.useState<Array<any>>([]);

    React.useEffect(() => {
        switch (grid) {
            case 'order':
                setDropdownColumns(orderDropdownColumns);
                setUpdateFilter(() => updateOrderFilter);
                orderColumnFilters.get(filter) &&
                    setValue(orderColumnFilters.get(filter));
                break;
            case 'product':
                setDropdownColumns(productDropdownColumns);
                setUpdateFilter(() => updateProductFilter);
                productColumnFilters.get(filter) &&
                    setValue(productColumnFilters.get(filter));
                break;
            case 'customer':
                setDropdownColumns(customerDropdownColumns);
                setUpdateFilter(() => updateCustomerFilter);
                customerColumnFilters.get(filter) &&
                    setValue(customerColumnFilters.get(filter));
                break;
            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    React.useEffect(() => {
        if (!filter) {
            return;
        }
        const mapItem = dropdownColumns.get(filter);

        if (!mapItem || !Array.isArray(mapItem)) return;

        const dropdownArray = Array.from(mapItem);

        setDropdownValues([
            { id: null, label: '-------RETIRER LE FILTRE-------' },
            ...dropdownArray,
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownColumns]);

    return (
        <>
            {!!(filter && dropdownValues.length && updateFilter) && (
                <HeaderDropdownFilter
                    variant="standard"
                    value={value}
                    onChange={(event: any) => {
                        if (!event.target.value) {
                            setValue('');
                            updateFilter(filter, event.target.value);
                        }

                        setValue(event.target.value);
                        if (!filter || updateFilter == undefined) return;

                        updateFilter(filter, event.target.value);
                    }}
                    placeholder={placeholder}
                >
                    {dropdownValues.map((column: any) => {
                        return (
                            <MenuItem key={column.id} value={column.id}>
                                {column.label}
                            </MenuItem>
                        );
                    })}
                </HeaderDropdownFilter>
            )}
        </>
    );
};

export default SelectDropdown;
