import React from 'react';
import { useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

import { CartContext } from '../../providers/CartProvider';

import CartList from '../../components/CartList/CartList';
import CartProductsArea from '../../components/CartProductsArea/CartProductsArea';
import CartCustomerArea from '../../components/CartCustomerArea/CartCustomerArea';
import CartCommentArea from '../../components/CartCommentArea/CartCommentArea';
import CartErrorBanner from '../../components/CartErrorBanner/CartErrorBanner';
import CartSubmitButton from '../../components/CartSubmitButton/CartSubmitButton';
import CartPaymentArea from '../../components/CartPaymentArea/CartPaymentArea';
import CartDeliveryArea from '../../components/CartDeliveryArea/CartDeliveryArea';

const CartContainer = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));
const CartHeader = styled(Box)(() => ({
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
}));
const CartHeaderRight = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
}));

const Cart: React.FunctionComponent = () => {
    const { state } = useLocation<{ cartID: string }>();
    const { carts, activeCart, initCarts, selectCart } =
        React.useContext(CartContext);

    React.useEffect(() => {
        if (carts.size == 0) {
            const localCarts = localStorage.getItem('carts');
            if (!localCarts) {
                selectCart();
                return;
            }

            initCarts();
            return;
        }

        if (carts.get(activeCart)) return;
        selectCart();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts]);

    React.useEffect(() => {
        if (!state || !state.cartID) return;

        selectCart(state.cartID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <CartContainer>
            <CartErrorBanner />
            <CartHeader>
                <Typography component="h1" variant="h1" sx={{ margin: 0 }}>
                    Caisse
                </Typography>

                <CartHeaderRight>
                    <CartList />
                </CartHeaderRight>
            </CartHeader>

            {carts.get(activeCart) && (
                <>
                    <CartProductsArea />

                    <CartCustomerArea />

                    <CartDeliveryArea />

                    <CartPaymentArea />

                    <CartCommentArea />

                    <CartSubmitButton />
                </>
            )}
        </CartContainer>
    );
};

export default Cart;
