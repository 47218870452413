import { gql } from '@apollo/client';

export const CUSTOMER_DROPDOWNCOLUMNS = gql`
    query posCustomerGridColumns($store_id: Int!) {
        posCustomerGridColumns(store_id: $store_id) {
            items {
                type
                label
                sortable
                filterable
                priority
                filter_code
                values {
                    id
                    label
                }
            }
        }
    }
`;
