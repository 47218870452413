import React from 'react';
import { useFormContext } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';

import {
    CustomerFormTypesExtended,
    FormattedCustomerInfo,
} from '../../services/customer.service';
import { getAddressFieldsRegisterOptions } from '../../services/delivery.service';

type Props = {
    section: CustomerFormTypesExtended;
    customerInfo: Array<FormattedCustomerInfo> | undefined;
    onChangeHandler?: () => void;
    isSameAddress?: boolean;
};

const InputsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}));
const StyledTextFieldContainer = styled(Box)(() => ({
    paddingLeft: '12px',
    paddingRight: '12px',
}));
const StyledTextField = styled(TextField)(() => ({
    height: '40px',
    width: '100%',
    margin: '20px 24px',
}));
const HiddenBox = styled(Box)(() => ({
    height: '40px',
    width: '100%',
    margin: '20px 24px',
}));

export const CustomerFields: React.FC<Props> = ({
    section,
    customerInfo,
    onChangeHandler,
    isSameAddress,
}) => {
    const {
        register,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    return (
        <InputsContainer>
            {!!customerInfo &&
                customerInfo.map((info) => {
                    const {
                        disabled,
                        hidden,
                        id,
                        label,
                        required,
                        value,
                        width,
                    } = info;
                    const sectionID = section + id.toString();

                    const registerOptions: any =
                        getAddressFieldsRegisterOptions(
                            id.toString(),
                            required && !disabled && !isSameAddress
                                ? required
                                : false
                        );

                    return (
                        <StyledTextFieldContainer
                            key={id}
                            sx={{ width: width !== '' ? width : '49.5%' }}
                        >
                            {hidden ? (
                                <HiddenBox />
                            ) : (
                                <StyledTextField
                                    variant="outlined"
                                    disabled={isSameAddress ? true : disabled}
                                    label={label}
                                    defaultValue={value}
                                    required={registerOptions.required}
                                    {...register(sectionID, registerOptions)}
                                    {...(errors[sectionID] && {
                                        error: true,
                                    })}
                                    helperText={
                                        errors[
                                            sectionID
                                        ]?.message?.toString() ?? null
                                    }
                                    onChange={() => {
                                        onChangeHandler
                                            ? onChangeHandler()
                                            : null;
                                        clearErrors();
                                    }}
                                />
                            )}
                        </StyledTextFieldContainer>
                    );
                })}
        </InputsContainer>
    );
};
