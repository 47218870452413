import { gql } from '@apollo/client';

export const PRODUCT_SETPRICE = gql`
    mutation posApplyFreePrice(
        $cart_id: String!
        $barcode: String!
        $price: Float!
    ) {
        posApplyFreePrice(
            input: { cart_id: $cart_id, bc_barcode: $barcode, price: $price }
        ) {
            cart {
                id
            }
        }
    }
`;
