import React from 'react';

import { Button, styled } from '@mui/material';
import { theme } from '../../themeOptions';

export const ButtonIconContainer = styled(Button)(() => ({
    height: '30px',
    width: '20px',
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    background: 'transparent',
    color: theme.palette.primary.main,
}));
