import React from 'react';

import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { PersonSearchOutlined } from '@mui/icons-material';

type Props = {
    onClickHandler: () => void;
};

const StyledButton = styled(Button)(() => ({
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    '&:hover': {
        background: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
    },
}));
const PersonIcon = styled(PersonSearchOutlined)(() => ({
    height: '18px',
    width: '18px',
    marginLeft: '10px',
    color: theme.palette.primary.main,
}));

const NewCustomerButton: React.FC<Props> = ({ onClickHandler }) => {
    return (
        <StyledButton type="submit" onClick={onClickHandler}>
            Recherche avancée
            <PersonIcon />
        </StyledButton>
    );
};

export default NewCustomerButton;
