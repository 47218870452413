import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { UserProvider } from './providers/UserProvider';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './apolloClient';

LicenseInfo.setLicenseKey(
    '3a3fe4578e7040bef5372b2ab04b6e5dT1JERVI6MzcyNjYsRVhQSVJZPTE2NzU2MDcxMTQwMDAsS0VZVkVSU0lPTj0x'
);

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <UserProvider>
                <App />
            </UserProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
