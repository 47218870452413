import {
    ApolloClient,
    ApolloLink,
    concat,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';

const getUri = (): string => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '';
        case 'production':
            if (!process.env.REACT_APP_GRAPHQL_HOST)
                return process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '';
            return (
                process.env.REACT_APP_GRAPHQL_HOST +
                process.env.REACT_APP_GRAPHQL_ENDPOINT
            );
        default:
            return '';
    }
};

const httpLink = new HttpLink({ uri: getUri() });

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('token');
    const storeCode = sessionStorage.getItem('storeCode');
    const customerID = sessionStorage.getItem('customerID');
    const locationID = sessionStorage.getItem('locationID');
    if (!storeCode || !locationID) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'Access-Control-Allow-Origin': process.env.REACT_APP_URL,
                authorization: 'Bearer ' + token?.replaceAll('"', '') || null,
                Pos: true,
            },
        }));
        return forward(operation);
    }

    if (!customerID) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'Access-Control-Allow-Origin': process.env.REACT_APP_URL,
                authorization: 'Bearer ' + token?.replaceAll('"', '') || null,
                Pos: true,
                Store: storeCode,
                PosLocationId: locationID,
            },
        }));
        return forward(operation);
    }

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'Access-Control-Allow-Origin': process.env.REACT_APP_URL,
            authorization: 'Bearer ' + token?.replaceAll('"', '') || null,
            Pos: true,
            Store: storeCode,
            PosLocationId: locationID,
            PosCustomer: customerID,
        },
    }));
    return forward(operation);
});

export const apolloClient = new ApolloClient({
    uri: getUri(),
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
});
