import { theme } from '../../themeOptions';
import { styled } from '@mui/material/styles';
import { Box, Select, TextField, Typography } from '@mui/material';

export const HeaderTextFilter = styled(TextField)(() => ({
    height: 'auto',
    background: '#f9f9f9',
    color: theme.palette.text.disabled,
    padding: 0,
    '& input': { paddingLeft: '5px' },
}));

export const HeaderNumberFilter = styled(TextField)(() => ({
    height: 'auto',
    width: '100%',
    background: '#f9f9f9',
    color: theme.palette.text.disabled,
    padding: 0,
    '& input': { paddingLeft: '5px' },
}));

export const HeaderDropdownFilter = styled(Select)(() => ({
    height: 'auto',
    width: '100%',
    background: '#f9f9f9',
    color: theme.palette.text.disabled,
    padding: 0,
    '& input': { paddingLeft: '5px' },
}));

export const RangePopoverContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const RangeFieldsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

export const ColumnHeaderContainer = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& input::placeholder': {
        textAlign: 'center',
    },
}));

export const ColumnHeaderName = styled(Typography)(() => ({
    height: '20px',
    width: '100%',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '500',
}));
